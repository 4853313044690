import { createSlice, PayloadAction } from '@reduxjs/toolkit';
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	IBalanceStore,
	IBalanceListSuccessPayload,
	IHotBalanceListSuccessPayload,
	ITotalFeeCollectedListSuccessPayload,
} from './types';

const initialState: IBalanceStore = {
	balanceListLoader: false,
	balanceList: null,
	hotBalanceListLoader: false,
	hotBalanceList: null,
	totalFeeCollectedListLoader: false,
	totalFeeCollectedList: null,
};

const balance = createSlice({
	name: '@@backofficeBalance',
	initialState,
	reducers: {
		getBalanceListRequest: (state, action: PayloadAction) => {
			const balanceListState = state;

			balanceListState.balanceListLoader = true;
		},

		setBalanceListSuccess: (state, action: PayloadAction<IBalanceListSuccessPayload>) => {
			const { payload } = action;
			const balanceListSuccessState = state;

			balanceListSuccessState.balanceList = payload;
			balanceListSuccessState.balanceListLoader = false;
		},
		getHotBalanceListRequest: (state, action: PayloadAction) => {
			const hotBalanceListState = state;

			hotBalanceListState.hotBalanceListLoader = true;
		},

		setHotBalanceListSuccess: (state, action: PayloadAction<IHotBalanceListSuccessPayload>) => {
			const { payload } = action;
			const hotBalanceListSuccessState = state;

			hotBalanceListSuccessState.hotBalanceList = payload;
			hotBalanceListSuccessState.hotBalanceListLoader = false;
		},

		getTotalFeeCollectedListRequest: (state, action: PayloadAction) => {
			const totalFeeCollectedListState = state;

			totalFeeCollectedListState.totalFeeCollectedListLoader = true;
		},

		setTotalFeeCollectedListSuccess: (
			state,
			action: PayloadAction<ITotalFeeCollectedListSuccessPayload>,
		) => {
			const { payload } = action;
			const totalFeeCollectedListSuccessState = state;

			totalFeeCollectedListSuccessState.totalFeeCollectedList = payload;
			totalFeeCollectedListSuccessState.totalFeeCollectedListLoader = false;
		},

		balanceListInitState: () => initialState,
	},
});

export default balance.reducer;

export const {
	getBalanceListRequest,
	setBalanceListSuccess,
	getHotBalanceListRequest,
	setHotBalanceListSuccess,
	getTotalFeeCollectedListRequest,
	setTotalFeeCollectedListSuccess,
	balanceListInitState,
} = balance.actions;
