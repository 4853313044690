/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeLimitationsRequest } from 'redux/reducers/limitations/reducer';
import { noExponent } from 'utils/numberFormat';
import { ILimitationsItemProps } from './types';

export const LimitationsItem: FC<ILimitationsItemProps> = ({ data }) => {
	const [isRedact, setIsRedact] = useState(false);
	const [withdrawMin, setWithdrawMin] = useState(String(data.withdraw_min));
	const [withdrawMax, setWithdrawMax] = useState(String(data.withdraw_max));
	const [notCorrect, setNotCorrect] = useState(false);

	const dispatch = useDispatch();

	const handleChangeLimitations = () => {
		if (notCorrect) return;
		setIsRedact(false);
		dispatch(
			changeLimitationsRequest({
				id: data.id,
				withdraw_min: Number(withdrawMin),
				withdraw_max: Number(withdrawMax),
			}),
		);
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>
					{data.asset_code?.toUpperCase()}
					{data.asset_code !== data.network_name && ` (${data.network_name.toUpperCase()})`}
				</p>
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Advertisement / Min</p>
				{!isRedact ? (
					<p>{noExponent(data.withdraw_min)}</p>
				) : (
					<div>
						<input
							type="text"
							value={noExponent(withdrawMin)}
							onChange={(e) => {
								setWithdrawMin(e.target.value.replace(/[^\d\\.]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d\\.]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Advertisement / Max</p>
				{!isRedact ? (
					<p>{noExponent(data.withdraw_max)}</p>
				) : (
					<div>
						<input
							type="text"
							value={noExponent(withdrawMax)}
							onChange={(e) => {
								setWithdrawMax(e.target.value.replace(/[^\d\\.]/g, ''));
								setNotCorrect(e.target.value.replace(/[^\d\\.]/g, '') === '');
							}}
						/>
					</div>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons">
					<div className="table-buttons">
						{isRedact ? (
							<>
								<button
									type="button"
									onClick={() => {
										setWithdrawMin(String(data.withdraw_min));
										setWithdrawMax(String(data.withdraw_max));

										setIsRedact(!isRedact);
									}}
								>
									<span className="edit-icon icon-cancel-icon" />
								</button>

								<button
									type="button"
									onClick={handleChangeLimitations}
									className={notCorrect ? 'button-not-active' : ''}
								>
									<svg
										width="18"
										height="19"
										viewBox="0 0 18 19"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
											stroke="#ff57d4"
											strokeLinecap="square"
										/>
									</svg>
								</button>
							</>
						) : (
							<button type="button" onClick={() => setIsRedact(!isRedact)}>
								<span className="edit-icon icon-edit-icon" />
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
