/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiUpdateIsolatedPairParams,
	IApiUpdatePairParams,
	TApiGetMarginIsolatedResponse,
	TApiGetSpotPairsResponse,
	TApiGetTradingPairsAssetsResponse,
	TFuturesActiveBaseAssetItem,
	TFuturesActiveBaseAssetResponse,
} from 'services/api/tradingPairs/types';
import { IAddAssetsPairPayload, ITradingPairsStore } from './types';

export const initialState: ITradingPairsStore = {
	tradingPairs: null,
	tradingPairsLoader: false,
	editTradingPairsLoader: false,

	marginIsolatedList: [],
	marginIsolatedLoading: false,
	marginIsolatedActionsLoading: false,

	assets: null,
	assetsLoader: false,
	addAssetsPairLoader: false,

	perp: null,
	perpLoading: false,

	putPerp: null,
	putPerpLoading: false,
};

const tradingPairs = createSlice({
	name: '@@tradingPairs',
	initialState,
	reducers: {
		getTradingPairsRequest: (state) => {
			state.tradingPairsLoader = true;
		},
		getTradingPairsSuccess: (state, { payload }: PayloadAction<TApiGetSpotPairsResponse>) => {
			state.tradingPairs = payload;
			state.tradingPairsLoader = false;
		},

		editTradingPairsRequest: (state, action: PayloadAction<IApiUpdatePairParams>) => {
			state.editTradingPairsLoader = true;
		},
		editTradingPairsSuccess: (state) => {
			state.editTradingPairsLoader = false;
		},

		getAssetsRequest: (state) => {
			state.assetsLoader = true;
		},
		getAssetsSuccess: (state, { payload }: PayloadAction<TApiGetTradingPairsAssetsResponse>) => {
			state.assets = payload;
			state.assetsLoader = false;
		},

		addAssetsPairRequest: (state, action: PayloadAction<IAddAssetsPairPayload>) => {
			const addAssetsPairState = state;

			addAssetsPairState.addAssetsPairLoader = true;
		},
		addAssetsPairSuccess: (state) => {
			const addAssetsPairState = state;

			addAssetsPairState.addAssetsPairLoader = false;
		},

		getMarginIsolatedRequest: (state) => {
			state.marginIsolatedLoading = true;
		},
		getMarginIsolatedSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetMarginIsolatedResponse>,
		) => {
			state.marginIsolatedList = payload;
			state.marginIsolatedLoading = false;
		},

		updateIsolatedPairRequest: (state, action: PayloadAction<IApiUpdateIsolatedPairParams>) => {
			state.marginIsolatedActionsLoading = true;
		},
		updateIsolatedPairSuccess: (state) => {
			state.marginIsolatedActionsLoading = false;
		},
		updateIsolatedPairError: (state) => {
			state.marginIsolatedActionsLoading = false;
		},
		/// ////Perp
		getTradingPairsPerpRequest: (state) => {
			state.perpLoading = true;
		},
		getTradingPairsPerpSuccess: (
			state,
			{ payload }: PayloadAction<TFuturesActiveBaseAssetResponse>,
		) => {
			state.perp = payload;
			state.perpLoading = false;
		},
		putTradingPairsPerpRequest: (state, payload) => {
			state.putPerpLoading = true;
		},
		putTradingPairsPerpSuccess: (
			state,
			{ payload }: PayloadAction<TFuturesActiveBaseAssetItem>,
		) => {
			state?.perp?.splice(
				state.perp.findIndex((el) => el.id === payload.id),
				1,
				payload,
			);
			state.putPerpLoading = false;
		},
		tradingPairsInitState: () => initialState,
	},
});

export default tradingPairs.reducer;
export const {
	getTradingPairsSuccess,
	getTradingPairsRequest,
	editTradingPairsRequest,
	editTradingPairsSuccess,
	getMarginIsolatedRequest,
	getMarginIsolatedSuccess,
	updateIsolatedPairRequest,
	updateIsolatedPairSuccess,
	updateIsolatedPairError,
	getAssetsRequest,
	getAssetsSuccess,
	addAssetsPairRequest,
	addAssetsPairSuccess,
	tradingPairsInitState,
	getTradingPairsPerpRequest,
	getTradingPairsPerpSuccess,
	putTradingPairsPerpRequest,
	putTradingPairsPerpSuccess,
} = tradingPairs.actions;
