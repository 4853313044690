import { FC } from 'react';

export const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>USER ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Contract</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>ROLE/ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Size</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fill Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fee</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>PnL</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Role</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Created At</p>
					</div>
				</div>
			</div>
		</div>
	);
};
