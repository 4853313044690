import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { IItemsProps } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
function numberInputFix(value: any) {
	['e', 'E', '+', '-'].includes(value.key) && value.preventDefault();
}

export const TableItem: FC<IItemsProps> = ({ item, updateHandler }) => {
	const currencyPair = item?.base_asset;
	const userOrders = item?.base_asset_id;
	const isActive = item?.is_active;
	const minLot = item?.min_lot;
	const feeRateTaker = item?.taker_fee;
	const feeRateMaker = item?.maker_fee;
	const maxLeverage = item?.max_leverage;
	const notional = item?.impact_margin_notional;
	const maintenance = item?.maintenance_margin_rate;
	const maxFundingRate = item?.max_funding_rate;
	const minFundingRate = item?.min_funding_rate;
	const interestBaseIndex = item?.interest_base_index;
	const interestQuoteIndex = item?.interest_quote_index;

	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};
	const initialValues = {
		minLot: minLot || '',
		feeRateTaker: feeRateTaker || '',
		feeRateMaker: feeRateMaker || '',
		maxLeverage: maxLeverage || '',
		notional: notional || '',
		maintenance: maintenance || '',
		maxFundingRate: maxFundingRate || '',
		minFundingRate: minFundingRate || '',
		interestBaseIndex: interestBaseIndex || '',
		interestQuoteIndex: interestQuoteIndex || '',
	};
	return (
		<div>
			{changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						const data = {
							base_asset: currencyPair,
							is_active: isActive,
							min_lot: values.minLot,
							taker_fee: values.feeRateTaker,
							maker_fee: values.feeRateMaker,
							max_leverage: values.maxLeverage,
							max_funding_rate: values.maxFundingRate,
							min_funding_rate: values.minFundingRate,
							impact_margin_notional: values.notional,
							interest_base_index: values.interestBaseIndex,
							interest_quote_index: values.interestQuoteIndex,
							maintenance_margin_rate: values.maintenance,
						};
						actions.setSubmitting(false);
						updateHandler(data);
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Currency Pair</p>
								<p>{currencyPair?.toUpperCase()}/USDT </p>
							</div>
							<div className="td">
								<p className="td-hidden-name">User Orders</p>
								<p>{userOrders}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">User Positions</p>
								<p>{isActive}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">Min. lot</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="minLot"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.minLot}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Rate take, %</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="feeRateTaker"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.feeRateTaker}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Rate maker, %</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="feeRateMaker"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.feeRateMaker}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Max Leverage</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="maxLeverage"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.maxLeverage}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Impact Margin</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="notional"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.notional}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Maintenance Margin</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="maintenance"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.maintenance}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Сontract Upper</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="maxFundingRate"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.maxFundingRate}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Сontract Lower</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="minFundingRate"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.minFundingRate}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Base index</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="interestBaseIndex"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.interestBaseIndex}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Quote index</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="interestQuoteIndex"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.interestQuoteIndex}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									<button type="button" onClick={switchOffChangeableMode}>
										<svg
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1.7998 1.7998L16.1998 16.1998M1.7998 16.1998L16.1998 1.7998"
												stroke="#E04141"
											/>
										</svg>
									</button>
									<button type="submit">
										<svg
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1.2002 8.4001L6.60019 13.8001L16.8002 3.6001"
												stroke="#30C06E"
												strokeLinecap="square"
											/>
										</svg>
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency Pair</p>
						<p>{currencyPair?.toUpperCase()}/USDT </p>
					</div>
					<div className="td">
						<p className="td-hidden-name">User Orders</p>
						<p>{userOrders}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">User Positions</p>
						<p>{isActive}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Min. lot</p>
						<p>{minLot}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Rate take, %</p>
						<p>{feeRateTaker}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Rate maker, %</p>
						<p>{feeRateMaker}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Max Leverage</p>
						<p>{maxLeverage}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Notional</p>
						<p>{notional}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maintenance Margin</p>
						<p>{maintenance}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Сontract Upper</p>
						<p>{maxFundingRate}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Сontract Lower</p>
						<p>{minFundingRate}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Base Index</p>
						<p>{interestBaseIndex}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Quote Index</p>
						<p>{interestQuoteIndex}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Action</p>
						<div className="table-buttons flex-e">
							<button type="button" onClick={switchOnChangeableMode}>
								<svg
									width="18"
									height="19"
									viewBox="0 0 18 19"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.599609 11.9001L0.246056 11.5465L0.0996094 11.693V11.9001H0.599609ZM11.3996 1.1001L11.7532 0.746544C11.5579 0.551282 11.2413 0.551282 11.0461 0.746544L11.3996 1.1001ZM17.3996 7.1001L17.7532 7.45365C17.9484 7.25839 17.9484 6.94181 17.7532 6.74654L17.3996 7.1001ZM6.59961 17.9001V18.4001H6.80672L6.95316 18.2537L6.59961 17.9001ZM0.599609 17.9001H0.0996094C0.0996094 18.1762 0.323467 18.4001 0.599609 18.4001L0.599609 17.9001ZM0.953163 12.2537L11.7532 1.45365L11.0461 0.746544L0.246056 11.5465L0.953163 12.2537ZM11.0461 1.45365L17.0461 7.45365L17.7532 6.74654L11.7532 0.746544L11.0461 1.45365ZM17.0461 6.74654L6.24606 17.5465L6.95316 18.2537L17.7532 7.45365L17.0461 6.74654ZM6.59961 17.4001H0.599609V18.4001H6.59961V17.4001ZM1.09961 17.9001V11.9001H0.0996094V17.9001H1.09961ZM7.44606 5.05365L13.4461 11.0537L14.1532 10.3465L8.15316 4.34654L7.44606 5.05365ZM9.59961 18.4001H17.9996V17.4001H9.59961V18.4001Z"
										fill="#30C06E"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TableItem;
