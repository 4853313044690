import { FC } from 'react';
import L from 'i18n-react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { appLanguage } from 'redux/reducers/app/selectors';
import { languages } from 'languages';
import { routesList } from 'routes/routesList';
import SocketConnectWrapper from 'components/SocketConnectWrapper';

const App: FC = () => {
	const language = useSelector(appLanguage);
	L.setTexts(languages[language]);

	return (
		<BrowserRouter>
			<SocketConnectWrapper>
				<Switch>
					{routesList?.map((route) => (
						<Route key={route.path} exact path={route.path} component={route.component} />
					))}
					<Redirect to="/" />
				</Switch>
			</SocketConnectWrapper>
		</BrowserRouter>
	);
};

export default App;
