import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeGeneralRequest } from 'redux/reducers/сurrencies/reducer';
import { IDisputeProps } from './types';

const CurrenciesGeneralTableItem: FC<IDisputeProps> = ({ item }) => {
	const dispatch = useDispatch();
	const [code] = useState(item?.code);
	const [depositable] = useState(item?.depositable);
	const [withdrawable] = useState(item?.withdrawable);
	const [exchangeable] = useState(item?.exchangeable);
	const handleSwitcherChange = (sw: boolean, text: string) => {
		const body = {
			id: item.id,
			[text]: sw ? 1 : 0,
		};
		dispatch(changeGeneralRequest(body));
	};

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Currency</p>
				<p style={{ textTransform: 'uppercase' }}>{code}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Deposit</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!depositable}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked, 'depositable');
							}}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Withdrawal</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!withdrawable}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked, 'withdrawable');
							}}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Convert</p>
				<div className="switch switch--type2">
					<label className="switch__label">
						<input
							type="checkbox"
							className="hidden"
							checked={!!exchangeable}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
								handleSwitcherChange(e.target.checked, 'exchangeable');
							}}
						/>
						<div className="switch__toggler" />
					</label>
				</div>
			</div>
		</div>
	);
};
export default CurrenciesGeneralTableItem;
