import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Orders from 'components/Orders';

// ================================================:
const OrdersPage: FC = () => {
	return (
		<Dashboard title="Orders">
			<Orders />
		</Dashboard>
	);
};
export default OrdersPage;
