import { useDispatch, useSelector } from 'react-redux';
import { useLayoutEffect, useState } from 'react';
import { getOptionsTrades, getOptionsTradesLoading } from 'redux/reducers/trade/selectors';
import { getOptionsTradesRequest } from 'redux/reducers/trade/reducer';
import { IApiGetFuturesTradesParams } from 'services/api/trades/types';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { TableHeader } from './TableHeader/TableHeader';
import TableItem from './TableItem/TableItem';

export const OptionsTrades = () => {
	const dispatch = useDispatch();

	const trades = useSelector(getOptionsTrades);
	const loading = useSelector(getOptionsTradesLoading);

	const [currentPage, setCurrentPage] = useState(1);

	useLayoutEffect(() => {
		const params: IApiGetFuturesTradesParams = {
			page: currentPage,
		};
		dispatch(getOptionsTradesRequest(params));
	}, [dispatch, currentPage]);

	return (
		<>
			{loading && (
				<div className="list-loader">
					<Loader />
				</div>
			)}

			{!loading && (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--futures-trades">
							<TableHeader />

							<div className="table-body">
								{trades?.data.map((item) => (
									<TableItem item={item} key={item.id} />
								))}

								{!trades?.data.length && <p style={{ padding: '20px' }}>List is empty</p>}
							</div>
						</div>
					</div>

					<div style={{ padding: '14px' }}>
						{(trades?.last_page || 0) > 1 && (
							<Pagination
								pageCount={trades?.last_page || 0}
								forcePage={currentPage - 1}
								onPageChange={setCurrentPage}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};
