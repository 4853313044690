import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getAddAssetsPairPerp,
	getAddAssetsPairPerpLoading,
} from 'redux/reducers/tradingPairs/selectors';
import {
	getTradingPairsPerpRequest,
	putTradingPairsPerpRequest,
} from 'redux/reducers/tradingPairs/reducer';
import Loader from 'ui/Loader';
import { TableHeader } from './TableHeader';
import TableItem from './TableItem';
import { TChangeData } from './TableItem/types';

export const FuturesPerp: FC = () => {
	const dispatch = useDispatch();
	const itemMap = useSelector(getAddAssetsPairPerp);
	const loading = useSelector(getAddAssetsPairPerpLoading);
	const updateHandler = (item: TChangeData) => {
		dispatch(putTradingPairsPerpRequest(item));
	};
	useEffect(() => {
		dispatch(getTradingPairsPerpRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="table-block perp">
			<div className="table-wrapper">
				<div className="table">
					<TableHeader />

					<div className="table-body">
						{loading && (
							<div className="list-loader">
								<Loader />
							</div>
						)}
						{!loading && !!itemMap?.length && (
							<div>
								{itemMap.map((item) => (
									<TableItem key={item.id} item={item} updateHandler={updateHandler} />
								))}
							</div>
						)}
						{!loading && !itemMap?.length && (
							<div className="user-management-empty-data">
								<p className="user-management-empty__text">No trading pairs found...</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
