/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC } from 'react';
import { numberFormat } from 'utils/numberFormat';
import { ICustomTooltipProps } from './types';

const CustomTooltip: FC<ICustomTooltipProps> = ({ active, payload, data }) => {
	const balanceList = data;

	if (active && payload !== undefined) {
		const currentItem = balanceList?.find((el) => el.asset_code === payload[0]?.name);

		return (
			<>
				<div className="pie-chart-tooltip">
					<h4 className="pie-chart-tooltip__content">
						{numberFormat(
							Number(
								currentItem.balances.reduce((sum: number, elem: any) => {
									return sum + Number(elem.balance);
								}, 0),
							),
							'en-EN',
						)}{' '}
						{currentItem?.asset_code.toUpperCase()}
					</h4>
					<h4 className="pie-chart-tooltip__content">
						${' '}
						{numberFormat(
							Number(
								currentItem.balances.reduce((sum: number, elem: any) => {
									return sum + Number(elem.balance_usdt);
								}, 0),
							),
							'en-EN',
						)}
					</h4>
				</div>
				<p className="pie-chart-tooltip-brd" />
			</>
		);
	}

	return null;
};

export default CustomTooltip;
