/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IOptionsStore, TOptionsStatistics } from './types';

export const initialState: IOptionsStore = {
	statistics: null,
};

const options = createSlice({
	name: '@@options',
	initialState,
	reducers: {
		setOptionsStatistics: (state, { payload }: PayloadAction<TOptionsStatistics>) => {
			state.statistics = payload;
		},
	},
});

export default options.reducer;
export const { setOptionsStatistics } = options.actions;
