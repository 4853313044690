/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFuturesUserMarkPrice,
	getFuturesUserMarkPriceLoading,
	getFuturesUserOrderHistory,
	getFuturesUserOrderHistoryLoading,
	getFuturesUserPositions,
	getFuturesUserPositionsLoading,
} from 'redux/reducers/userManagement/selectors';
import { useParams } from 'react-router';
import {
	getFuturesUserMarkPriceRequest,
	getFuturesUserPositionsRequest,
} from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import { IApiGetFuturesUserOrderHistoryParams } from 'services/api/users/types';
import { TableHeader } from './TableHeader/TableHeader';
import TableItem from './TableItem/TableItem';

export const FuturesPositions = () => {
	const dispatch = useDispatch();
	const { id: user_id } = useParams<{ id: string }>(); // eslint-disable-line

	const orderHistory = useSelector(getFuturesUserPositions);
	const loading = useSelector(getFuturesUserPositionsLoading);

	const markPrice = useSelector(getFuturesUserMarkPrice);
	const loadingMarkPrice = useSelector(getFuturesUserMarkPriceLoading);

	const [currentPage] = useState(1);

	useLayoutEffect(() => {
		const params: IApiGetFuturesUserOrderHistoryParams = {
			user_id,
			current_page: currentPage,
		};
		dispatch(getFuturesUserPositionsRequest(params));
		dispatch(getFuturesUserMarkPriceRequest(params));
	}, [dispatch, user_id, currentPage]);

	return (
		<div className="content-block__inside content-block__inside--login-his">
			<div className="table-block table-block--ip">
				<div className="table-wrapper">
					<div className="table table--feature-positions">
						{loading && (
							<div className="list-loader">
								<Loader />
							</div>
						)}

						{!loading && (
							<>
								<TableHeader />
								<div className="table-body">
									{orderHistory?.map((item) => (
										<TableItem item={item} key={item.id} markPrice={markPrice} />
									))}
									{!orderHistory?.length && <p style={{ padding: '20px' }}>List is empty</p>}
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
