import { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import { FuturesOrders } from './OrdersBox/FuturesOrders/FuturesOrders';
import { MarginOrders } from './OrdersBox/MarginOrders/MarginOrders';
import { SpotOrders } from './OrdersBox/SpotOrders/SpotOrders';
import { OptionsOrders } from './OrdersBox/OptionsOrders/OptionsOrders';

const Orders: FC = () => {
	return (
		<div className="orders">
			<div className="title-block">
				<p className="title">Orders</p>
			</div>
			<Tabs className="settings-tabs">
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link"> Spot orders</span>
					</Tab>
					<Tab className="user-settings__item">
						{/* CROSS HIDE */}
						{/* <span className="user-settings__link"> Margin orders(cross/isolated)</span> */}
						<span className="user-settings__link">Margin orders</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Perp Futures</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Options</span>
					</Tab>
				</TabList>

				<TabPanel>
					<SpotOrders radioCheck="cross" />
				</TabPanel>
				<TabPanel>
					<MarginOrders radioCheck="cross" />
				</TabPanel>
				<TabPanel>
					<FuturesOrders />
				</TabPanel>
				<TabPanel>
					<OptionsOrders />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default Orders;
