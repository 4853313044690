/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApproveWithdrawalsRequestPayload,
	IApproveWithdrawalsResponsePayload,
	IRejectWithdrawalsRequestPayload,
	IRejectWithdrawalsResponsePayload,
	IWithdrawalsListRequestPayload,
	IWithdrawalsResponsePayload,
	IWithdrawalsStore,
} from './types';
// ==========================================:
export const initialState: IWithdrawalsStore = {
	withdrawals: null,
	withdrawalsLoader: false,
	approveWithdrawals: null,
	approveWithdrawalsLoader: false,
	rejectWithdrawals: null,
	rejectWithdrawalsLoader: false,
};

// ==========================================:
const withdrawals = createSlice({
	name: '@@withdrawals',
	initialState,
	reducers: {
		getWithdrawalsRequest: (state, action: PayloadAction<IWithdrawalsListRequestPayload>) => {
			const withdrawalsRequestState = state;
			withdrawalsRequestState.withdrawalsLoader = true;
		},
		getWithdrawalsSuccess: (state, action: PayloadAction<IWithdrawalsResponsePayload>) => {
			const { payload } = action;
			const withdrawalsSuccessState = state;
			withdrawalsSuccessState.withdrawals = payload;
			withdrawalsSuccessState.withdrawalsLoader = false;
		},

		approveWithdrawalsRequest: (
			state,
			action: PayloadAction<IApproveWithdrawalsRequestPayload>,
		) => {
			const { payload } = action;
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = true;
		},
		approveWithdrawalsSuccess: (
			state,
			action: PayloadAction<IApproveWithdrawalsResponsePayload>,
		) => {
			const { payload } = action;
			const approveWithdrawalsRequestState = state;
			approveWithdrawalsRequestState.approveWithdrawalsLoader = false;
		},

		rejectWithdrawalsRequest: (state, action: PayloadAction<IRejectWithdrawalsRequestPayload>) => {
			const { payload } = action;
			const rejectWithdrawalsRequestState = state;
			rejectWithdrawalsRequestState.rejectWithdrawalsLoader = true;
		},
		rejectWithdrawalsSuccess: (state, action: PayloadAction<IRejectWithdrawalsResponsePayload>) => {
			const { payload } = action;
			const rejectWithdrawalsRequestState = state;
			rejectWithdrawalsRequestState.rejectWithdrawalsLoader = false;
		},

		withdrawalsInitState: () => initialState,
	},
});

export default withdrawals.reducer;
export const {
	getWithdrawalsRequest,
	getWithdrawalsSuccess,
	approveWithdrawalsRequest,
	approveWithdrawalsSuccess,
	rejectWithdrawalsRequest,
	rejectWithdrawalsSuccess,
	withdrawalsInitState,
} = withdrawals.actions;
