/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IFeesResponsePayload,
	IFeesStore,
	IPlatformFeesResponsePayload,
	ICryptoFeesResponsePayload,
	IChangeExchangeFeePayload,
	IChangeTradeFeesAndLimitsPayload,
	IChangeCryptoFeesPayload,
	IFiatFeesResponsePayload,
	IChangeFiatFeesPayload,
} from './types';

export const initialState: IFeesStore = {
	fees: null,
	platformFees: null,
	cryptoFees: null,
	fiatFees: null,
	volumeDependsFeeSettings: null,
	changeVolumeDependsFeeSettingsLoader: false,
	feesLoader: false,
	changeExchangeFeeLoader: false,
	changeFeesLoader: false,
	changeCryptoFeesLoader: false,
	changeFiatFeesLoader: false,
	marginBorrow: null,
	marginBorrowLoading: false,
};

const fees = createSlice({
	name: '@@fees',
	initialState,
	reducers: {
		changeExchangeFeeRequest: (state, action: PayloadAction<IChangeExchangeFeePayload>) => {
			const changeExchangeFeeState = state;

			changeExchangeFeeState.changeExchangeFeeLoader = true;
		},
		changeExchangeSuccess: (state) => {
			const changeExchangeFeeState = state;

			changeExchangeFeeState.changeExchangeFeeLoader = true;
		},
		changeTradeFeesAndLimitsRequest: (
			state,
			action: PayloadAction<IChangeTradeFeesAndLimitsPayload>,
		) => {
			const changeTradeFeesAndLimitsState = state;

			changeTradeFeesAndLimitsState.changeFeesLoader = true;
		},
		changeTradeFeesAndLimitsSuccess: (state) => {
			const changeTradeFeesAndLimitsState = state;

			changeTradeFeesAndLimitsState.changeFeesLoader = true;
		},
		changeCryptoFeesRequest: (state, action: PayloadAction<IChangeCryptoFeesPayload>) => {
			const changeCryptoFeesState = state;

			changeCryptoFeesState.changeCryptoFeesLoader = true;
		},
		changeCryptoFeesSuccess: (state) => {
			const changeCryptoFeesState = state;

			changeCryptoFeesState.changeCryptoFeesLoader = true;
		},
		getFeesRequest: (state) => {
			const feesRequestState = state;
			feesRequestState.feesLoader = true;
		},
		getFeesSuccess: (state, action: PayloadAction<IFeesResponsePayload>) => {
			const { payload } = action;
			const feesSuccessState = state;
			feesSuccessState.fees = payload;
			feesSuccessState.feesLoader = false;
		},
		getPlatformFeesRequest: (state) => {
			const feesRequestState = state;
			feesRequestState.feesLoader = true;
		},
		getPlatformFeesSuccess: (state, action: PayloadAction<IPlatformFeesResponsePayload>) => {
			const { payload } = action;
			const feesSuccessState = state;
			feesSuccessState.platformFees = payload;
			feesSuccessState.feesLoader = false;
		},
		getCryptoFeesRequest: (state) => {
			const feesRequestState = state;
			feesRequestState.feesLoader = true;
		},
		getCryptoFeesSuccess: (state, action: PayloadAction<ICryptoFeesResponsePayload>) => {
			const { payload } = action;
			const feesSuccessState = state;
			feesSuccessState.cryptoFees = payload;
			feesSuccessState.feesLoader = false;
		},
		getFiatFeesRequest: (state) => {
			const feesRequestState = state;
			feesRequestState.feesLoader = true;
		},
		getFiatFeesSuccess: (state, action: PayloadAction<IFiatFeesResponsePayload>) => {
			const { payload } = action;
			const fiatSuccessState = state;
			fiatSuccessState.fiatFees = payload;
			fiatSuccessState.feesLoader = false;
		},
		changeFiatFeesRequest: (state, action: PayloadAction<IChangeFiatFeesPayload>) => {
			const changeFiatFeesState = state;

			changeFiatFeesState.changeFiatFeesLoader = true;
		},
		changeFiatFeesSuccess: (state) => {
			const changeFiatFeesState = state;

			changeFiatFeesState.changeFiatFeesLoader = true;
		},

		getVolumeDependsFeeSettingsRequest: (state) => {
			const changeTradeFeesAndLimitsState = state;

			changeTradeFeesAndLimitsState.changeVolumeDependsFeeSettingsLoader = true;
		},
		getVolumeDependsFeeSettingsSuccess: (state, action) => {
			const { payload } = action;
			const fiatSuccessState = state;
			fiatSuccessState.volumeDependsFeeSettings = payload;
			fiatSuccessState.changeVolumeDependsFeeSettingsLoader = false;
		},
		changeVolumeDependsFeeSettingsRequest: (state, action) => {
			const changeTradeFeesAndLimitsState = state;
			changeTradeFeesAndLimitsState.changeVolumeDependsFeeSettingsLoader = true;
		},

		getMarginBorrowRequest: (state) => {
			state.marginBorrowLoading = true;
		},
		getMarginBorrowSuccess: (state, { payload }) => {
			state.marginBorrow = payload;
			state.marginBorrowLoading = false;
		},

		changeMarginBorrowRequest: (state, action) => {
			state.marginBorrowLoading = true;
		},

		feesInitState: () => initialState,
	},
});

export default fees.reducer;
export const {
	getFeesSuccess,
	feesInitState,
	getFeesRequest,
	getPlatformFeesSuccess,
	getPlatformFeesRequest,
	getCryptoFeesRequest,
	getCryptoFeesSuccess,
	getFiatFeesRequest,
	getFiatFeesSuccess,
	changeExchangeSuccess,
	changeExchangeFeeRequest,
	changeTradeFeesAndLimitsRequest,
	changeTradeFeesAndLimitsSuccess,
	changeCryptoFeesRequest,
	changeCryptoFeesSuccess,
	changeFiatFeesRequest,
	changeFiatFeesSuccess,
	getVolumeDependsFeeSettingsRequest,
	getVolumeDependsFeeSettingsSuccess,
	changeVolumeDependsFeeSettingsRequest,
	getMarginBorrowRequest,
	getMarginBorrowSuccess,
	changeMarginBorrowRequest,
} = fees.actions;
