/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { getLoadingTradesMargin, getTradesMarginList } from 'redux/reducers/trade/selectors';
import { getTradesMarginRequest } from 'redux/reducers/trade/reducer';
import { MarginTradesTableHeader } from './MarginTradesTableHeader';
import { IMarginProps, IMarginTradeItem } from './types';
import MarginTradesTableItem from './MarginTradesTableItem';

export const MarginTrades: FC<IMarginProps> = () => {
	const tradesMarginList = useSelector(getTradesMarginList);
	const tradesMarginListLoader = useSelector(getLoadingTradesMargin);
	// CROSS HIDE
	// const [radioCheck, setRadioCheck] = useState('cross');
	const [radioCheck, setRadioCheck] = useState('isolated');
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			order_type: radioCheck,
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getTradesMarginRequest(params));
	};

	useEffect(() => {
		const params = {
			per_page: 15,
			order_type: radioCheck,
			current_page: 1,
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getTradesMarginRequest(params));
	}, [radioCheck]);

	return (
		<div className="trades-marginTrades">
			{/* CROSS HIDE */}
			{/* <div className="table-panel__radio-group">
				<div className="table-panel__radio-group">
					<div className="radio">
						<label className="radio__label">
							<input
								onChange={() => {
									setRadioCheck('cross');
								}}
								checked={radioCheck === 'cross'}
								type="radio"
								className="hidden"
								name="margin-type"
							/>
							<span className="radio__item" />
							<span className="radio__text">Cross </span>
						</label>
					</div>
					<div className="radio">
						<label className="radio__label">
							<input
								onChange={() => {
									setRadioCheck('isolated');
								}}
								checked={radioCheck === 'isolated'}
								type="radio"
								className="hidden"
								name="margin-type"
							/>
							<span className="radio__item" />
							<span className="radio__text">Isolated</span>
						</label>
					</div>
				</div>
			</div> */}
			{tradesMarginListLoader ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--spot-trades">
							<MarginTradesTableHeader />
							<div className="table-body">
								{tradesMarginList?.data.map((item: IMarginTradeItem) => (
									<MarginTradesTableItem item={item} key={item.id} />
								))}
								{tradesMarginList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Orders history is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(tradesMarginList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={tradesMarginList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
