/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getWithdrawalsTransactionsIsLoad,
	getWithdrawalsTransactionsList,
} from 'redux/reducers/transactions/selectors';
import Loader from 'ui/Loader';
import { WithdrawalsItem } from './WithdrawalsItem';

export const Withdrawals: FC = () => {
	const withdrawals = useSelector(getWithdrawalsTransactionsList);
	const withdrawalsLoader = useSelector(getWithdrawalsTransactionsIsLoad);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block content-block--transaction">
				<div className="content-block__inside content-block__inside--deposit-his" />
				<div className="content-block__inside content-block__inside--login-his">
					{(withdrawals?.data?.length ?? 0) < 1 && !withdrawalsLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">Withdrawals not found...</p>
						</div>
					)}
					{withdrawalsLoader && (
						<div className="list-loader">
							<Loader />
						</div>
					)}
					{(withdrawals?.data?.length ?? 0) > 0 && !withdrawalsLoader && (
						<div className="table-block">
							<div className="table-wrapper">
								<div className="table table--transaction">
									<div className="table-header">
										<div className="tr">
											<div className="td">
												<div className="td-name">
													<p>ID</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>User ID</p>
												</div>
											</div>

											<div className="td">
												<div className="td-name">
													<p className="status-arrow status-arrow--flex-start">
														<span className="status-arrow__text">Time</span>
														<button type="button" className="status-arrow__button" />
													</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p className="status-arrow status-arrow--flex-start">
														<span className="status-arrow__text">Asset</span>
														<button type="button" className="status-arrow__button" />
													</p>
												</div>
											</div>

											<div className="td">
												<div className="td-name">
													<p>Network</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p>Network fee</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p>Amount</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p>Destination</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p className="status-arrow">
														<span className="status-arrow__text">Status</span>
														<button type="button" className="status-arrow__button" />
													</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p className="status-arrow">
														TxID
														<button type="button" className="status-arrow__button" />
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="table-body">
										{withdrawals?.data.map((item) => (
											<WithdrawalsItem data={item} key={item.id} />
										))}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
