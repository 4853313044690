import { FC } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';
import { SpotTrades } from './TradexBox/SpotTrades/SpotTrades';
import { MarginTrades } from './TradexBox/MarginTrades/MarginTrades';
import { FuturesTrades } from './TradexBox/FuturesTrades/FuturesTrades';
import { OptionsTrades } from './TradexBox/OptionsTrades/OptionsTrades';

const Trades: FC = () => {
	return (
		<div className="trades">
			<div className="title-block">
				<p className="title">Trades</p>
			</div>

			<Tabs className="settings-tabs">
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link"> Spot trades</span>
					</Tab>
					<Tab className="user-settings__item">
						{/* CROSS HIDE */}
						{/* <span className="user-settings__link"> Margin trades(cross/isolated)</span> */}
						<span className="user-settings__link">Margin trades</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Perp Futures</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Options trades</span>
					</Tab>
				</TabList>

				<TabPanel>
					<SpotTrades radioCheck="cross" />
				</TabPanel>
				<TabPanel>
					<MarginTrades radioCheck="cross" />
				</TabPanel>
				<TabPanel>
					<FuturesTrades />
				</TabPanel>
				<TabPanel>
					<OptionsTrades />
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default Trades;
