import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { ITrades } from './types';

export const trades: ITrades = {
	getTradesSpot: (params) =>
		http.get<any>(endpoint.trades.tradesSpot, { params }).then((response) => response.data),
	getTradesMargin: (params) =>
		http.get<any>(endpoint.trades.tradesSpot, { params }).then((response) => response.data),
	getFuturesTrades: (params) =>
		http.get(endpoint.trades.getFuturesTrades, { params }).then(({ data }) => data),
	getOptionsTrades: (params) =>
		http.get(endpoint.trades.getOptionsTrades, { params }).then(({ data }) => data),
};
