export const CRYPTO_TRANSACTION_STATUS_CONFIRMED = 'confirmed';
export const CRYPTO_TRANSACTION_STATUS_UNCONFIRMED = 'unconfirmed';

export const CRYPTO_TRANSACTION_STATUS_CONFIRMED_CLASS = 'status--verified';
export const CRYPTO_TRANSACTION_STATUS_UNCONFIRMED_CLASS = 'status--error';

export const CRYPTO_TRANSACTION_STATUS_CONFIRMED_TEXT = 'Confirmed';
export const CRYPTO_TRANSACTION_STATUS_UNCONFIRMED_TEXT = 'Unconfirmed';

export const cryptoTransactionsDepositStatus = {
	[CRYPTO_TRANSACTION_STATUS_CONFIRMED]: {
		statusText: CRYPTO_TRANSACTION_STATUS_CONFIRMED_TEXT,
		statusClass: CRYPTO_TRANSACTION_STATUS_CONFIRMED_CLASS,
	},
	[CRYPTO_TRANSACTION_STATUS_UNCONFIRMED]: {
		statusText: CRYPTO_TRANSACTION_STATUS_UNCONFIRMED_TEXT,
		statusClass: CRYPTO_TRANSACTION_STATUS_UNCONFIRMED_CLASS,
	},
};

export const FIAT_TRANSACTION_STATUS_COMPLETED = 'completed';
export const FIAT_TRANSACTION_STATUS_PROCESSING = 'processing';
export const FIAT_TRANSACTION_STATUS_CANCELED = 'canceled';

export const FIAT_TRANSACTION_STATUS_COMPLETED_CLASS = 'status--verified';
export const FIAT_TRANSACTION_STATUS_PROCESSING_CLASS = 'status--inprogres';
export const FIAT_TRANSACTION_STATUS_CANCELED_CLASS = 'status--error';

export const FIAT_TRANSACTION_STATUS_COMPLETED_TEXT = 'Completed';
export const FIAT_TRANSACTION_STATUS_PROCESSING_TEXT = 'Processing';
export const FIAT_TRANSACTION_STATUS_CANCELED_TEXT = 'Canceled';

export const fiatTransactionsStatus = {
	[FIAT_TRANSACTION_STATUS_COMPLETED]: {
		statusText: FIAT_TRANSACTION_STATUS_COMPLETED_TEXT,
		statusClass: FIAT_TRANSACTION_STATUS_COMPLETED_CLASS,
	},
	[FIAT_TRANSACTION_STATUS_PROCESSING]: {
		statusText: FIAT_TRANSACTION_STATUS_PROCESSING_TEXT,
		statusClass: FIAT_TRANSACTION_STATUS_PROCESSING_CLASS,
	},
	[FIAT_TRANSACTION_STATUS_CANCELED]: {
		statusText: FIAT_TRANSACTION_STATUS_CANCELED_TEXT,
		statusClass: FIAT_TRANSACTION_STATUS_CANCELED_CLASS,
	},
};

export const WITHDRAWALS_TRANSACTION_STATUS_PENDING = 'pending';
export const WITHDRAWALS_TRANSACTION_STATUS_PROCESSED = 'processed';
export const WITHDRAWALS_TRANSACTION_STATUS_REJECTED = 'rejected';
export const WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS = 'in_progress';
export const WITHDRAWALS_TRANSACTION_STATUS_INPROGRESS = 'in progress';

export const WITHDRAWALS_TRANSACTION_STATUS_PROCESSED_CLASS = 'status--inprogres';
export const WITHDRAWALS_TRANSACTION_STATUS_PENDING_CLASS = 'status--verified';
export const WITHDRAWALS_TRANSACTION_STATUS_REJECTED_CLASS = 'status--inprogres';
export const WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS_CLASS = 'status--inprogres';

export const WITHDRAWALS_TRANSACTION_STATUS_PROCESSED_TEXT = 'Processed';
export const WITHDRAWALS_TRANSACTION_STATUS_PENDING_TEXT = 'Pending';
export const WITHDRAWALS_TRANSACTION_STATUS_REJECTED_TEXT = 'Rejected';
export const WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS_TEXT = 'In progress';

export const withdrawalsTransactionsStatus = {
	[WITHDRAWALS_TRANSACTION_STATUS_PROCESSED]: {
		statusText: WITHDRAWALS_TRANSACTION_STATUS_PROCESSED_TEXT,
		statusClass: WITHDRAWALS_TRANSACTION_STATUS_PROCESSED_CLASS,
	},
	[WITHDRAWALS_TRANSACTION_STATUS_PENDING]: {
		statusText: WITHDRAWALS_TRANSACTION_STATUS_PENDING_TEXT,
		statusClass: WITHDRAWALS_TRANSACTION_STATUS_PENDING_CLASS,
	},
	[WITHDRAWALS_TRANSACTION_STATUS_REJECTED]: {
		statusText: WITHDRAWALS_TRANSACTION_STATUS_REJECTED_TEXT,
		statusClass: WITHDRAWALS_TRANSACTION_STATUS_REJECTED_CLASS,
	},
	[WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS]: {
		statusText: WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS_TEXT,
		statusClass: WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS_CLASS,
	},
	[WITHDRAWALS_TRANSACTION_STATUS_INPROGRESS]: {
		statusText: WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS_TEXT,
		statusClass: WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS_CLASS,
	},
};

export const TYPE_TRANSACTIONS_CRYPTO = 'crypto';
export const TYPE_TRANSACTIONS_FIAT = 'fiat';
export const TYPE_TRANSACTIONS_WITHDRAWALS = 'withdrawals';
export const TYPE_TRANSACTIONS_TRANSFER = 'transfer';
export const TYPE_TRANSACTIONS_CONVERTS = 'convert';

export const TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY = ['user_id', 'status', 'currency'];
export const TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY = ['User ID', 'Status', 'Asset'];

export const TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY = ['user_id', 'status', 'amount'];
export const TYPE_TRANSACTIONS_FIAT_SEARCH_TEXT_ARRAY = ['User ID', 'Status', 'Amount'];

export const TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY = [
	'user_id',
	'status',
	'currency',
	'amount',
];
export const TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY = [
	'User ID',
	'Status',
	'Asset',
	'Amount',
];

export const TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY = ['user_id', 'code', 'from', 'to'];
export const TYPE_TRANSACTIONS_TRANSFER_SEARCH_TEXT_ARRAY = ['User ID', 'Coin', 'From', 'To'];

export const TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY = ['user_id'];
export const TYPE_TRANSACTIONS_CONVERTS_SEARCH_TEXT_ARRAY = ['User ID'];

export const CRYPTO_TRANSACTION_STATUS_ARRAY = [
	'all',
	CRYPTO_TRANSACTION_STATUS_CONFIRMED,
	CRYPTO_TRANSACTION_STATUS_UNCONFIRMED,
];

export const FIAT_TRANSACTION_STATUS_ARRAY = [
	'all',
	FIAT_TRANSACTION_STATUS_COMPLETED,
	FIAT_TRANSACTION_STATUS_PROCESSING,
];
export const WITHDRAWALS_TRANSACTION_STATUS_ARRAY = [
	'all',
	WITHDRAWALS_TRANSACTION_STATUS_PENDING,
	WITHDRAWALS_TRANSACTION_STATUS_PROCESSED,
	WITHDRAWALS_TRANSACTION_STATUS_REJECTED,
	WITHDRAWALS_TRANSACTION_STATUS_IN_PROGRESS,
];

export const TRANSFERS_FROM_TO_WALLET_SPOT = 'spot';
export const TRANSFERS_FROM_TO_WALLET_ISOLATED = 'isolated';
// export const TRANSFERS_FROM_TO_WALLET_CROSS = 'cross'; // CROSS HIDE

export const TRANSFERS_FROM_TO_WALLET_ARRAY = [
	'all',
	TRANSFERS_FROM_TO_WALLET_SPOT,
	TRANSFERS_FROM_TO_WALLET_ISOLATED,
	// TRANSFERS_FROM_TO_WALLET_CROSS, // CROSS HIDE
];
export const SPOT_ORDERS_HISTORY_STATUS_CONFIRMED = 'confirmed';
export const SPOT_ORDERS_HISTORY_STATUS_FILLED = 'filled';
export const SPOT_ORDERS_HISTORY_STATUS_CANCELLED = 'cancelled';
export const SPOT_ORDERS_HISTORY_STATUS_OPENED = 'opened';

export const SPOT_ORDERS_HISTORY_STATUS_CONFIRMED_CLASS = 'status--verified';
export const SPOT_ORDERS_HISTORY_STATUS_FILLED_CLASS = 'status--filled';
export const SPOT_ORDERS_HISTORY_STATUS_CANCELLED_CLASS = 'status--error';
export const SPOT_ORDERS_HISTORY_STATUS_OPENED_CLASS = 'status--verified';

export const SPOT_ORDERS_HISTORY_STATUS_CONFIRMED_TEXT = 'Confirmed';
export const SPOT_ORDERS_HISTORY_STATUS_FILLED_TEXT = 'Filled';
export const SPOT_ORDERS_HISTORY_STATUS_CANCELLED_TEXT = 'Cancelled';
export const SPOT_ORDERS_HISTORY_STATUS_OPENED_TEXT = 'Opened';

export const spotOrdersHistoryStatus = {
	[SPOT_ORDERS_HISTORY_STATUS_CONFIRMED]: {
		statusText: SPOT_ORDERS_HISTORY_STATUS_CONFIRMED_TEXT,
		statusClass: SPOT_ORDERS_HISTORY_STATUS_CONFIRMED_CLASS,
	},
	[SPOT_ORDERS_HISTORY_STATUS_FILLED]: {
		statusText: SPOT_ORDERS_HISTORY_STATUS_FILLED_TEXT,
		statusClass: SPOT_ORDERS_HISTORY_STATUS_FILLED_CLASS,
	},
	[SPOT_ORDERS_HISTORY_STATUS_CANCELLED]: {
		statusText: SPOT_ORDERS_HISTORY_STATUS_CANCELLED_TEXT,
		statusClass: SPOT_ORDERS_HISTORY_STATUS_CANCELLED_CLASS,
	},
	[SPOT_ORDERS_HISTORY_STATUS_OPENED]: {
		statusText: SPOT_ORDERS_HISTORY_STATUS_OPENED_TEXT,
		statusClass: SPOT_ORDERS_HISTORY_STATUS_OPENED_CLASS,
	},
};

// 'under_dispute';
// 'waiting_for_payment';
// 'waiting_for_confirmation';
// 'closed';
// 'canceled';

export const P2P_DISPUTE_UNDER_STATUS_CONFIRMED = 'under_dispute';
export const P2P_DISPUTE_UNDER_STATUS_CANCELED = 'canceled';
export const P2P_DISPUTE_UNDER_STATUS_CLOSED = 'closed';
export const P2P_DISPUTE_UNDER_STATUS_WAITING = 'waiting_for_payment';
export const P2P_DISPUTE_UNDER_STATUS_WAITING_CONFIRMATION = 'waiting_for_confirmation';

export type TP2PStatus =
	| typeof P2P_DISPUTE_UNDER_STATUS_CONFIRMED
	| typeof P2P_DISPUTE_UNDER_STATUS_CANCELED
	| typeof P2P_DISPUTE_UNDER_STATUS_CLOSED
	| typeof P2P_DISPUTE_UNDER_STATUS_WAITING
	| typeof P2P_DISPUTE_UNDER_STATUS_WAITING_CONFIRMATION;

export const P2P_DISPUTE_UNDER_STATUS_CONFIRMED_CLASS = 'status--under-dispute';
export const P2P_DISPUTE_UNDER_STATUS_CANCELED_CLASS = 'status--error';
export const P2P_DISPUTE_UNDER_STATUS_CLOSED_CLASS = 'status--closed';
export const P2P_DISPUTE_UNDER_STATUS_WAITING_CLASS = 'status--waiting';
export const P2P_DISPUTE_UNDER_STATUS_WAITING_CONFIRMATION_CLASS = 'status--waiting_confirmation';

export const P2P_DISPUTE_UNDER_STATUS_CONFIRMED_TEXT = 'Under dispute';
export const P2P_DISPUTE_UNDER_STATUS_CANCELED_TEXT = 'Canceled';
export const P2P_DISPUTE_UNDER_STATUS_CLOSED_TEXT = 'Closed';
export const P2P_DISPUTE_UNDER_STATUS_WAITING_TEXT = 'Waiting payment';
export const P2P_DISPUTE_UNDER_STATUS_WAITING_CONFIRMATION_TEXT = 'Waiting confirmation';

export const p2pStatus = {
	[P2P_DISPUTE_UNDER_STATUS_CONFIRMED]: {
		statusText: P2P_DISPUTE_UNDER_STATUS_CONFIRMED_TEXT,
		statusClass: P2P_DISPUTE_UNDER_STATUS_CONFIRMED_CLASS,
	},
	[P2P_DISPUTE_UNDER_STATUS_CANCELED]: {
		statusText: P2P_DISPUTE_UNDER_STATUS_CANCELED_TEXT,
		statusClass: P2P_DISPUTE_UNDER_STATUS_CANCELED_CLASS,
	},
	[P2P_DISPUTE_UNDER_STATUS_CLOSED]: {
		statusText: P2P_DISPUTE_UNDER_STATUS_CLOSED_TEXT,
		statusClass: P2P_DISPUTE_UNDER_STATUS_CLOSED_CLASS,
	},
	[P2P_DISPUTE_UNDER_STATUS_WAITING]: {
		statusText: P2P_DISPUTE_UNDER_STATUS_WAITING_TEXT,
		statusClass: P2P_DISPUTE_UNDER_STATUS_WAITING_CLASS,
	},
	[P2P_DISPUTE_UNDER_STATUS_WAITING_CONFIRMATION]: {
		statusText: P2P_DISPUTE_UNDER_STATUS_WAITING_CONFIRMATION_TEXT,
		statusClass: P2P_DISPUTE_UNDER_STATUS_WAITING_CONFIRMATION_CLASS,
	},
};

export const ALL_STATUS_PROCESSED = 'processed';
export const ALL_STATUS_CONFIRMED = 'confirmed';
export const ALL_STATUS_PENDING = 'pending';
export const ALL_STATUS_REJECTED = 'rejected';
export const ALL_STATUS_IN_PROGRESS = 'in_progress';
export const ALL_STATUS_IN_SPACE_PROGRESS = 'in progress';
export const ALL_STATUS_FILLED = 'filled';
export const ALL_STATUS_OPENED = 'opened';
export const ALL_STATUS_CANCELLED = 'cancelled';
export const ALL_STATUS_EXECUTED = 'executed';
export const ALL_STATUS_CANCELED = 'canceled';
export const ALL_STATUS_EXPIRED = 'expired';

export const ALL_STATUS_PROCESSED_CLASS = 'status--processed';
export const ALL_STATUS_CANCELLED_CLASS = 'status--error';
export const ALL_STATUS_CONFIRMED_CLASS = 'status--verified';
export const ALL_STATUS_OPENED_CLASS = 'status--verified';
export const ALL_STATUS_PENDING_CLASS = 'status--pending';
export const ALL_STATUS_REJECTED_CLASS = 'status--rejected';
export const ALL_STATUS_IN_PROGRESS_CLASS = 'status--inprogres';
export const ALL_STATUS_IN_SPACE_PROGRESS_CLASS = 'status--inprogres';
export const ALL_STATUS_FILLER_CLASS = 'status--inprogres';
export const ALL_STATUS_EXECUTED_CLASS = 'status--inprogres';
export const ALL_STATUS_CANCELED_CLASS = 'status--error';
export const ALL_STATUS_EXPIRED_CLASS = 'status--error';

export const ALL_STATUS_CANCELLED_TEXT = 'Cancelled';
export const ALL_STATUS_PROCESSED_TEXT = 'Processed';
export const ALL_STATUS_CONFIRMED_TEXT = 'Confirmed';
export const ALL_STATUS_OPENED_TEXT = 'Opened';
export const ALL_STATUS_PENDING_TEXT = 'Pending';
export const ALL_STATUS_REJECTED_TEXT = 'Rejected';
export const ALL_STATUS_IN_PROGRESS_TEXT = 'In progress';
export const ALL_STATUS_FILLER_TEXT = 'Filled';
export const ALL_STATUS_EXECUTED_TEXT = 'Filled';
export const ALL_STATUS_CANCELED_TEXT = 'Cancelled';
export const ALL_STATUS_EXPIRED_TEXT = 'Expired';

export const allStatus = {
	[ALL_STATUS_CANCELLED]: {
		statusText: ALL_STATUS_CANCELLED_TEXT,
		statusClass: ALL_STATUS_CANCELLED_CLASS,
	},
	[ALL_STATUS_OPENED]: {
		statusText: ALL_STATUS_OPENED_TEXT,
		statusClass: ALL_STATUS_OPENED_CLASS,
	},
	[ALL_STATUS_FILLED]: {
		statusText: ALL_STATUS_FILLER_TEXT,
		statusClass: ALL_STATUS_FILLER_CLASS,
	},
	[ALL_STATUS_PROCESSED]: {
		statusText: ALL_STATUS_PROCESSED_TEXT,
		statusClass: ALL_STATUS_PROCESSED_CLASS,
	},
	[ALL_STATUS_CONFIRMED]: {
		statusText: ALL_STATUS_CONFIRMED_TEXT,
		statusClass: ALL_STATUS_CONFIRMED_CLASS,
	},
	[ALL_STATUS_PENDING]: {
		statusText: ALL_STATUS_PENDING_TEXT,
		statusClass: ALL_STATUS_PENDING_CLASS,
	},
	[ALL_STATUS_REJECTED]: {
		statusText: ALL_STATUS_REJECTED_TEXT,
		statusClass: ALL_STATUS_REJECTED_CLASS,
	},
	[ALL_STATUS_IN_PROGRESS]: {
		statusText: ALL_STATUS_IN_PROGRESS_TEXT,
		statusClass: ALL_STATUS_IN_PROGRESS_CLASS,
	},
	[ALL_STATUS_IN_SPACE_PROGRESS]: {
		statusText: ALL_STATUS_IN_PROGRESS_TEXT,
		statusClass: ALL_STATUS_IN_SPACE_PROGRESS_CLASS,
	},
	[ALL_STATUS_EXECUTED]: {
		statusText: ALL_STATUS_EXECUTED_TEXT,
		statusClass: ALL_STATUS_EXECUTED_CLASS,
	},
	[ALL_STATUS_CANCELED]: {
		statusText: ALL_STATUS_CANCELED_TEXT,
		statusClass: ALL_STATUS_CANCELED_CLASS,
	},
	[ALL_STATUS_EXPIRED]: {
		statusText: ALL_STATUS_EXPIRED_TEXT,
		statusClass: ALL_STATUS_EXPIRED_CLASS,
	},
};
