/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiGetFuturesUserOrderHistoryParams,
	IApiGetOneUserResponse,
	IApiGetOrderHistoryParams,
	TApiGetFuturesUserBalancesResponse,
	IApiGetUserTransactionsCryptoParams,
	TApiGetFuturesUserOrderHistoryResponse,
	TApiGetFuturesUserPositionsResponse,
	TApiGetOrderHistoryResponse,
	TApiGetUserBalancesResponse,
	TApiGetUserTransactionsCryptoResponse,
	IApiGetOptionsPositionsByUserParams,
	TApiGetOptionsPositionsByUserResponse,
	IApiGetOptionsOrdersHistoryByUserParams,
	TApiGetOptionsOrdersHistoryByUserResponse,
	IApiGetOptionsTradesHistoryByUserParams,
	TApiGetOptionsTradesHistoryByUserResponse,
	IApiGetOptionsDeliveryByUserParams,
	TApiGetOptionsDeliveryByUserResponse,
} from 'services/api/users/types';

import {
	I2FAListRequestPayload,
	I2FARejectPayload,
	I2FAResetPayload,
	I2FAResponsePayload,
	IDepositAndWithdrawHistoryRequestData,
	IDepositAndWithdrawHistoryResponsePayload,
	ILoginHistoryRequestData,
	ILoginHistoryResponsePayload,
	IUserBalancesRequestData,
	IUserBalancesResponsePayload,
	IUserBlockUnblockRequestData,
	IUserOrdersHistoryMarginRequestData,
	IUserRequestPayload,
	IUsersListRequestPayload,
	IUsersResponsePayload,
	IUsersStore,
	IWhitelistIpListResponsePayload,
	IWhitelistIpRemoveRequestData,
	IWhitelistIpRemoveResponsePayload,
	IWhitelistIpRequestData,
	IWhitelistIpVerifyRequestData,
	IWhitelistIpVerifyResponsePayload,
	TApiGetFuturesUserMarkPriceResponse,
	TApiGetFuturesUserLiquidationHistoryResponse,
	TApiGetFuturesUserTradeHistoryResponse,
	TOneUserDateRange,
} from './types';

export const initialState: IUsersStore = {
	tabMenuUserIndex: null,

	users: null,
	usersLoader: false,

	userBlockUnblockLoader: false,

	selectUser: null,
	userLoader: false,

	oneUserDateRange: null,

	userBalances: null,
	userBalancesLoader: false,
	userOrdersHistory: null,
	userOrdersHistoryLoader: false,
	userOrdersHistoryMargin: null,
	userOrdersHistoryMarginLoader: false,
	whitelistIp: null,
	whitelistIpLoader: false,
	whitelistIpRemove: null,
	whitelistIpRemoveLoader: false,
	whitelistIpVerify: null,
	whitelistIpVerifyLoader: false,
	id: 0,
	twoFA: null,
	twoFALoader: false,
	twoFAResetLoader: false,
	twoFARejectLoader: false,
	loginHistory: null,
	loginHistoryLoader: false,
	depositAndWithdrawHistory: null,
	depositAndWithdrawHistoryLoader: false,
	userBorrowingLoader: false,
	userBorrowingMargin: null,
	userRepaymentLoader: false,
	userRepaymentMargin: null,
	userInterestLoader: false,
	userInterestMargin: null,
	userLiquidationLoader: false,
	userLiquidationMargin: null,
	userBalancesMarginLoader: false,
	userBalancesMargin: null,
	userMarginCallMarginLoader: false,
	userMarginCallMargin: null,

	// User details / Transaction history
	userCryptoDepositLoader: false,
	userCryptoDeposit: null,
	userFiatDepositLoader: false,
	userFiatDeposit: null,
	userConvertsLoader: false,
	userConverts: null,
	userTransfersLoader: false,
	userTransfers: null,

	// Futures
	futuresUserOrderHistory: null,
	futuresUserOrderHistoryLoading: false,
	// Positions
	futuresUserPositions: null,
	futuresUserPositionsLoading: false,
	futuresUserPositionsError: false,
	// Balances
	futuresUserBalances: null,
	futuresUserBalancesLoading: false,
	futuresUserBalancesError: false,
	// Trade history
	futuresUserTradeHistory: null,
	futuresUserTradeHistoryLoading: false,
	futuresUserTradeHistoryError: false,
	// features mark price
	futuresUserMarkPrice: null,
	futuresUserMarkPriceLoading: false,
	futuresUserMarkPriceError: false,
	// LiquidationHistory
	futuresUserLiquidationHistory: null,
	futuresUserLiquidationHistoryLoading: false,
	futuresUserLiquidationHistoryError: false,

	// User details / Options
	optionsPositionsByUser: null,
	optionsPositionsByUserLoading: false,
	optionsOrdersHistoryByUser: null,
	optionsOrdersHistoryByUserLoading: false,
	optionsTradesHistoryByUser: null,
	optionsTradesHistoryByUserLoading: false,
	optionsDeliveryByUser: null,
	optionsDeliveryByUserLoading: false,
	optionsLiquidationHistoryByUserLoading: false,
	optionsLiquidationHistoryByUser: null,
};

const users = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		setTabMenuUserIndex: (state, action) => {
			const usersState = state;
			const { payload } = action;
			usersState.tabMenuUserIndex = payload;
		},

		getUsersRequest: (state, action: PayloadAction<IUsersListRequestPayload>) => {
			const usersRequestState = state;
			usersRequestState.usersLoader = true;
		},
		getUsersSuccess: (state, action: PayloadAction<IUsersResponsePayload>) => {
			const { payload } = action;
			const usersSuccessState = state;
			usersSuccessState.users = payload;
			usersSuccessState.usersLoader = false;
		},
		updateUsersSuccess: (state, action: PayloadAction<IUsersResponsePayload>) => {
			const { payload } = action;
			const usersUpdateState = state;
			usersUpdateState.users = payload;
			usersUpdateState.usersLoader = false;
		},

		getUserRequest: (state, action: PayloadAction<IUserRequestPayload>) => {
			state.userLoader = true;
		},
		getUserSuccess: (state, { payload }: PayloadAction<IApiGetOneUserResponse>) => {
			state.selectUser = payload;
			state.userLoader = false;
		},

		setOneUserDateRange: (state, { payload }: PayloadAction<TOneUserDateRange>) => {
			state.oneUserDateRange = payload;
		},
		unsetOneUserDateRange: (state) => {
			state.oneUserDateRange = null;
		},

		getUserBalancesRequest: (state, action: PayloadAction<IUserBalancesRequestData>) => {
			state.userBalancesLoader = true;
		},
		getUserBalancesSuccess: (state, { payload }: PayloadAction<TApiGetUserBalancesResponse>) => {
			state.userBalances = payload;
			state.userBalancesLoader = false;
		},

		getWhitelistIpRequest: (state, action: PayloadAction<IWhitelistIpRequestData>) => {
			const whitelistIpRequestState = state;
			whitelistIpRequestState.whitelistIpLoader = true;
			const whitelistIp = state;
		},

		getWhitelistIpSuccess: (state, action: PayloadAction<IWhitelistIpListResponsePayload>) => {
			const { payload } = action;
			const whitelistIpSuccessState = state;

			whitelistIpSuccessState.whitelistIp = payload;
			whitelistIpSuccessState.whitelistIpLoader = false;
		},

		removeWhitelistIpRequest: (state, action: PayloadAction<IWhitelistIpRemoveRequestData>) => {
			const whitelistIpRequestRemoveState = state;
			whitelistIpRequestRemoveState.whitelistIpRemoveLoader = true;
		},

		removeWhitelistIpSuccess: (state, action: PayloadAction<IWhitelistIpRemoveResponsePayload>) => {
			const { payload } = action;
			const whitelistIpRemoveSuccessState = state;

			whitelistIpRemoveSuccessState.whitelistIpRemove = payload;
			whitelistIpRemoveSuccessState.whitelistIpRemoveLoader = false;
		},

		verifyWhitelistIpRequest: (state, action: PayloadAction<IWhitelistIpVerifyRequestData>) => {
			const whitelistIpRequestRemoveState = state;
			whitelistIpRequestRemoveState.whitelistIpRemoveLoader = true;
		},

		verifyWhitelistIpSuccess: (state, action: PayloadAction<IWhitelistIpVerifyResponsePayload>) => {
			const { payload } = action;
			const whitelistIpVerifySuccessState = state;

			whitelistIpVerifySuccessState.whitelistIpVerify = payload;
			whitelistIpVerifySuccessState.whitelistIpVerifyLoader = false;
		},

		setUserBlockUnblockRequest: (state, action: PayloadAction<IUserBlockUnblockRequestData>) => {
			state.userBlockUnblockLoader = true;
		},
		setUserBlockUnblockSuccess: (state) => {
			state.userBlockUnblockLoader = false;
		},

		get2FARequest: (state, action: PayloadAction<I2FAListRequestPayload>) => {
			const twoFARequestState = state;
			twoFARequestState.twoFALoader = true;
		},
		get2FASuccess: (state, action: PayloadAction<I2FAResponsePayload>) => {
			const { payload } = action;
			const twoFASuccessState = state;
			twoFASuccessState.twoFA = payload;
			twoFASuccessState.twoFALoader = false;
		},
		reset2FA: (state, action: PayloadAction<I2FAResetPayload>) => {
			const { payload } = action;
			const twoFAResetState = state;
			twoFAResetState.twoFAResetLoader = true;
		},
		reset2FASuccess: (state) => {
			const twoFAResetSuccessState = state;
			twoFAResetSuccessState.twoFAResetLoader = false;
		},
		reject2FA: (state, action: PayloadAction<I2FARejectPayload>) => {
			const { payload } = action;
			const twoFARejectState = state;
			twoFARejectState.twoFARejectLoader = true;
		},
		reject2FASuccess: (state) => {
			const twoFAResetSuccessState = state;
			twoFAResetSuccessState.twoFARejectLoader = false;
		},

		getLoginHistoryRequest: (state, action: PayloadAction<ILoginHistoryRequestData>) => {
			const loginHistoryRequestState = state;
			loginHistoryRequestState.loginHistoryLoader = true;
			const loginHistory = state;
		},

		getLoginHistorySuccess: (state, action: PayloadAction<ILoginHistoryResponsePayload>) => {
			const { payload } = action;
			const loginHistorySuccessState = state;

			loginHistorySuccessState.loginHistory = payload;
			loginHistorySuccessState.loginHistoryLoader = false;
		},

		getDepositAndWithdrawHistoryRequest: (
			state,
			action: PayloadAction<IDepositAndWithdrawHistoryRequestData>,
		) => {
			const depositAndWithdrawHistoryRequestState = state;
			depositAndWithdrawHistoryRequestState.depositAndWithdrawHistoryLoader = true;
		},

		getDepositAndWithdrawHistorySuccess: (
			state,
			action: PayloadAction<IDepositAndWithdrawHistoryResponsePayload>,
		) => {
			const { payload } = action;
			const depositAndWithdrawHistorySuccessState = state;

			depositAndWithdrawHistorySuccessState.depositAndWithdrawHistory = payload;
			depositAndWithdrawHistorySuccessState.depositAndWithdrawHistoryLoader = false;
		},

		getUserOrdersHistoryRequest: (state, action: PayloadAction<IApiGetOrderHistoryParams>) => {
			state.userOrdersHistoryLoader = true;
		},
		getUserOrdersHistorySuccess: (state, action: PayloadAction<TApiGetOrderHistoryResponse>) => {
			state.userOrdersHistory = action.payload;
			state.userOrdersHistoryLoader = false;
		},

		getUserMarginOrdersHistoryRequest: (
			state,
			action: PayloadAction<IApiGetOrderHistoryParams>,
		) => {
			state.userOrdersHistoryMarginLoader = true;
		},
		getUserMarginOrdersHistorySuccess: (
			state,
			{ payload }: PayloadAction<TApiGetOrderHistoryResponse>,
		) => {
			state.userOrdersHistoryMargin = payload;
			state.userOrdersHistoryMarginLoader = false;
		},

		getUserMarginBorrowingRequest: (state, action: PayloadAction<any>) => {
			state.userBorrowingLoader = true;
		},
		getUserMarginBorrowingSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginBorrowingRequestState = state;
			userMarginBorrowingRequestState.userBorrowingMargin = payload;
			userMarginBorrowingRequestState.userBorrowingLoader = false;
		},

		getUserMarginRepaymentRequest: (state, action: PayloadAction<any>) => {
			state.userRepaymentLoader = true;
		},
		getUserMarginRepaymentSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginRepaymentRequestState = state;
			userMarginRepaymentRequestState.userRepaymentMargin = payload;
			userMarginRepaymentRequestState.userRepaymentLoader = false;
		},
		getUserMarginInterestRequest: (state, action: PayloadAction<any>) => {
			state.userInterestLoader = true;
		},
		getUserMarginInterestSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginInterestRequestState = state;
			userMarginInterestRequestState.userInterestMargin = payload;
			userMarginInterestRequestState.userInterestLoader = false;
		},

		getUserMarginLiquidationRequest: (state, action: PayloadAction<any>) => {
			state.userLiquidationLoader = true;
		},
		getUserMarginLiquidationSuccess: (
			state,
			action: PayloadAction<IUserBalancesResponsePayload>,
		) => {
			const { payload } = action;
			const userMarginLiquidationRequestState = state;
			userMarginLiquidationRequestState.userLiquidationMargin = payload;
			userMarginLiquidationRequestState.userLiquidationLoader = false;
		},

		getUserMarginBalancesRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userMarginBalancesRequestState = state;
			userMarginBalancesRequestState.userBalancesMarginLoader = true;
		},
		getUserMarginBalancesSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userMarginBalancesRequestState = state;
			userMarginBalancesRequestState.userBalancesMargin = payload;
			userMarginBalancesRequestState.userBalancesMarginLoader = false;
		},

		getUserMarginMarginCallRequest: (state, action: PayloadAction<any>) => {
			state.userMarginCallMarginLoader = true;
		},
		getUserMarginMarginCallSuccess: (
			state,
			action: PayloadAction<IUserBalancesResponsePayload>,
		) => {
			const { payload } = action;
			const userMarginMarginCallRequestState = state;
			userMarginMarginCallRequestState.userMarginCallMargin = payload;
			userMarginMarginCallRequestState.userMarginCallMarginLoader = false;
		},

		// User details / Transaction history

		getUserCryptoDepositRequest: (
			state,
			action: PayloadAction<IApiGetUserTransactionsCryptoParams>,
		) => {
			state.userCryptoDepositLoader = true;
		},
		getUserCryptoDepositSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetUserTransactionsCryptoResponse>,
		) => {
			state.userCryptoDeposit = payload;
			state.userCryptoDepositLoader = false;
		},

		getUserFiatDepositRequest: (state, action: PayloadAction<any>) => {
			state.userFiatDepositLoader = true;
		},
		getUserFiatDepositSuccess: (state, { payload }: PayloadAction<any>) => {
			state.userFiatDeposit = payload;
			state.userFiatDepositLoader = false;
		},

		getUserConvertsRequest: (state, action: PayloadAction<IUserOrdersHistoryMarginRequestData>) => {
			const userСonvertsState = state;
			userСonvertsState.userConvertsLoader = true;
		},
		getUserConvertsSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userConvertsDepositRequestState = state;
			userConvertsDepositRequestState.userConverts = payload;
			userConvertsDepositRequestState.userConvertsLoader = false;
		},

		getUserTransfersRequest: (
			state,
			action: PayloadAction<IUserOrdersHistoryMarginRequestData>,
		) => {
			const userTransfersState = state;
			userTransfersState.userTransfersLoader = true;
		},
		getUserTransfersSuccess: (state, action: PayloadAction<IUserBalancesResponsePayload>) => {
			const { payload } = action;
			const userTransfersDepositRequestState = state;
			userTransfersDepositRequestState.userTransfers = payload;
			userTransfersDepositRequestState.userTransfersLoader = false;
		},

		// Futures

		getFuturesUserOrderHistoryRequest: (
			state,
			action: PayloadAction<IApiGetFuturesUserOrderHistoryParams>,
		) => {
			state.futuresUserOrderHistoryLoading = true;
		},
		getFuturesUserOrderHistorySuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesUserOrderHistoryResponse>,
		) => {
			state.futuresUserOrderHistory = payload;
			state.futuresUserOrderHistoryLoading = false;
		},
		getFuturesUserOrderHistoryError: (state) => {
			state.futuresUserOrderHistory = null;
			state.futuresUserOrderHistoryLoading = false;
		},
		// Futures Positions
		getFuturesUserPositionsRequest: (
			state,
			action: PayloadAction<IApiGetFuturesUserOrderHistoryParams>,
		) => {
			state.futuresUserPositionsLoading = true;
		},
		getFuturesUserPositionsSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesUserPositionsResponse>,
		) => {
			state.futuresUserPositions = payload;
			state.futuresUserPositionsLoading = false;
		},
		getFuturesUserPositionsError: (state) => {
			state.futuresUserPositions = null;
			state.futuresUserPositionsLoading = false;
		},
		// Futures Balances
		getFuturesUserBalancesRequest: (
			state,
			action: PayloadAction<IApiGetFuturesUserOrderHistoryParams>,
		) => {
			state.futuresUserBalancesLoading = true;
		},
		getFuturesUserBalancesSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesUserBalancesResponse>,
		) => {
			state.futuresUserBalances = payload;
			state.futuresUserBalancesLoading = false;
		},
		getFuturesUserBalancesError: (state) => {
			state.futuresUserBalances = null;
			state.futuresUserBalancesLoading = false;
		},
		// Trade History
		getFuturesUserTradeHistoryRequest: (
			state,
			action: PayloadAction<IApiGetFuturesUserOrderHistoryParams>,
		) => {
			state.futuresUserTradeHistoryLoading = true;
		},
		getFuturesUserTradeHistorySuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesUserTradeHistoryResponse>,
		) => {
			state.futuresUserTradeHistory = payload;
			state.futuresUserTradeHistoryLoading = false;
		},
		getFuturesUserTradeHistoryError: (state) => {
			state.futuresUserTradeHistory = null;
			state.futuresUserTradeHistoryLoading = false;
		},
		// features mark price
		getFuturesUserMarkPriceRequest: (
			state,
			action: PayloadAction<IApiGetFuturesUserOrderHistoryParams>,
		) => {
			state.futuresUserMarkPriceLoading = true;
		},
		getFuturesUserMarkPriceSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesUserMarkPriceResponse>,
		) => {
			state.futuresUserMarkPrice = payload;
			state.futuresUserMarkPriceLoading = false;
		},

		getFuturesUserMarkPriceError: (state) => {
			state.futuresUserMarkPrice = null;
			state.futuresUserMarkPriceLoading = false;
		},
		// Trade LiquidationHistory
		getFuturesUserLiquidationHistoryRequest: (
			state,
			action: PayloadAction<IApiGetFuturesUserOrderHistoryParams>,
		) => {
			state.futuresUserLiquidationHistoryLoading = true;
		},
		getFuturesUserLiquidationHistorySuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesUserLiquidationHistoryResponse>,
		) => {
			state.futuresUserLiquidationHistory = payload;
			state.futuresUserLiquidationHistoryLoading = false;
		},
		getFuturesUserLiquidationHistoryError: (state) => {
			state.futuresUserLiquidationHistory = null;
			state.futuresUserLiquidationHistoryLoading = false;
		},

		// User details / Options

		getOptionsPositionsByUserRequest: (
			state,
			action: PayloadAction<IApiGetOptionsPositionsByUserParams>,
		) => {
			state.optionsPositionsByUserLoading = true;
		},
		getOptionsPositionsByUserSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetOptionsPositionsByUserResponse>,
		) => {
			state.optionsPositionsByUser = payload;
			state.optionsPositionsByUserLoading = false;
		},
		getOptionsPositionsByUserError: (state) => {
			state.optionsPositionsByUser = null;
			state.optionsPositionsByUserLoading = false;
		},

		getOptionsOrdersHistoryByUserRequest: (
			state,
			action: PayloadAction<IApiGetOptionsOrdersHistoryByUserParams>,
		) => {
			state.optionsOrdersHistoryByUserLoading = true;
		},
		getOptionsOrdersHistoryByUserSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetOptionsOrdersHistoryByUserResponse>,
		) => {
			state.optionsOrdersHistoryByUser = payload;
			state.optionsOrdersHistoryByUserLoading = false;
		},
		getOptionsOrdersHistoryByUserError: (state) => {
			state.optionsOrdersHistoryByUser = null;
			state.optionsOrdersHistoryByUserLoading = false;
		},

		getOptionsTradesHistoryByUserRequest: (
			state,
			action: PayloadAction<IApiGetOptionsTradesHistoryByUserParams>,
		) => {
			state.optionsTradesHistoryByUserLoading = true;
		},
		getOptionsTradesHistoryByUserSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetOptionsTradesHistoryByUserResponse>,
		) => {
			state.optionsTradesHistoryByUser = payload;
			state.optionsTradesHistoryByUserLoading = false;
		},
		getOptionsTradesHistoryByUserError: (state) => {
			state.optionsTradesHistoryByUser = null;
			state.optionsTradesHistoryByUserLoading = false;
		},

		getOptionsDeliveryByUserRequest: (
			state,
			action: PayloadAction<IApiGetOptionsDeliveryByUserParams>,
		) => {
			state.optionsDeliveryByUserLoading = true;
		},
		getOptionsDeliveryByUserSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetOptionsDeliveryByUserResponse>,
		) => {
			state.optionsDeliveryByUser = payload;
			state.optionsDeliveryByUserLoading = false;
		},
		getOptionsDeliveryByUserError: (state) => {
			state.optionsDeliveryByUser = null;
			state.optionsDeliveryByUserLoading = false;
		},
		getOptionsLiquidationHistoryByUserRequest: (state, action) => {
			console.log(action.payload);
			state.optionsLiquidationHistoryByUserLoading = true;
		},
		getOptionsLiquidationHistoryByUserSuccess: (state, { payload }) => {
			state.optionsLiquidationHistoryByUserLoading = false;
			state.optionsLiquidationHistoryByUser = payload;
		},

		usersInitState: () => initialState,
	},
});
export default users.reducer;
export const {
	getUsersRequest,
	getUsersSuccess,
	updateUsersSuccess,
	getUserRequest,
	getUserSuccess,
	setOneUserDateRange,
	unsetOneUserDateRange,
	getUserBalancesRequest,
	getUserBalancesSuccess,
	getWhitelistIpRequest,
	getWhitelistIpSuccess,
	removeWhitelistIpRequest,
	removeWhitelistIpSuccess,
	verifyWhitelistIpRequest,
	verifyWhitelistIpSuccess,
	setUserBlockUnblockRequest,
	setUserBlockUnblockSuccess,
	usersInitState,
	get2FARequest,
	get2FASuccess,
	reset2FA,
	reset2FASuccess,
	reject2FA,
	reject2FASuccess,
	getLoginHistoryRequest,
	getLoginHistorySuccess,
	getDepositAndWithdrawHistoryRequest,
	getDepositAndWithdrawHistorySuccess,
	getUserOrdersHistoryRequest,
	getUserOrdersHistorySuccess,
	getUserMarginOrdersHistoryRequest,
	getUserMarginOrdersHistorySuccess,
	getUserMarginBorrowingRequest,
	getUserMarginBorrowingSuccess,
	getUserMarginRepaymentRequest,
	getUserMarginRepaymentSuccess,
	getUserMarginInterestRequest,
	getUserMarginInterestSuccess,
	getUserMarginLiquidationRequest,
	getUserMarginLiquidationSuccess,
	getUserMarginBalancesRequest,
	getUserMarginBalancesSuccess,
	getUserMarginMarginCallRequest,
	getUserMarginMarginCallSuccess,
	getUserCryptoDepositRequest,
	getUserCryptoDepositSuccess,
	getUserFiatDepositRequest,
	getUserFiatDepositSuccess,
	getUserConvertsRequest,
	getUserConvertsSuccess,
	getUserTransfersRequest,
	getUserTransfersSuccess,
	setTabMenuUserIndex,
	getFuturesUserOrderHistoryRequest,
	getFuturesUserOrderHistorySuccess,
	getFuturesUserOrderHistoryError,
	getFuturesUserPositionsRequest,
	getFuturesUserPositionsSuccess,
	getFuturesUserPositionsError,
	getFuturesUserBalancesRequest,
	getFuturesUserBalancesSuccess,
	getFuturesUserBalancesError,
	getFuturesUserTradeHistoryRequest,
	getFuturesUserTradeHistorySuccess,
	getFuturesUserTradeHistoryError,
	getFuturesUserMarkPriceRequest,
	getFuturesUserMarkPriceSuccess,
	getFuturesUserMarkPriceError,
	getFuturesUserLiquidationHistoryRequest,
	getFuturesUserLiquidationHistorySuccess,
	getFuturesUserLiquidationHistoryError,
	getOptionsPositionsByUserRequest,
	getOptionsPositionsByUserSuccess,
	getOptionsPositionsByUserError,
	getOptionsOrdersHistoryByUserRequest,
	getOptionsOrdersHistoryByUserSuccess,
	getOptionsOrdersHistoryByUserError,
	getOptionsTradesHistoryByUserRequest,
	getOptionsTradesHistoryByUserSuccess,
	getOptionsTradesHistoryByUserError,
	getOptionsDeliveryByUserRequest,
	getOptionsDeliveryByUserSuccess,
	getOptionsDeliveryByUserError,
	getOptionsLiquidationHistoryByUserRequest,
	getOptionsLiquidationHistoryByUserSuccess,
} = users.actions;
