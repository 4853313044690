import { FC } from 'react';
import { capitalizeFirstLetter, toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';

export const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{item.id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.contract_name}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Direction</p>
				<p>{capitalizeFirstLetter(item.transactions_type)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Filled Price</p>
				<p>{Number(toMaxDecimals(item.fill_price, 6)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">QTY</p>
				<p>{toMaxDecimals(item.amount_asset_filled, 6)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Implied Volatility</p>
				<p>{Number(toMaxDecimals(item.iv_coefficient, 2)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Underlying Price</p>
				<p>{Number(toMaxDecimals(item.index_price, 6)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Mark Price</p>
				<p>{Number(toMaxDecimals(item.mark_price, 6)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Fees</p>
				<p>{Number(item.fee_usdt).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order Time</p>
				<p>{new Date(item.created_at).toLocaleString('en-GB')}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Trade Time</p>
				<p>{new Date(item.updated_at).toLocaleString('en-GB')}</p>
			</div>
		</div>
	);
};
