import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getBalanceIsLoad,
	getBalanceList,
	getHotBalanceIsLoad,
	getHotBalanceList,
	getTotalFeeCollectedIsLoad,
	getTotalFeeCollectedList,
} from 'redux/reducers/balance/selectors';
import Loader from 'ui/Loader';
import {
	getBalanceListRequest,
	getHotBalanceListRequest,
	getTotalFeeCollectedListRequest,
} from 'redux/reducers/balance/reducer';
import Balance from './Balances';
import HotBalance from './BalanceHotWallet';
import TotalFeeCollected from './TotalFeeCollected';

const AdminPanel: FC = () => {
	const balanceList = useSelector(getBalanceList);
	const balanceListIsLoad = useSelector(getBalanceIsLoad);
	const hotBalanceList = useSelector(getHotBalanceList);
	const hotBalanceListIsLoad = useSelector(getHotBalanceIsLoad);
	const totalFeeCollectedList = useSelector(getTotalFeeCollectedList);
	const totalFeeCollectedListIsLoad = useSelector(getTotalFeeCollectedIsLoad);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getBalanceListRequest());
		dispatch(getHotBalanceListRequest());
		dispatch(getTotalFeeCollectedListRequest());
	}, [dispatch]);

	return (
		<>
			<div className="title-block">
				<p className="title">Admin panel</p>
			</div>

			{balanceListIsLoad || !balanceList?.length ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<Balance data={balanceList} />
			)}

			{hotBalanceListIsLoad || !hotBalanceList?.length ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<HotBalance data={hotBalanceList} />
			)}

			{totalFeeCollectedListIsLoad || !totalFeeCollectedList?.length ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<TotalFeeCollected data={totalFeeCollectedList} />
			)}
		</>
	);
};

export default AdminPanel;
