import { spawn } from 'redux-saga/effects';
import { adminManagementSaga } from 'redux/reducers/adminManagement/saga';
import { authSaga } from 'redux/reducers/auth/saga';
import { balanceSaga } from 'redux/reducers/balance/saga';
import { settingsSaga } from 'redux/reducers/settings/saga';
import { usersSaga } from 'redux/reducers/userManagement/saga';
import { feesSaga } from 'redux/reducers/fees/saga';
import { withdrawalsSaga } from './reducers/withdrawals/saga';
import { notificationsSaga } from './reducers/notifications/saga';
import { tradingPairsSaga } from './reducers/tradingPairs/saga';
import { walletManagementSaga } from './reducers/walletManagement/saga';
import { marketMakerSaga } from './reducers/marketMakerBot/saga';
import { limitationsSaga } from './reducers/limitations/saga';
import { transactionsSaga } from './reducers/transactions/saga';
import { tradesSaga } from './reducers/trade/saga';
import { ordersSaga } from './reducers/orders/saga';
import { disputeSaga } from './reducers/dispute/saga';
import { currenciesSaga } from './reducers/сurrencies/saga';
import { socketsSaga } from './reducers/sockets/saga';
import { chatSaga } from './reducers/chat/saga';
// ==========================================:
export default function* rootSagas() {
	yield spawn(adminManagementSaga);
	yield spawn(authSaga);
	yield spawn(settingsSaga);
	yield spawn(usersSaga);
	yield spawn(balanceSaga);
	yield spawn(feesSaga);
	yield spawn(withdrawalsSaga);
	yield spawn(notificationsSaga);
	yield spawn(tradingPairsSaga);
	yield spawn(walletManagementSaga);
	yield spawn(marketMakerSaga);
	yield spawn(limitationsSaga);
	yield spawn(transactionsSaga);
	yield spawn(transactionsSaga);
	yield spawn(tradesSaga);
	yield spawn(ordersSaga);
	yield spawn(disputeSaga);
	yield spawn(currenciesSaga);
	yield spawn(socketsSaga);
	yield spawn(chatSaga);
}
