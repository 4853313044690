import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { TradingPairs } from 'components/TradingPairs';

// ================================================:
const TradingPairsPage: FC = () => {
	return (
		<Dashboard title="Trading Pairs">
			<TradingPairs />
		</Dashboard>
	);
};

export default TradingPairsPage;
