import { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import { IRejectWithdrawalsModalProps } from './types';

// ================================================:
export const RejectWithdrawalsModal: FC<IRejectWithdrawalsModalProps> = ({
	openModal,
	closeModal,
	handleRejectWithdrawal,
}) => {
	const [comment, setComment] = useState('');
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window admin-managment-popup">
				<div className="popup-window__inside">
					<div className="popup">
						<button
							onClick={() => {
								closeModal();
								setComment('');
							}}
							type="button"
							className="popup__close"
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#011E26" />
							</svg>
						</button>
						<div className="popup-header">
							<h3 className="popup-header__title">Reject withdrawal</h3>
						</div>
						<div className="popup-body">
							<div className="popup-text popup-text--center">
								<p>Are you sure you want to reject the withdrawal?</p>
							</div>
							<textarea
								onChange={(e) => setComment(e.target.value)}
								placeholder="Comment"
								maxLength={256}
							/>

							<div className="popup-submit popup-submit--sb ">
								<button
									onClick={() => {
										closeModal();
										setComment('');
									}}
									type="button"
									className="button button--wider"
								>
									Cancel
								</button>
								<button
									className={`button button--wider button--full   ${
										comment.length < 3 ? 'button-block' : 'button--del'
									}`}
									type="button"
									onClick={() => {
										if (comment.length < 3) return;
										closeModal();
										handleRejectWithdrawal(comment);
									}}
								>
									Reject
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
