import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IApiUpdateIsolatedPairParams,
	IApiUpdatePairParams,
	TApiGetMarginIsolatedResponse,
	TApiGetSpotPairsResponse,
	TApiGetTradingPairsAssetsResponse,
	TFuturesActiveBaseAssetItem,
	TFuturesActiveBaseAssetPayload,
	TFuturesActiveBaseAssetResponse,
} from 'services/api/tradingPairs/types';
import { IAddAssetsPairPayload } from './types';
import {
	editTradingPairsRequest,
	editTradingPairsSuccess,
	getTradingPairsRequest,
	getTradingPairsSuccess,
	tradingPairsInitState,
	getAssetsSuccess,
	getAssetsRequest,
	addAssetsPairSuccess,
	addAssetsPairRequest,
	getMarginIsolatedRequest,
	getMarginIsolatedSuccess,
	updateIsolatedPairSuccess,
	updateIsolatedPairError,
	updateIsolatedPairRequest,
	getTradingPairsPerpRequest,
	getTradingPairsPerpSuccess,
	putTradingPairsPerpRequest,
	putTradingPairsPerpSuccess,
} from './reducer';
import { FuturesPerp } from '../../../components/TradingPairs/FuturesPerp';

function* tradingPairsRequestWorker() {
	try {
		yield put(showLoading());
		const data: TApiGetSpotPairsResponse = yield call(api.tradingPairs.getTradingPairs);
		yield put(getTradingPairsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tradingPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* editTradingPairsWorker({ payload }: PayloadAction<IApiUpdatePairParams>) {
	try {
		yield put(showLoading());
		yield call(api.tradingPairs.editTradingPairs, payload);
		yield put(editTradingPairsSuccess());
		notificationContainer('Trading Pairs has been edited!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tradingPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* getMarginIsolatedWorker() {
	try {
		const data: TApiGetMarginIsolatedResponse = yield call(api.tradingPairs.getMarginIsolated);
		yield put(getMarginIsolatedSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* updateIsolatedPairWorker({ payload }: PayloadAction<IApiUpdateIsolatedPairParams>) {
	try {
		yield call(api.tradingPairs.updateIsolatedPair, payload);
		yield put(updateIsolatedPairSuccess());

		notificationContainer('Trading pair changed', 'success');
	} catch (error) {
		yield put(updateIsolatedPairError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* assetsRequestWorker() {
	try {
		yield put(showLoading());
		const data: TApiGetTradingPairsAssetsResponse = yield call(api.tradingPairs.getAssets);
		yield put(getAssetsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(tradingPairsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* addAssetsPairWorker(action: PayloadAction<IAddAssetsPairPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.tradingPairs.addAssetsPair, payload);
		yield put(addAssetsPairSuccess());
		notificationContainer('Assets pair has been added!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error.response?.data?.msg === 'Asset pair already created') {
				notificationContainer('Asset pair already created', 'info');
			} else responseErrors(error);
		}

		yield put(tradingPairsInitState());
		yield put(getAssetsRequest());
	} finally {
		yield put(hideLoading());
	}
}

function* getFuturesPerpWorker() {
	try {
		const data: TFuturesActiveBaseAssetResponse = yield call(api.tradingPairs.getActiveBaseAsset);
		yield put(getTradingPairsPerpSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* putFuturesPerpWorker(action: PayloadAction<TFuturesActiveBaseAssetPayload>) {
	const { payload } = action;
	try {
		const data: TFuturesActiveBaseAssetItem = yield call(
			api.tradingPairs.putActiveBaseAsset,
			payload,
		);
		console.log(data);
		yield put(putTradingPairsPerpSuccess(data));
		notificationContainer('Сhanges assets future settings success!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
export function* tradingPairsSaga() {
	yield takeEvery(getTradingPairsRequest.type, tradingPairsRequestWorker);
	yield takeEvery(editTradingPairsRequest.type, editTradingPairsWorker);

	yield takeEvery(getMarginIsolatedRequest, getMarginIsolatedWorker);
	yield takeEvery(updateIsolatedPairRequest, updateIsolatedPairWorker);

	yield takeEvery(getAssetsRequest.type, assetsRequestWorker);
	yield takeEvery(addAssetsPairRequest.type, addAssetsPairWorker);

	yield takeEvery(getTradingPairsPerpRequest.type, getFuturesPerpWorker);
	yield takeEvery(putTradingPairsPerpRequest.type, putFuturesPerpWorker);
}
