import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { getAdminPermissions } from 'redux/reducers/auth/selectors';

import { getAssetsRequest } from 'redux/reducers/tradingPairs/reducer';
import { AddNewPairModal } from './AddNewPairModal';
import { FuturesPerp } from './FuturesPerp';
import { MarginIsolatedPairs } from './MarginIsolatedPairs';
import { OptionsPerp } from './OptionsPerp';
import { SpotPairs } from './SpotPairs';

const SPOT_TAB_INDEX = 0;

export const TradingPairs: FC = () => {
	const dispatch = useDispatch();

	const [activeTabIndex, setActiveTabIndex] = useState(SPOT_TAB_INDEX);
	const [openModal, setOpenModal] = useState(false);
	const permissions = useSelector(getAdminPermissions);

	const manageAssetPairs =
		permissions?.find((permission: any) => permission.name === 'manage derivatives') || null;

	useEffect(() => {
		if (manageAssetPairs) {
			dispatch(getAssetsRequest());
		}
	}, [dispatch, manageAssetPairs]);

	return (
		<div className="trading-pairs">
			<div className="title-block title-block--admin-managment">
				<p className="title">Trading Pairs</p>

				{activeTabIndex === SPOT_TAB_INDEX && (
					<div className="admin-managment-btn" onClick={() => setOpenModal(true)}>
						<span className="admin-managment-btn__text">Add New Pair</span>
						<span className="plus-icon plus-icon--action icon-plus-icon" />
					</div>
				)}
			</div>

			<Tabs className="settings-tabs" onSelect={setActiveTabIndex}>
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Spot</span>
					</Tab>

					<Tab className="user-settings__item">
						<span className="user-settings__link">Margin Isolated</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Derivatives</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Options</span>
					</Tab>
				</TabList>
				<TabPanel>
					<SpotPairs />
				</TabPanel>

				<TabPanel>
					<MarginIsolatedPairs />
				</TabPanel>

				<TabPanel>
					<FuturesPerp />
				</TabPanel>
				<TabPanel>
					<OptionsPerp />
				</TabPanel>
			</Tabs>

			<AddNewPairModal openModal={openModal} setCloseModal={() => setOpenModal(false)} />
		</div>
	);
};
