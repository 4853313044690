import { store } from 'redux/store';
import { authInitState } from 'redux/reducers/auth/reducer';
import { IAxiosError, IAxiosRequestConfig } from './types';

// ==========================================:
const onError = (error: IAxiosError) => {
	const status = error.response ? error.response.status : null;
	const errorResponse: IAxiosRequestConfig = error.config;

	if (status === 401 && !errorResponse._retry) {
		store.dispatch(authInitState());
	}

	return Promise.reject(error);
};

export default onError;
