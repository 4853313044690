/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSelector } from '@reduxjs/toolkit';
import { IOrdersStore } from './types';

const getOrdersState = (state: any): IOrdersStore => state.orders;

export const getOrdersSpotList = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersSpot,
);
export const getLoadingOrdersSpot = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersSpotLoading,
);
export const getOrdersMarginList = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersMargin,
);
export const getLoadingOrdersMargin = createSelector(
	[getOrdersState],
	(trades: IOrdersStore) => trades.ordersMarginLoading,
);

export const getFuturesAllOrders = (state: any) => {
	return getOrdersState(state).futuresAllOrders;
};

export const getFuturesAllOrdersLoading = (state: any) => {
	return getOrdersState(state).futuresAllOrdersLoading;
};

export const getOrdersOptions = (state: any) => {
	return getOrdersState(state).ordersOptions;
};

export const getOrdersOptionsLoading = (state: any) => {
	return getOrdersState(state).ordersOptionsLoading;
};
