import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';
import { IConvertsItemProps } from './types';

const ConvertsItem: FC<IConvertsItemProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [fromAssetCode] = useState(item.from_asset_code);
	const [toAssetCode] = useState(item.to_asset_code);
	const [rate] = useState(item.rate);
	const [fee] = useState(item.fee);
	const [amount] = useState(item.amount);
	const createdDate = new Date(item.created_at);

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">From</p>
				<p style={{ textTransform: 'uppercase' }}>
					{fromAssetCode === undefined ? '-' : fromAssetCode}
				</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">To</p>
				<p style={{ textTransform: 'uppercase' }}>
					{toAssetCode === undefined ? '-' : toAssetCode}
				</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Rate</p>
				<p>{noExponent(Number(rate).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Value</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(Number(fee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{createdDate.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{createdDate.toLocaleTimeString()}
				</p>
			</div>
		</div>
	);
};
export default ConvertsItem;
