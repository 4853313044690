/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	getFeesSuccess,
	feesInitState,
	getFeesRequest,
	getPlatformFeesSuccess,
	getPlatformFeesRequest,
	getCryptoFeesRequest,
	getCryptoFeesSuccess,
	changeExchangeSuccess,
	changeExchangeFeeRequest,
	changeTradeFeesAndLimitsRequest,
	changeTradeFeesAndLimitsSuccess,
	changeCryptoFeesRequest,
	changeCryptoFeesSuccess,
	changeFiatFeesRequest,
	getFiatFeesSuccess,
	getFiatFeesRequest,
	changeFiatFeesSuccess,
	getVolumeDependsFeeSettingsRequest,
	getVolumeDependsFeeSettingsSuccess,
	changeVolumeDependsFeeSettingsRequest,
	getMarginBorrowRequest,
	getMarginBorrowSuccess,
	changeMarginBorrowRequest,
} from './reducer';
import {
	IFeesResponsePayload,
	IPlatformFeesResponsePayload,
	ICryptoFeesResponsePayload,
	IChangeExchangeFeePayload,
	IChangeTradeFeesAndLimitsPayload,
	IChangeCryptoFeesPayload,
	IChangeFiatFeesPayload,
	IFiatFeesResponsePayload,
	IChangeVolumeDependsFeeSettingsPayload,
} from './types';

// =============================================================:
function* changeExchangeFeeWorker(action: PayloadAction<IChangeExchangeFeePayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.fees.changeInPlatformExchangeFee, payload);
		yield put(changeExchangeSuccess());

		notificationContainer('Your platform fees changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(feesInitState());
		}
	} finally {
		yield put(hideLoading());
		yield put(getPlatformFeesRequest());
	}
}

// =============================================================:
function* changeTradeFeesAndLimitsWorker(action: PayloadAction<IChangeTradeFeesAndLimitsPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.fees.changeFeesAndLimits, payload);
		yield put(changeTradeFeesAndLimitsSuccess());

		notificationContainer('Your trade fees and limits changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(feesInitState());
		}
	} finally {
		yield put(hideLoading());
		yield put(getFeesRequest());
	}
}

// =============================================================:
function* changeCryptoFeesWorker(action: PayloadAction<IChangeCryptoFeesPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.fees.changeCryptoFees, payload);
		yield put(changeCryptoFeesSuccess());

		notificationContainer('Your crypto fees changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(feesInitState());
		}
	} finally {
		yield put(hideLoading());
		yield put(getCryptoFeesRequest());
	}
}

// =============================================================:
function* changeFiatFeesWorker(action: PayloadAction<IChangeFiatFeesPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.fees.changeFiatFees, payload);
		yield put(changeFiatFeesSuccess());

		notificationContainer('Your fiat fees changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(feesInitState());
		}
	} finally {
		yield put(hideLoading());
		yield put(getFiatFeesRequest());
	}
}

// =============================================================:
function* feesRequestWorker() {
	try {
		yield put(showLoading());
		const response: IFeesResponsePayload = yield call(api.fees.getFeesAndLimits);
		yield put(getFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* platformFeesRequestWorker() {
	try {
		yield put(showLoading());
		const response: IPlatformFeesResponsePayload = yield call(api.fees.getPlatformFees);
		yield put(getPlatformFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* cryptoFeesRequestWorker() {
	try {
		yield put(showLoading());
		const response: ICryptoFeesResponsePayload = yield call(api.fees.getCryptoFees);
		yield put(getCryptoFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* fiatFeesRequestWorker() {
	try {
		yield put(showLoading());
		const response: IFiatFeesResponsePayload = yield call(api.fees.getFiatFees);
		yield put(getFiatFeesSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* volumeDependsFeeSettingsRequestWorker() {
	try {
		yield put(showLoading());
		const response: IPlatformFeesResponsePayload = yield call(api.fees.getVolumeDependsFeeSettings);
		yield put(getVolumeDependsFeeSettingsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* changeVolumeDependsFeeSettingsRequestWorker(
	action: PayloadAction<IChangeVolumeDependsFeeSettingsPayload>,
) {
	const { payload } = action;
	try {
		yield put(showLoading());
		yield call(api.fees.changeVolumeDependsFeeSettings, payload);

		notificationContainer('Updated settings!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(feesInitState());
		}
	} finally {
		yield put(hideLoading());
		yield put(getVolumeDependsFeeSettingsRequest());
	}
}

function* getMarginBorrowRequestWorker() {
	try {
		yield put(showLoading());
		const response: IFeesResponsePayload = yield call(api.fees.getMarginBorrowFees);
		yield put(getMarginBorrowSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}

		yield put(feesInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* changeMarginBorrowRequestWorker({ payload }: PayloadAction<any>) {
	try {
		yield put(showLoading());
		yield call(api.fees.changeMarginBorrowFees, payload);
		notificationContainer('Successfully updated!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(feesInitState());
	} finally {
		const response: IFeesResponsePayload = yield call(api.fees.getMarginBorrowFees);
		yield put(getMarginBorrowSuccess(response));
		yield put(hideLoading());
	}
}

export function* feesSaga() {
	yield takeEvery(getFeesRequest.type, feesRequestWorker);
	yield takeEvery(getPlatformFeesRequest.type, platformFeesRequestWorker);
	yield takeEvery(getCryptoFeesRequest.type, cryptoFeesRequestWorker);
	yield takeEvery(getFiatFeesRequest.type, fiatFeesRequestWorker);

	yield takeEvery(changeExchangeFeeRequest.type, changeExchangeFeeWorker);
	yield takeEvery(changeTradeFeesAndLimitsRequest.type, changeTradeFeesAndLimitsWorker);
	yield takeEvery(changeCryptoFeesRequest.type, changeCryptoFeesWorker);
	yield takeEvery(changeFiatFeesRequest.type, changeFiatFeesWorker);

	yield takeEvery(getVolumeDependsFeeSettingsRequest.type, volumeDependsFeeSettingsRequestWorker);
	yield takeEvery(
		changeVolumeDependsFeeSettingsRequest.type,
		changeVolumeDependsFeeSettingsRequestWorker,
	);

	yield takeEvery(getMarginBorrowRequest, getMarginBorrowRequestWorker);
	yield takeEvery(changeMarginBorrowRequest, changeMarginBorrowRequestWorker);
}
