/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { noExponent } from 'utils/numberFormat';
import { ITransfersItemProps } from './types';

export const TransfersItem: FC<ITransfersItemProps> = ({ data }) => {
	const createdDate = new Date(data.created_at);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data?.id}</p>
			</div>
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				<p>
					{createdDate.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{createdDate.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Coin</p>
				<p>{data?.asset?.code?.toUpperCase() ?? ''}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From wallet</p>
				<p>
					{data.from?.[0]?.toUpperCase()}
					{data.from?.slice(1)}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">To wallet</p>
				<p>
					{data.to?.[0]?.toUpperCase()}
					{data.to?.slice(1)}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(data.amount)}</p>
			</div>
		</div>
	);
};
