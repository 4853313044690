import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';
import classNames from 'classnames';
import { ICryptoDepositProps } from './types';
import { cryptoTransactionStatusClassNames, cryptoTransactionStatusNames } from '../../utils';

const CryptoDepositTableItem: FC<ICryptoDepositProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [coin] = useState(item?.asset?.code);
	const [fee] = useState(item.fee);
	const [amount] = useState(item.amount);
	const dateCreated = new Date(item.created_at * 1000);

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Asset</p>
				<p style={{ textTransform: 'uppercase' }}>{coin === undefined ? '-' : coin}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Value</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(Number(fee).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateCreated.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateCreated.toLocaleTimeString()}
				</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div>
					<span
						className={classNames(
							'status',
							cryptoTransactionStatusClassNames[item.status.type_name],
						)}
					>
						{cryptoTransactionStatusNames[item.status.type_name]}
					</span>
				</div>
			</div>
		</div>
	);
};

export default CryptoDepositTableItem;
