import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import Header from 'layouts-elements/Header';
import { IAuth } from './types';

// ==========================================:
const Auth: FC<IAuth> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className="wrapper">
				<div className="content">
					<Header />
					<main className="main">{children}</main>
				</div>
			</div>
		</>
	);
};

export default Auth;
