import { FC } from 'react';
import { toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';

const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">USER ID</p>
				<p>{item.user_id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p>{item.contract.toUpperCase()}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">ROLE/ID</p>
				<p>{item.order_id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Size</p>
				<p>{item.size}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Fill Price</p>
				<p>{toMaxDecimals(item.file_price, 8)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Fee</p>
				<p>{toMaxDecimals(item.fee, 8)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">PnL</p>
				<p>{toMaxDecimals(item.pnl, 8)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Role</p>
				<p>{item.role}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Created At</p>
				<p>{new Date(item.created_at * 1000).toLocaleString()}</p>
			</div>
		</div>
	);
};

export default TableItem;
