/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';

export const initialState: any = {
	general: null,
	generalLoading: false,
};

const currencies = createSlice({
	name: '@@currencies',
	initialState,
	reducers: {
		getGeneralRequest: (state) => {
			const generalState = state;
			generalState.generalLoading = true;
		},
		getGeneralSuccess: (state, action: any) => {
			const { payload } = action;
			const generalState = state;
			generalState.general = payload;
			generalState.generalLoading = false;
		},

		changeGeneralRequest: (state, action) => {
			const generalState = state;
			generalState.generalLoading = true;
		},

		generalInitState: () => initialState,
	},
});

export default currencies.reducer;
export const { getGeneralRequest, getGeneralSuccess, changeGeneralRequest } = currencies.actions;
