import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	IApiGetFuturesTradesParams,
	IApiGetFuturesTradesResponse,
	IApiGetOptionsTradesResponse,
} from 'services/api/trades/types';
import {
	getFuturesTradesError,
	getFuturesTradesRequest,
	getFuturesTradesSuccess,
	getOptionsTradesError,
	getOptionsTradesRequest,
	getOptionsTradesSuccess,
	getTradesMarginRequest,
	getTradesMarginSuccess,
	getTradesSpotRequest,
	getTradesSpotSuccess,
} from './reducer';
import { IMarketMakerPageRequestPayload, ITradesResponsePayload } from './types';

function* spotTradesRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITradesResponsePayload = yield call(api.trades.getTradesSpot, payload);
		yield put(getTradesSpotSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* marginTradesRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: ITradesResponsePayload = yield call(api.trades.getTradesMargin, payload);
		yield put(getTradesMarginSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getFuturesTradesWorker({ payload }: PayloadAction<IApiGetFuturesTradesParams>) {
	try {
		const data: IApiGetFuturesTradesResponse = yield call(api.trades.getFuturesTrades, payload);
		yield put(getFuturesTradesSuccess(data));
	} catch (error) {
		yield put(getFuturesTradesError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOptionsTradesWorker({ payload }: PayloadAction<IApiGetFuturesTradesParams>) {
	try {
		const data: IApiGetOptionsTradesResponse = yield call(api.trades.getOptionsTrades, payload);
		yield put(getOptionsTradesSuccess(data));
	} catch (error) {
		yield put(getOptionsTradesError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* tradesSaga() {
	yield takeEvery(getTradesSpotRequest.type, spotTradesRequestWorker);
	yield takeEvery(getTradesMarginRequest.type, marginTradesRequestWorker);
	yield takeEvery(getFuturesTradesRequest, getFuturesTradesWorker);
	yield takeEvery(getOptionsTradesRequest, getOptionsTradesWorker);
}
