import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ILimitationsData, ILimitationsStore } from './types';

const getLimitationsState = (state: IStoreState): ILimitationsStore => state.limitations;
export const getLimitations = createSelector(
	[getLimitationsState],
	(limitations: ILimitationsStore) => limitations,
);

export const getLimitationsIsLoad = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): boolean => limitations.limitationsLoader,
);

export const getLimitationsList = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore): ILimitationsData => limitations.limitations,
);

export const getSpotLimitationsList = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore) => limitations.spotLimits,
);

export const getSpotLimitsLoader = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore) => limitations.spotLimitsLoader,
);

export const getDerivativeLimitationsList = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore) => limitations.derivativeLimits,
);

export const getDerivativeLimitsLoader = createSelector(
	[getLimitations],
	(limitations: ILimitationsStore) => limitations.derivativeLimitsLoader,
);
