/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAssetsList } from 'redux/reducers/tradingPairs/selectors';
import { noExponent } from 'utils/numberFormat';
import { IConvertsItemProps } from './types';

export const ConvertsItem: FC<IConvertsItemProps> = ({ data }) => {
	const createdDate = new Date(data.created_at);
	const assets = useSelector(getAssetsList);

	const fromIsCrypto =
		data.from_asset_code === 'usdt'
			? 0
			: assets?.find((item) => item.code === data.from_asset_code)?.is_crypto;

	const toIsCrypto =
		data.to_asset_code === 'usdt'
			? 0
			: assets?.find((item) => item.code === data.to_asset_code)?.is_crypto;

	const convertToFixed = (value: number, symbols: number) => {
		const toFixed = value.toFixed(symbols).replace(/0*$/, '');
		if (toFixed.split('.')[1].length === 1) {
			return Number(toFixed).toFixed(2);
		}
		return toFixed[toFixed.length - 1] === '.' ? Number(toFixed).toFixed(2) : toFixed;
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Id</p>
				<p>{data.id}</p>
			</div>
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Date</p>
				{createdDate.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
				{createdDate.toLocaleTimeString()}
			</div>
			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(String(data.fee))}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">From</p>
				<p>{data.from_asset_code.toUpperCase()}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">To</p>
				<p>{data.to_asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Convert Rate</p>
				<p>{noExponent(data.rate)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount (From)</p>
				{fromIsCrypto ? (
					<p>{convertToFixed(data.quantity, 6)}</p>
				) : (
					<p>{convertToFixed(data.quantity, 2)}</p>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount (To)</p>
				{toIsCrypto ? (
					<p>{convertToFixed(data.amount, 6)}</p>
				) : (
					<p>{convertToFixed(data.amount, 2)}</p>
				)}
			</div>
		</div>
	);
};
