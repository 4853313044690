/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from '../../types';
import { ITradesStore } from './types';

const getTradesState = (state: IStoreState): ITradesStore => state.trades;

export const getTradesSpotList = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesSpot,
);
export const getLoadingTradesSpot = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesSpotLoading,
);
export const getTradesMarginList = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesMargin,
);
export const getLoadingTradesMargin = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.tradesMarginLoading,
);
export const getFuturesTrades = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.futuresTrades,
);
export const getFuturesTradesLoading = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.futuresTradesLoading,
);
export const getOptionsTrades = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.optionsTrades,
);
export const getOptionsTradesLoading = createSelector(
	[getTradesState],
	(trades: ITradesStore) => trades.optionsTradesLoading,
);
