import { FC } from 'react';
import Popup from 'reactjs-popup';
import noImage from 'assets/img/no-image.jpg';

import { IImageModalProps } from './types';

export const ImageModal: FC<IImageModalProps> = ({ src, openModal, closeModal }) => {
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<button type="button" className="popup__close" onClick={closeModal}>
				<svg
					width="18"
					height="18"
					viewBox="0 0 18 18"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M15 3L3 15"
						stroke="#777E91"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M3 3L15 15"
						stroke="#777E91"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			</button>

			<div className={`image-modal ${openModal ? 'open-modal' : 'close-modal'}`}>
				<img src={src === 'no selfie' ? noImage : src} alt="" />
			</div>
		</Popup>
	);
};
