import {
	I2FAResetPayload,
	I2FARejectPayload,
	I2FAResponsePayload,
	IUsersResponsePayload,
	IWhitelistIpListResponsePayload,
	IWhitelistIpRemoveResponsePayload,
	IWhitelistIpVerifyResponsePayload,
	IDepositAndWithdrawHistoryResponsePayload,
} from 'redux/reducers/userManagement/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IUsersApi } from './types';

export const users: IUsersApi = {
	getUsers: (params) =>
		http
			.get<IUsersResponsePayload>(endpoint.users.USERS, { params })
			.then((response) => response.data),

	setUserBlockUnblock: (params) => http.put(endpoint.users.USER_BLOCK_UNBLOCK, params),

	getUser: (params) => http.get(endpoint.users.USER, { params }).then(({ data }) => data),

	getBalances: ({ id }) =>
		http.get(endpoint.users.BALANCES, { params: { user_id: id } }).then(({ data }) => data),

	getOrdersHistory: ({ order_type = 'spot', status = 'all', ...restParams }) =>
		http
			.get(endpoint.users.ORDERS_HISTORY, { params: { order_type, status, ...restParams } })
			.then(({ data }) => data),

	get2FA: (params) =>
		http
			.get<I2FAResponsePayload>(endpoint.users.TWO_FA, { params })
			.then((response) => response.data),
	reset2FA: (payload) => http.put<I2FAResetPayload>(endpoint.users.TWO_FA_RESET, payload),
	reject2FA: (payload) => http.post<I2FARejectPayload>(endpoint.users.TWO_FA_REJECT, payload),
	getWhitelistIp: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.WHITELIST_IP(params.id), { params })
			.then((response) => response.data),
	removeWhitelistIp: (payload) =>
		http
			.put<IWhitelistIpRemoveResponsePayload>(endpoint.users.WHITELIST_IP_REMOVE, payload)
			.then((response) => response.data),
	verifyWhitelistIp: (payload) =>
		http
			.put<IWhitelistIpVerifyResponsePayload>(endpoint.users.WHITELIST_IP_VERIFY, {
				verification_id: payload.id,
			})
			.then((response) => response.data),

	getLoginHistory: ({ id, ...restParams }) => {
		return http
			.get(endpoint.users.LOGIN_HISTORY, { params: { user_id: id, ...restParams } })
			.then(({ data }) => data);
	},
	getDepositAndWithdrawHistory: (params) =>
		http
			.get<IDepositAndWithdrawHistoryResponsePayload>(endpoint.users.TRANSACTIONS(params.id), {
				params,
			})
			.then((response) => response.data),

	getBorrowingMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.BORROW_CROSS(params.id), { params })
			.then((response) => response.data),

	getBorrowingMarginIsolated: (params) =>
		http.get(endpoint.users.BORROW_ISOLATED, { params }).then(({ data }) => data),

	getRepaymentMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.REPAYMENT_CROSS(params.id), { params })
			.then((response) => response.data),

	getRepaymentMarginIsolated: (params) =>
		http.get(endpoint.users.REPAYMENT_ISOLATED, { params }).then(({ data }) => data),

	getInterestMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.INTEREST_CROSS(params.id), { params })
			.then((response) => response.data),

	getInterestMarginIsolated: (params) =>
		http.get(endpoint.users.INTEREST_ISOLATED, { params }).then(({ data }) => data),

	getLiquidationMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.LIQUIDATION_CROSS(params.id), { params })
			.then((response) => response.data),

	getLiquidationMarginIsolated: (params) =>
		http.get(endpoint.users.LIQUIDATION_ISOLATED, { params }).then(({ data }) => data),

	getBalancesMarginCross: ({ id, ...restParams }) =>
		http
			.get(endpoint.users.BALANCES_CROSS, { params: { user_id: id, ...restParams } })
			.then((response) => response.data),

	getBalancesMarginIsolated: ({ id, ...restParams }) =>
		http
			.get(endpoint.users.BALANCES_ISOLATED, { params: { user_id: id, ...restParams } })
			.then((response) => response.data),

	getMarginCallMarginCross: (params) =>
		http
			.get<IWhitelistIpListResponsePayload>(endpoint.users.MARGIN_CALL_CROSS(params.id), { params })
			.then((response) => response.data),

	getMarginCallMarginIsolated: (params) =>
		http.get(endpoint.users.MARGIN_CALL_ISOLATED, { params }).then(({ data }) => data),

	getCryptoDeposit: (params) =>
		http.get(endpoint.users.GET_USER_TRANSACTIONS_CRYPTO, { params }).then(({ data }) => data),

	getFiatDeposit: (params) =>
		http.get(endpoint.users.FIAT_DEPOSIT, { params }).then((response) => response.data),

	getConverts: ({ id, ...restParams }) =>
		http
			.get(endpoint.users.CONVERTS, { params: { user_id: id, ...restParams } })
			.then((response) => response.data),

	getTransfers: ({ id, ...restParams }) =>
		http
			.get(endpoint.users.TRANSFERS, { params: { user_id: id, ...restParams } })
			.then((response) => response.data),

	// Futures
	getFuturesUserOrderHistory: (params) =>
		http.get(endpoint.users.GET_FUTURES_USER_ORDER_HISTORY, { params }).then(({ data }) => data),
	getFuturesUserPositions: (params) =>
		http.get(endpoint.users.GET_FUTURES_USER_POSITIONS, { params }).then(({ data }) => data),
	getFuturesUserBalances: (params) =>
		http.get(endpoint.users.GET_FUTURES_USER_BALANCES, { params }).then(({ data }) => data),
	getFuturesUserTradeHistory: (params) =>
		http.get(endpoint.users.GET_FUTURES_USER_TRADE_HISTORY, { params }).then(({ data }) => data),
	getFuturesUserBaseAssetStatistics: () =>
		http.get(endpoint.users.GET_FUTURES_USER_BASE_ASSET_STATISTICS).then(({ data }) => data),
	getFuturesUserLiquidationHistory: (params) =>
		http
			.get(endpoint.users.GET_FUTURES_USER_LIQUIDATION_HISTORY, { params })
			.then(({ data }) => data),

	// Options
	getOptionsPositionsByUser: (params) => {
		return http
			.get(endpoint.users.GET_OPTIONS_POSITIONS_BY_USER, { params })
			.then(({ data }) => data);
	},
	getOptionsOrdersHistoryByUser: (params) => {
		return http
			.get(endpoint.users.GET_OPTIONS_ORDERS_HISTORY_BY_USER, { params })
			.then(({ data }) => data);
	},
	getOptionsTradesHistoryByUser: (params) => {
		return http
			.get(endpoint.users.GET_OPTIONS_TRADES_HISTORY_BY_USER, { params })
			.then(({ data }) => data);
	},
	getOptionsDeliveryByUser: (params) => {
		return http
			.get(endpoint.users.GET_OPTIONS_DELIVERY_BY_USER, { params })
			.then(({ data }) => data);
	},
	getOptionsLiquidationHistoryByUser: (params) => {
		return http
			.get(endpoint.users.GET_OPTIONS_LIQUIDATION_HISTORY_BY_USER, { params })
			.then(({ data }) => data);
	},
};
