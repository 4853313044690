/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeMarginBorrowRequest } from 'redux/reducers/fees/reducer';
import { numberFormat8Fraction } from 'utils/numberFormat';
import { IMarginBorrowItemProps } from './types';

const MarginBorrowItem: FC<IMarginBorrowItemProps> = ({ item }) => {
	const [interest24h, setDeposit] = useState(
		numberFormat8Fraction(item.daily_interest_percent, 'en-En'),
	);
	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();

	const handleCryptoFeesSubmit = (value: any) => {
		if (interest24h === '') return;
		dispatch(changeMarginBorrowRequest(value));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{item.asset_code.toUpperCase()}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Buyer fee, %</p>
				{isClosed ? <div /> : <p>{interest24h}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={interest24h}
						onChange={(e) => {
							setDeposit(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="submit"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setDeposit(numberFormat8Fraction(item.daily_interest_percent, 'en-En'));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleCryptoFeesSubmit({
									asset_id: item.asset_id,
									daily_interest_percent: Number(interest24h),
								});
							}}
							className={interest24h === '' ? 'button-not-active' : ''}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="submit" onClick={() => setIsClosed(!isClosed)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default MarginBorrowItem;
