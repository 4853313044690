import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Currencies from 'components/Currencies';

// ================================================:
const CurrenciesPage: FC = () => {
	return (
		<Dashboard title="Currencies">
			<Currencies />
		</Dashboard>
	);
};
export default CurrenciesPage;
