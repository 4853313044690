import { FC } from 'react';
import Loader from 'ui/Loader';
import PermissionListItem from '../PermissionListItem';
import { IPermissionListProps } from './types';

// ================================================:
const PermissionTableList: FC<IPermissionListProps> = ({ data, setFieldValue, permissions }) => {
	return (
		<div className="section-block">
			<div className="title-block">
				<p className="title">Permissions</p>
			</div>
			<div className="table-block">
				<div className="table-wrapper">
					<div className="table table--permissions">
						<div className="table-header">
							<div className="tr">
								<div className="td">
									<div className="td-name">
										<p>Action name</p>
									</div>
								</div>
								<div className="td">
									<div className="td-name">
										<p />
									</div>
								</div>
							</div>
						</div>
						<div className="table-body">
							{data?.length ? (
								data.map((el, idx) => (
									<PermissionListItem
										idx={idx}
										name={el.name}
										key={el.name}
										setFieldValue={setFieldValue}
										values={permissions[idx]}
									/>
								))
							) : (
								<div className="list-loader">
									<Loader />
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PermissionTableList;
