import { FC } from 'react';

export const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>USER ID</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Contract</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Created At</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>End Time</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Side</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Order Price</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Size</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Order Filled</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Fill Price</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
			</div>
		</div>
	);
};
