import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Field, Form, Formik } from 'formik';
import queryString from 'query-string';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissionsList } from 'redux/reducers/adminManagement/selectors';
import { editAdminRequest } from 'redux/reducers/adminManagement/reducer';
import { email, fullName, password, confirmPassword } from 'utils/formValidation';
import AdminInput from 'ui/Formik/AdminInput';
import { IAdminsListItem } from 'redux/reducers/adminManagement/types';
import {
	IEditAdminFormValue,
	IEditAdminSubmitPermissionsValue,
	IEditAdminSubmitValue,
	IEditAdminSubmitValuePassword,
	ILocationState,
} from './types';
import PermissionTableList from '../CreateAdmin/PermissionTableList';

// ==========================================:
const EditAdmin: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location: ILocationState = useLocation();
	const userData: IAdminsListItem = location?.state?.data;
	const permissionsList = useSelector(getPermissionsList);
	const [passwordConfirm, setPasswordConfirm] = useState(false);
	const { page } = queryString.parse(history.location.search);

	const initialValues: IEditAdminFormValue = {
		fullName: `${userData?.first_name ?? ''} ${userData?.last_name ?? ''}`,
		email: userData?.email,
		password: '',
		confirmPassword: '',
		permissions: permissionsList?.map((e) => {
			let newEditable = false;
			let newSelected = false;
			for (let i = 0; i < userData?.permissions?.length; i += 1) {
				if (e.id === userData?.permissions[i].id) {
					newEditable = Boolean(userData.permissions[i].pivot.editable);
					newSelected = true;
				}
			}
			return {
				id: e.id,
				editable: newEditable,
				selected: newSelected,
			};
		}) ?? [{ id: 1, editable: false, selected: false }],
	};
	const validationSchema = yup.object().shape({
		email,
		fullName,
	});
	const validationSchemaPassword = yup.object().shape({
		email,
		fullName,
		password,
		confirmPassword,
	});
	const valueChange = (values: any) => {
		if (values.password === '' && values.confirmPassword === '') {
			setPasswordConfirm(true);
		} else {
			setPasswordConfirm(false);
		}
	};

	const createAdminSubmit = (values: IEditAdminFormValue): void => {
		const nameArray = values.fullName.split(' ');
		const newPermissions = [] as IEditAdminSubmitPermissionsValue[];
		let j = 0;
		for (let i = 0; i < values.permissions.length; i += 1) {
			if (values.permissions[i].selected) {
				newPermissions[j] = {
					id: values.permissions[i].id,
					editable: values.permissions[i].editable,
				};
				j += 1;
			}
		}
		const newValue: IEditAdminSubmitValue = {
			first_name: nameArray?.[0] ?? '',
			last_name: nameArray?.[1] ?? '',
			email: values.email,
			permissions: newPermissions,
		};
		const newValuePassword: IEditAdminSubmitValuePassword = {
			first_name: nameArray?.[0] ?? '',
			last_name: nameArray?.[1] ?? '',
			email: values.email,
			password: values.password,
			permissions: newPermissions,
		};

		dispatch(
			editAdminRequest({
				userId: userData.id,
				data: passwordConfirm ? newValue : newValuePassword,
				history,
			}),
		);
	};
	const handleCancelCreate = (): void => {
		history.push({
			pathname: '/admin-management',
			search: `?page=${String(page || 1)}`,
		});
	};

	const [isShowPass, setIsShowPass] = useState(false);
	const [isShowRepeatPass, setIsShowRepeatPass] = useState(false);

	return (
		<>
			<div className="title-block">
				<p className="title">Edit Admin</p>
			</div>
			<Formik
				initialValues={initialValues}
				validationSchema={passwordConfirm ? validationSchema : validationSchemaPassword}
				onSubmit={(values: IEditAdminFormValue, { setSubmitting }) => {
					createAdminSubmit(values);
					setSubmitting(false);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, dirty, values, setFieldValue }) => {
					valueChange(values);
					return (
						<Form>
							<div className="forms-row">
								<div className="forms-block admin-management-width">
									<div className="form form--settings">
										<p className="block-title">Name</p>
										<div className="form__content">
											<Field
												title="Full name"
												type="text"
												placeholder="Full name"
												name="fullName"
												required
												component={AdminInput}
												fieldIcon={<span className="input-icon icon-user-managment-icon" />}
											/>
											<Field
												title="Email address"
												type="email"
												placeholder="Email address"
												name="email"
												required
												component={AdminInput}
												fieldIcon={<span className="input-icon icon-mail-icon" />}
											/>
										</div>
									</div>
								</div>
								<div className="forms-block admin-management-width">
									<div className="form form--settings">
										<p className="block-title">Password</p>
										<div className="form__content">
											<Field
												title="Password"
												type={isShowPass ? 'text' : 'password'}
												placeholder="Password"
												name="password"
												required
												component={AdminInput}
												fieldIcon={<span className="input-icon icon-password-icon" />}
												fieldRightIcon={
													<span
														className={`input-icon input-icon--right 
														input-icon-cursor-pointer ${isShowPass ? 'icon-eye-icon' : 'icon-eye-password-icon'}`}
														onClick={() => setIsShowPass(!isShowPass)}
													/>
												}
											/>
											<Field
												title="Repeat Password"
												type={isShowRepeatPass ? 'text' : 'password'}
												placeholder="Repeat Password"
												name="confirmPassword"
												required
												component={AdminInput}
												fieldIcon={<span className="input-icon icon-password-icon" />}
												fieldRightIcon={
													<span
														className={`input-icon input-icon--right input-icon-cursor-pointer ${
															isShowRepeatPass ? 'icon-eye-icon' : 'icon-eye-password-icon'
														}`}
														onClick={() => setIsShowRepeatPass(!isShowRepeatPass)}
													/>
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<PermissionTableList
								data={permissionsList}
								setFieldValue={setFieldValue}
								permissions={values.permissions}
							/>
							<div className="table-footer">
								<button
									className="button button--right button--transparent"
									type="button"
									onClick={handleCancelCreate}
								>
									Cancel
								</button>
								<button
									className="button button--right"
									type="submit"
									// disabled={!(isValid && dirty) || isSubmitting}
								>
									Save
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
};

export default EditAdmin;
