import { FC } from 'react';
import { IFormConrol } from './types';

// ==========================================:
const FormConrol: FC<IFormConrol> = (props) => {
	const { form, field, title, isShowPass, setIsShowPass, children, ariaLabel, fieldIcon } = props;

	const errors: string = form?.errors?.[field?.name];
	const touched: string = form?.touched?.[field?.name];

	const handlePassDisplay = (): void => {
		if (setIsShowPass) {
			setIsShowPass(!isShowPass);
		}
	};

	let inputStatus = '';

	if (errors && touched) {
		inputStatus = 'input--error';
	} else if (!errors && touched) {
		inputStatus = 'input--success';
	} else {
		inputStatus = '';
	}

	return (
		<div className={`input ${inputStatus}`}>
			<label>
				{title && <p className="input__name">{title}</p>}
				<div className={`input-wrapper ${errors && touched ? 'input--error' : ''}`}>
					{children}
					<span className="input-icon input-icon--more-right input-icon--success icon-checkbox" />
					<span className="input-icon input-icon--more-right input-icon--error icon-close" />
					{setIsShowPass && (
						<button
							type="button"
							onClick={handlePassDisplay}
							aria-label={ariaLabel}
							className="input-icon input-icon--right"
						>
							<span className="password-type icon-eye-password-icon" />
							<span className="text-type icon-eye-icon" />
						</button>
					)}
					<span className={`input-icon ${fieldIcon && fieldIcon !== undefined ? fieldIcon : ''}`} />
				</div>
				{errors && touched && (
					<div className="input-error-note">
						<p className="word-wrap">{errors && touched && errors}</p>
					</div>
				)}
				{field?.name === 'totp' && (
					<p className="input__descr">Enter the verification code from the authentication app</p>
				)}
			</label>
		</div>
	);
};

export default FormConrol;
