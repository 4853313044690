import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getHotColdTransactionsIsLoad,
	getHotColdTransactionsList,
} from 'redux/reducers/walletManagement/selectors';
import Loader from 'ui/Loader';
import { TransactionsItem } from './TransactionsItem';

export const Transactions: FC = () => {
	const hotColdTransactionsLoader = useSelector(getHotColdTransactionsIsLoad);
	const hotColdTransactions = useSelector(getHotColdTransactionsList);

	return (
		<div className="content-block content-block--walletman">
			<div>
				<div className="table-block table-block--walletman">
					<div className="table-wrapper">
						<div className="table table--wallett-transact">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>ID</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Date</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Coin</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Amount</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Type</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Address to</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Hash</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name">
											<p>Address from</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{!hotColdTransactions?.data?.length && !hotColdTransactionsLoader && (
									<div className="user-management-empty-data">
										<p className="user-management-empty__text">No transactions found...</p>
									</div>
								)}

								{hotColdTransactionsLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<div className="table-body">
										{hotColdTransactions?.data?.map((item) => (
											<TransactionsItem key={item.id} data={item} />
										))}
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
