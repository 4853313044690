/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from 'ui/Pagination';
import { get2FAList } from 'redux/reducers/userManagement/selectors';
import { get2FARequest } from 'redux/reducers/userManagement/reducer';
import { I2FAListRequestPayload } from 'redux/reducers/userManagement/types';
import SearchBar from '../SearchBar';
import TwoFAtList from './TwoFAtList';

// ==========================================:
const TwoFA: FC = () => {
	const twoFAResetList = useSelector(get2FAList);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();

	const searchFieldArray = ['user_id', 'email'];
	const searchFieldTextArray = ['User ID', 'E-mail'];

	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);

	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = typeof text === 'string' ? text : String(text);
		}

		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${pageNumber}`;

		const params: I2FAListRequestPayload =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
				  }
				: {
						current_page: pageNumber || 1,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(get2FARequest(params));
	};

	const handleSearch = (txt: string) => {
		const params = {
			search_value: txt,
			search_field: searchField,
		};

		if (txt.length >= 1) {
			dispatch(get2FARequest(params));
		} else {
			dispatch(get2FARequest({ current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?page=${String(page || 1)}&text=${txt}&field=${searchField}` : ``,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
	};

	return (
		<>
			<div className="title-block">
				<p className="title">2FA reset request</p>
			</div>
			<div className="count-label count-label--user-management count-label--left">
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={searchFieldArray}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={searchFieldTextArray}
				/>
			</div>

			<TwoFAtList twoFAResetList={twoFAResetList?.data} handleClearSearch={handleClearSearch} />

			{(twoFAResetList?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={twoFAResetList?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default TwoFA;
