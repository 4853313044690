import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { APP_NAME } from 'services/constants/env';
import { getMobileMenuStatus } from 'redux/reducers/app/selectors';
import Header from 'layouts-elements/Header';
import Sidebar from 'layouts-elements/Sidebar';
import { IDashboard } from './types';

// ==========================================:
const Dashboard: FC<IDashboard> = ({ children, title }) => {
	const pageTitle = title ? `| ${title}` : '';

	const mobileMenuStatus = useSelector(getMobileMenuStatus);

	return (
		<>
			<Helmet>
				<title>{`${String(APP_NAME)} ${pageTitle}`}</title>
			</Helmet>
			<div className="wrapper">
				<div className="content">
					<Header />
					<main className="main">
						<section className={`main-section ${mobileMenuStatus ? 'minimized' : ''}`}>
							<Sidebar />
							<div className="main-content">{children}</div>
						</section>
					</main>
				</div>
			</div>
		</>
	);
};

export default Dashboard;
