import { FC } from 'react';
import { MarkPriceItem } from 'redux/reducers/userManagement/types';
import { noExponent } from 'utils/numberFormat';
import { ITableItemProps } from './types';

const TableItem: FC<ITableItemProps> = ({ item, markPrice }) => {
	const marketPrice = () => {
		return markPrice?.map((e: MarkPriceItem) => {
			if (e.base_asset === item.base_asset) {
				return e.mark_price;
			}
			return null;
		});
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.base_asset}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Size</p>
				<p>{item.id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Entry Price</p>
				<p>{noExponent(item.entry_price)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Mark Price</p>
				<p>{marketPrice()}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Liquidation price </p>
				<p>{noExponent(item.liquidation_price)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Margin</p>
				<p>{noExponent(item.amount_leverage_deposit_in_usdt)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Unreatised PNL[RDE]</p>
				<p>{item.pnl_realized ? noExponent(item.pnl_realized) : '-'}</p>
			</div>
		</div>
	);
};

export default TableItem;
