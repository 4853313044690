import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IAppStore } from 'redux/reducers/app/types';

const getAppState = (state: IStoreState): IAppStore => state.app;
export const getApp = createSelector([getAppState], (app: IAppStore) => app);

// ====================================================:
export const getMobileMenuStatus = createSelector(
	[getApp],
	(app: IAppStore): boolean => app.mobileMenu,
);

// ====================================================:
export const appLanguage = createSelector([getApp], (app): string => app.lang);
