import CryptoJS from 'crypto-js';

export const deCoderLS = (value: string) => {
	const bytes = CryptoJS.AES.decrypt(value, 'my-super-secret-key');
	const decrypted = bytes.toString(CryptoJS.enc.Utf8);

	return JSON.parse(decrypted);
};

export const getUserDataFromLS = (key = 'persist:app-in-design') => {
	try {
		const lsValue = localStorage.getItem(key);
		if (lsValue) {
			// TODO : need to be check this
			/* eslint-disable @typescript-eslint/no-unsafe-call */
			return deCoderLS(JSON.parse(lsValue)?.auth?.slice(1, -1));
		}
		throw new Error(`I do not find ${key}`);
	} catch (error) {
		return null;
	}
};
