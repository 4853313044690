/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getOneUser, getSelectUserIsLoad } from 'redux/reducers/userManagement/selectors';
import Loader from 'ui/Loader';

export const Account: FC = () => {
	const user = useSelector(getOneUser);
	const selectUserLoader = useSelector(getSelectUserIsLoad);

	const date = user?.birth_date ? new Date(user.birth_date).toLocaleDateString('en-GB') : '';

	return (
		<>
			{/* {!userManagement && !selectUserLoader && (
				<div className="user-management-empty-data">
					<p className="user-management-empty__text">No user found...</p>
				</div>
			)} */}

			{selectUserLoader ? (
				<div className="list-loader">
					<Loader />
				</div>
			) : (
				<div className="content-block content-block--user-management user-management--account">
					<div className="content-block__inside">
						<div className="user-header">User data</div>
					</div>
					<div className="content-block__inside content-block__inside--bt">
						<div className="table-block table-transaction">
							<div className="table-wrapper">
								<div className="table table--transaction">
									<div className="table-body">
										<div className="tr">
											<div className="td param">
												<p>First Name:</p>
											</div>
											<div className="td ">
												<p className="td-hidden-name">First Name</p>
												<p>{user?.first_name}</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p>Last Name:</p>
											</div>
											<div className="td ">
												<p className="td-hidden-name">Last Name</p>
												<p>{user?.last_name}</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p>Address:</p>
											</div>
											<div className="td ">
												<p className="td-hidden-name">Address</p>
												<p>{user?.address}</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p>Phone Number:</p>
											</div>
											<div className="td ">
												<p className="td-hidden-name">Phone Number</p>
												<p>{user?.phone_number}</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p>Date of Birth:</p>
											</div>
											<div className="td">
												<p className="td-hidden-name">Date of Birth</p>
												<p>{date}</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p>E-mail:</p>
											</div>
											<div className="td ">
												<p className="td-hidden-name">E-mail</p>
												<p>{user?.email}</p>
											</div>
										</div>
										<div className="tr">
											<div className="td param">
												<p>KYC:</p>
											</div>
											<div className={`td ${user?.status === 'unverified' ? 'rad-status' : ''}`}>
												<p className="td-hidden-name">KYC</p>
												<p>{user?.status?.toUpperCase()}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};
