/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';

export const CryptoWithdrawalTableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Asset</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Value</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Fee</p>
					</div>
				</div>
				<div className="td td--left">
					<div className="td-name">
						<p>TX link</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Created</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
			</div>
		</div>
	);
};
