import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { SelectUser } from 'components/UserManagement/SelectUser';
import SocketWithPrivateTopicConnectWrapper from 'components/SocketWithPrivateTopicConnectWrapper';

// ================================================:
const SelectUserPage: FC = () => {
	return (
		<Dashboard title="User management">
			<SocketWithPrivateTopicConnectWrapper topicName="all_options_statistic:option">
				<SelectUser />
			</SocketWithPrivateTopicConnectWrapper>
		</Dashboard>
	);
};

export default SelectUserPage;
