import * as yup from 'yup';

// ==========================================:
export const email = yup
	.string()
	.trim()
	.required('Please, enter your email!')
	.email('Invalid email format');

// ==========================================:
export const password = yup
	.string()
	.required('Please, enter your password!')
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	);

// ==========================================:
export const confirmPassword = yup
	.string()
	.required('Please, enter your confirm password!')
	.matches(
		/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_#?!@$%^&*-]).{8,}$/,
		'Password must be at least 8 characters long and include 1 uppercase letter, 1 lowercase letter, 1 number, 1 special symbol',
	)
	.oneOf([yup.ref('password'), null], 'Passwords must match');

// ==========================================:
export const acceptTerms = yup
	.bool()
	.oneOf([true], 'You must agree to the terms & conditions and privacy policy!');

// ==========================================:
export const temporaryCode = yup.string().required('Please, enter your temporary code!');

// ==========================================:
export const cookiePolicy = yup.bool().oneOf([true], 'You must agree to  cookie policy!');

// ==========================================:
export const recaptcha = yup.string().required('Please verify that you are a human!');

// ==========================================:
export const swap = yup.string().required('Insufficient funds on the wallet');

// ==========================================:
export const fullName = yup
	.string()
	.trim()
	.matches(
		/^[a-zA-Z]+( [a-zA-Z]+)+$/,
		'Full name must be at least two words, do not contain numbers and special symbol!',
	)
	.max(60, 'Full name max length must be not more 60 characters!')
	.required('Please, enter your full name!');
