/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { getOrdersSpotList, getLoadingOrdersSpot } from 'redux/reducers/orders/selectors';
import { getOrdersSpotRequest } from 'redux/reducers/orders/reducer';
import { SpotOrdersTableHeader } from './SpotOrdersTableHeader';
import { ISpotOrdersItem, IMarginProps } from './types';
import SpotOrdersTableItem from './SpotOrdersTableItem';

export const SpotOrders: FC<IMarginProps> = ({ radioCheck }) => {
	const ordersSpotList = useSelector(getOrdersSpotList);
	const ordersSpotIsLoad = useSelector(getLoadingOrdersSpot);
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
			order_type: 'spot',
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getOrdersSpotRequest(params));
	};

	useEffect(() => {
		const params = {
			per_page: 15,
			current_page: 1,
			order_type: 'spot',
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getOrdersSpotRequest(params));
	}, [radioCheck]);

	return (
		<>
			{ordersSpotIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--spot-orders">
							<SpotOrdersTableHeader />
							<div className="table-body">
								{ordersSpotList?.data.map((item: ISpotOrdersItem) => (
									<SpotOrdersTableItem item={item} key={item.id} />
								))}
								{ordersSpotList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Borrowing is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(ordersSpotList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={ordersSpotList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
