import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	getChatRequest,
	getChatSuccess,
	postChatMessageSendRequest,
	postChatMessageSendSuccess,
	postChatMessageAdminSendRequest,
	postChatMessageAdminSendSuccess,
	postMessageSellerRequest,
	postMessageBuyerRequest,
	postChatMessageAddBuyerSendSuccess,
	postChatMessageAddSellerSendSuccess,
} from './reducer';
import {
	IP2PTradeChatResponse,
	IP2PTradeChatRequest,
	IP2PTradeChatMessgeRequest,
	IP2PTradeChatMessgeResponse,
} from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
// =============================================================:
// =============================================================:

function* getChatWorker(action: PayloadAction<IP2PTradeChatRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatResponse = yield call(api.chat.getP2PChats, payload);
		yield put(getChatSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getChatMessageWorker(action: PayloadAction<IP2PTradeChatMessgeRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatMessgeResponse = yield call(
			api.chat.postP2PChatsUserSend,
			payload,
		);

		yield put(postChatMessageSendSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:

function* getChatMessageAdminWorker(action: PayloadAction<IP2PTradeChatMessgeRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatMessgeResponse = yield call(
			api.chat.postP2PChatsAdminSend,
			payload,
		);

		yield put(postChatMessageAdminSendSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* getChatMessageSellerWorker(action: PayloadAction<IP2PTradeChatMessgeRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatMessgeResponse = yield call(
			api.chat.postChatMessagesSeller,
			payload,
		);
		yield put(postChatMessageAddSellerSendSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getChatMessageBuyerWorker(action: PayloadAction<IP2PTradeChatMessgeRequest>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PTradeChatMessgeResponse = yield call(
			api.chat.postChatMessagesBuyer,
			payload,
		);
		yield put(postChatMessageAddBuyerSendSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* chatSaga() {
	yield takeEvery(getChatRequest.type, getChatWorker);
	yield takeEvery(postChatMessageSendRequest.type, getChatMessageWorker);
	yield takeEvery(postChatMessageAdminSendRequest.type, getChatMessageAdminWorker);
	yield takeEvery(postMessageSellerRequest.type, getChatMessageSellerWorker);
	yield takeEvery(postMessageBuyerRequest.type, getChatMessageBuyerWorker);
}
