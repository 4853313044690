/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersList } from 'redux/reducers/userManagement/selectors';
import { getUsersRequest } from 'redux/reducers/userManagement/reducer';
import { IUsersListRequestPayload } from 'redux/reducers/userManagement/types';
import {
	USER_SEARCH_ARRAY,
	USER_SEARCH_TEXT_ARRAY,
	USER_STATUS_ARRAY,
	USER_STATUS_TEXT_ARRAY,
} from 'redux/reducers/userManagement/constants';
import UserManagementList from './UserManagementList';
import SearchBar from '../SearchBar';

const UserManagement: FC = () => {
	const usersList = useSelector(getUsersList);
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const searchFieldArray = ['user_id', 'email'];

	const { search } = useLocation();
	const { page, text, field } = queryString.parse(search);
	const [searchField, setSearchField] = useState(field ? String(field) : searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: `?page=${String(pageNumber)}`;

		const params: IUsersListRequestPayload =
			verifyText.length > 0
				? {
						current_page: pageNumber || 1,
						search_field: searchField,
						search_value: verifyText,
				  }
				: {
						current_page: pageNumber || 1,
				  };
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getUsersRequest(params));
	};

	const handleSearch = (txt: string) => {
		const params: IUsersListRequestPayload = {
			current_page: 1,
			search_value: txt,
			search_field: searchField,
		};

		if (txt.length >= 1) {
			dispatch(getUsersRequest(params));
		} else {
			dispatch(getUsersRequest({ current_page: 1 }));
		}
		history.push({
			pathname: location.pathname,
			search:
				txt.length >= 1
					? `?page=${String(page || 1)}&text=${String(txt)}&field=${searchField}`
					: `?page=${String(1)}`,
		});
		setCurrentPage(txt.length >= 1 ? Number(page || 1) : 1);
	};
	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 800);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const searchFieldHandler = (txt: string) => {
		setSearchField(txt);
	};

	return (
		<>
			<div className="title-block">
				<p className="title">User Management</p>
			</div>
			<div className="count-label--user-management">
				<p>All users ({usersList?.total ?? 0})</p>
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={USER_SEARCH_ARRAY}
					searchField={searchField}
					setSearchField={searchFieldHandler}
					handleClearSearch={handleClearSearch}
					searchFieldTextArray={USER_SEARCH_TEXT_ARRAY}
					typeSearch={searchField === 'status' ? 'dropdown' : undefined}
					dropdownArray={USER_STATUS_ARRAY}
					dropdownTextArray={USER_STATUS_TEXT_ARRAY}
				/>
			</div>

			<UserManagementList users={usersList?.data} handleClearSearch={handleClearSearch} />

			{(usersList?.total ?? 0) > 1 && (
				<Pagination
					pageCount={usersList?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default UserManagement;
