/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PayloadAction } from '@reduxjs/toolkit';
import { take, fork, call, put, race } from 'redux-saga/effects';
import { eventChannel, EventChannel } from 'redux-saga';
import { postChatMessageSendUpdate } from 'redux/reducers/chat/reducer';
import { WEB_SOCKETS_URL } from 'services/constants/env';
import {
	setSocketsConnect,
	setSocketsDisconnect,
	socketClosedConnection,
	socketOpenConnection,
	socketSendMessage,
} from './reducer';
import { SocketsResponseData } from './types';
import { setOptionsStatistics } from '../options/reducer';

export const socketConnection = (socketToken: string | null) => {
	return new Promise((resolve, reject) => {
		let socket: WebSocket;

		if (socketToken) {
			socket = new WebSocket(`${String(WEB_SOCKETS_URL)}/?${socketToken}`, ['wamp']);
		} else {
			socket = new WebSocket(`${String(WEB_SOCKETS_URL)}`, ['wamp']);
		}

		socket.onopen = () => {
			resolve(socket);
			// console.log('Connection open...');
		};
		socket.onerror = (event) => {
			reject(event);
		};
		socket.onclose = (event) => {
			if (event.wasClean) {
				// console.log('Connection closed...');
			} else {
				// console.log('Lost connection...');
			}
		};
	});
};

export const socketChannel = (socketValue: WebSocket) => {
	const socket = socketValue;
	return eventChannel((emiter) => {
		socket.onmessage = ({ data }) => {
			emiter(JSON.parse(data));
		};
		return () => {
			socket.close();
		};
	});
};

function* socketSend(socket: WebSocket) {
	const isOpenSocket = socket.readyState === socket.OPEN;
	if (isOpenSocket) {
		while (true) {
			const { payload }: { payload: PayloadAction } = yield take(socketSendMessage.type);
			socket.send(JSON.stringify(payload));
		}
	}
}

function* socketClose(socket: WebSocket) {
	while (true) {
		yield take(socketClosedConnection.type);
		yield put(setSocketsDisconnect());

		socket.close();
	}
}

function* socketOnmessage(channel: EventChannel<SocketsResponseData>) {
	while (true) {
		const [type, subscription, payload]: SocketsResponseData = yield take(channel);

		if (type === 8) {
			switch (subscription) {
				case 'all_options_statistic:option':
					yield put(setOptionsStatistics(payload.data.statistics));
					break;

				// case 'chat':
				// 	const chatP2P = data[2].data;
				// 	yield put(postChatMessageSendUpdate(chatP2P));
				// 	// yield put(
				// 	// 	getChatRequest({ trade_id: window.location.pathname.split('/dispute-info/')[1] }),
				// 	// );
				// 	break;

				default:
					break;
			}
		}
	}
}

export function* socketsSaga() {
	try {
		while (true) {
			const { payload }: { payload: PayloadAction } = yield take(socketOpenConnection.type);

			const socketToken: any = payload; // TODO: add types

			const socket: WebSocket = yield call(socketConnection, socketToken);
			const channel: EventChannel<any> = yield call(socketChannel, socket);

			if (socket.onopen) {
				yield put(setSocketsConnect());
			}

			yield fork(socketSend, socket);
			yield fork(socketClose, socket);

			const { cancel } = yield race({
				task: call(socketOnmessage, channel),
				cancel: take(socketClosedConnection.type),
			});

			if (cancel) {
				channel.close();
			}
		}
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('websockets_error', error);
		// eslint-disable-next-line no-console
		console.dir('websockets_error', error);
	}
}
