/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IHotColdTransactionsRequestPayload,
	IHotColdTransactionsResponsePayload,
	IHotWalletAddressRequestPayload,
	IHotWalletAddressResponsePayload,
	IWalletsRequestPayload,
	IWalletsResponsePayload,
	IWithdrawToColdWalletRequestPayload,
	IWithdrawToColdWalletResponsePayload,
} from './types';
import {
	geHotColdTransactionsRequest,
	geHotWalletAddressRequest,
	getHotColdTransactionsSuccess,
	getHotWalletAddressSuccess,
	getWalletsRequest,
	getWalletsSuccess,
	walletManagementInitState,
	withdrawToColdWalletRequest,
	withdrawToColdWalletSuccess,
} from './reducer';

// ===================================================:
function* walletsRequestWorker(action: PayloadAction<IWalletsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWalletsResponsePayload = yield call(api.walletManagement.getWallets, payload);
		yield put(getWalletsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* hotWalletAddressRequestWorker(action: PayloadAction<IHotWalletAddressRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IHotWalletAddressResponsePayload = yield call(
			api.walletManagement.getHotWalletAddress,
			payload,
		);
		yield put(getHotWalletAddressSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* withdrawToColdWalletRequestWorker(
	action: PayloadAction<IWithdrawToColdWalletRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWithdrawToColdWalletResponsePayload = yield call(
			api.walletManagement.withdrawToColdWallet,
			payload,
		);
		yield put(withdrawToColdWalletSuccess(response));
		notificationContainer('Withdrawal to cold wallet will be processed', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
		geHotColdTransactionsRequest({});
	}
}

// ===================================================:
function* geHotColdTransactionsWorker(action: PayloadAction<IHotColdTransactionsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IHotColdTransactionsResponsePayload = yield call(
			api.walletManagement.getHotColdTransactions,
			payload,
		);
		yield put(getHotColdTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(walletManagementInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:

export function* walletManagementSaga() {
	yield takeEvery(getWalletsRequest.type, walletsRequestWorker);
	yield takeEvery(geHotWalletAddressRequest.type, hotWalletAddressRequestWorker);
	yield takeEvery(withdrawToColdWalletRequest.type, withdrawToColdWalletRequestWorker);
	yield takeEvery(geHotColdTransactionsRequest.type, geHotColdTransactionsWorker);
}
