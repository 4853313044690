/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMarginBorrow, getMarginBorrowLoading } from 'redux/reducers/fees/selectors';
import { getMarginBorrowRequest } from 'redux/reducers/fees/reducer';
import Loader from 'ui/Loader';
import MarginBorrowItem from './MarginBorrow';

const CryptoFees: FC = () => {
	const marginBorrowList = useSelector(getMarginBorrow);
	const marginBorrowLoading = useSelector(getMarginBorrowLoading);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getMarginBorrowRequest());
	}, [dispatch]);
	return (
		<div className="content-block content-block--user-management">
			<div className="content-block__inside content-block__inside--bt content-block__inside--login-his">
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--fee--margin-borrow">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Borrow interest(24h), %</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Edit</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{marginBorrowLoading ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<>
										{marginBorrowList?.map((item: any) => (
											<MarginBorrowItem item={item} key={item.asset_id} />
										))}
										<div />
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CryptoFees;
