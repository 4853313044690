/* eslint-disable no-nested-ternary */

import { FC } from 'react';
import {
	TRANSACTION_TYPE_DEPOSIT,
	TRANSACTION_TYPE_DEPOSIT_TEXT,
	TRANSACTION_TYPE_WITHDRAWAL,
	TRANSACTION_TYPE_WITHDRAWAL_TEXT,
} from 'redux/reducers/walletManagement/constants';
import { ITransactionsItemParams } from './types';

export const TransactionsItem: FC<ITransactionsItemParams> = ({ data }) => {
	const dateCreated = new Date(data.created_at).toLocaleString('en-GB', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: 'numeric',
		minute: '2-digit',
	});

	const type =
		data.type === TRANSACTION_TYPE_WITHDRAWAL
			? TRANSACTION_TYPE_WITHDRAWAL_TEXT
			: data.type === TRANSACTION_TYPE_DEPOSIT
			? TRANSACTION_TYPE_DEPOSIT_TEXT
			: '';

	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">ID</p>
					<p>{data.id}</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Date</p>
					<p style={{ whiteSpace: 'nowrap' }}>{dateCreated}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Coin</p>
					<p>
						{data.code.toUpperCase()}{' '}
						{data.network && data.network !== data.code && <>({data.network.toUpperCase()})</>}
					</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Amount</p>
					<p>{data.amount}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Type</p>
					<p>{type}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Address to</p>
					<p className="transaction-address">{data.address_to}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Hash</p>
					<p className="transaction-hash">{data.transaction_hash}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">Address from</p>
					<p className="transaction-address">{data.address_from}</p>
				</div>
			</div>
		</div>
	);
};
