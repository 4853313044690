/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useState, MouseEvent } from 'react';
import { numberFormat } from 'utils/numberFormat';
import Chart from '../Chart';
import { IBalanceHotWalletProps } from './types';

// ==========================================:
const BalanceHotWallet: FC<IBalanceHotWalletProps> = ({ data }) => {
	const [balanceTab, setBalanceTab] = useState<string>(data?.[0]?.asset_code);

	let totalBalance = 0;
	data.forEach((item) => {
		totalBalance += item.balances.reduce((sum, elem) => {
			return sum + Number(elem.balance_usdt);
		}, 0);
	});

	const handleBalanceTab = (e: MouseEvent<HTMLButtonElement>): void => {
		setBalanceTab(e.currentTarget.name);
	};

	return (
		<div className="content-block admin-panel-custom">
			<div className="content-block__inside">
				<div className="stats">
					<div className="stats__info">
						<div className="stats__header">
							<span className="stats__title">Hot Balance</span>
							<span className="stats__desc">
								Total balance: $ {numberFormat(totalBalance, 'en-EN')}
							</span>
						</div>
						<div className="stats__list">
							{data?.length
								? data.map((el) => (
										<button
											key={el.asset_code}
											name={el.asset_code}
											type="button"
											className={`stats-item ${
												balanceTab === el.asset_code ? 'stats-item--active' : ''
											}`}
											onClick={handleBalanceTab}
										>
											<div className="stats-item__desc" style={{ width: '100%' }}>
												{el.balances?.length && el.asset_code !== el.balances[0].network_name ? (
													<>
														<span
															className="stats-item__elem"
															style={{
																textAlign: 'end',
																display: 'flex',
																justifyContent: 'space-between',
															}}
														>
															<div className="stats-item__name">
																<span className="stats-item__img">
																	<img src={el.asset_logo_url} alt="bitcoin token" />
																</span>
																<span className="stats-item__title">
																	{el.asset_code.toUpperCase()}
																</span>
															</div>
															<span>
																{`${numberFormat(
																	el.balances.reduce((sum, elem) => {
																		return sum + Number(elem.balance);
																	}, 0),
																	'en-EN',
																)} / $
													${numberFormat(
														el.balances.reduce((sum, elem) => {
															return sum + Number(elem.balance_usdt);
														}, 0),
														'en-EN',
													)}`}
															</span>
														</span>
														<hr className="stats-item__elem" />
														{el.balances.map((item) => (
															<span
																className="stats-item__elem"
																style={{
																	display: 'flex',
																	width: '100%',
																	justifyContent: 'space-between',
																	textAlign: 'end',
																}}
																key={item.network_name}
															>
																<span style={{ marginLeft: '24px' }}>
																	{item.network_name.toUpperCase()}
																</span>
																<span className="stats-item__elem_n" key={item.network_name}>
																	{`${numberFormat(Number(item.balance), 'en-EN')} / $
													${numberFormat(item.balance_usdt, 'en-EN')}`}
																</span>
															</span>
														))}
													</>
												) : el.balances?.length ? (
													el.balances.map((item: any) => (
														<span
															className="stats-item__elem"
															key={item.network_name}
															style={{
																textAlign: 'end',
																display: 'flex',
																justifyContent: 'space-between',
															}}
														>
															<div className="stats-item__name">
																<span className="stats-item__img">
																	<img src={el.asset_logo_url} alt="bitcoin token" />
																</span>
																<span className="stats-item__title">
																	{el.asset_code.toUpperCase()}
																</span>
															</div>
															<span>
																{`${numberFormat(item.balance, 'en-EN')} / $
												${numberFormat(item.balance_usdt, 'en-EN')}`}
															</span>
														</span>
													))
												) : null}
											</div>
											{el.balances?.length > 1 && (
												<div className="stats-item__arrow">
													<span className="icon-pagination-left-icon" />
												</div>
											)}
										</button>
								  ))
								: null}
						</div>
					</div>
					<Chart data={data} currentBalanceItemName={balanceTab} />
				</div>
			</div>
		</div>
	);
};

export default BalanceHotWallet;
