import { FC, useState } from 'react';
import { IMarginCallProps } from './types';

const MarginAllTableItem: FC<IMarginCallProps> = ({ item }) => {
	const [created] = useState(item.created_at);
	const [pair] = useState(item?.pair?.code);
	const [type] = useState(item.type);
	const [status] = useState(item.status);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);
	return (
		<div className="tr">
			{pair ? (
				<div className="td td--left">
					<p className="td-hidden-name">Date</p>
					<p>
						{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
						{dateOffset.toLocaleTimeString()}
					</p>
				</div>
			) : (
				<div className="td td--left">
					<p className="td-hidden-name">Date</p>
					<p>
						{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
						{dateOffset.toLocaleTimeString()}
					</p>
				</div>
			)}
			{pair ? (
				<div className="td td--right">
					<p className="td-hidden-name">Pair</p>
					<p style={{ textTransform: 'uppercase' }}>{pair.replace('_', '/')}</p>
				</div>
			) : null}
			<div className="td td--right">
				<p className="td-hidden-name">Type</p>
				<p style={{ textTransform: 'capitalize' }}>{type}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Send Status</p>
				<p style={{ textTransform: 'capitalize' }}>{status}</p>
			</div>
		</div>
	);
};
export default MarginAllTableItem;
