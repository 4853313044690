export const endpoint = {
	auth: {
		LOGIN: '/v1/admin/login', // new
		LOGOUT: '/v1/admin/logout', // new
	},
	settings: {
		CHANGE_PASSWORD: '/v1/admin/password/change', // new
		ENABLE_2FA: '/admin/2fa/enable',
		DISABLE_2FA: '/admin/2fa/disable',
		GENERATE_2FA_KEY: '/admin/2fa/generate_secret_key',
		CHECK_ENABLED_2FA: '/admin/2fa/check_enabled',
	},
	trades: {
		tradesSpot: '/v1/admin/trades', // new
		getFuturesTrades: '/v1/admin/futures/all_trade_history', // new
		getOptionsTrades: '/v1/admin/options/all-trade-history', // new
	},
	orders: {
		ordersSpot: '/v1/admin/orders', // new
		getFuturesAllOrders: '/v1/admin/futures/all_orders', // new
	},
	dispute: {
		disputeP2P: '/admin/p2p/disputes/trades',
		getDetails: (id: string) => `/admin/p2p/disputes/trade/${id}`,
		accept: (id: string) => `/admin/p2p/disputes/trade/${id}/approve`,
		reject: (id: string) => `/admin/p2p/disputes/trade/${id}/reject`,
	},
	currencies: {
		general: '/v1/admin/assets', // new
		generalChange: '/v1/admin/asset', // new
	},
	users: {
		USERS: '/v1/admin/user_data', // new
		USER: '/v1/admin/user_settings', // new
		BALANCES: '/v1/wallet/admin/spot/user', // new
		ORDERS_HISTORY: '/v1/admin/orders/user_orders', // new
		USER_BLOCK_UNBLOCK: '/v1/admin/user/blocked', // new
		TWO_FA: '/v1/admin/2fa/reset',
		TWO_FA_RESET: '/v1/admin/2fa/reset',
		TWO_FA_REJECT: '/v1/admin/2fa/reject',
		WHITELIST_IP: (id: string) => `/admin/user/${id}/whitelist_ip`,
		WHITELIST_IP_REMOVE: '/admin/whitelist_ip/remove',
		WHITELIST_IP_VERIFY: '/admin/whitelist_ip/verify',
		LOGIN_HISTORY: '/v1/admin/user/login_history', // new
		TRANSACTIONS: (id: string) => `/admin/user/${id}/transactions`,
		BORROW_CROSS: (id: string) => `/admin/user/${id}/margin/borrow`,
		BORROW_ISOLATED: '/v1/admin/user/margin/borrow', // new
		REPAYMENT_CROSS: (id: string) => `/admin/user/${id}/margin/repay`,
		REPAYMENT_ISOLATED: '/v1/admin/user/margin/repay', // new
		INTEREST_CROSS: (id: string) => `/admin/user/${id}/margin/interest`,
		INTEREST_ISOLATED: '/v1/admin/user/margin/interest', // new
		LIQUIDATION_CROSS: (id: string) => `/admin/user/${id}/margin/liquidation`,
		LIQUIDATION_ISOLATED: '/v1/admin/user/margin/liquidation', // new
		BALANCES_CROSS: '/v1/wallet/admin/margin/cross/user', // new
		BALANCES_ISOLATED: '/v1/wallet/admin/margin/isolated/user', // new
		MARGIN_CALL_CROSS: (id: string) => `/admin/user/${id}/margin/margin_call`,
		MARGIN_CALL_ISOLATED: '/v1/admin/user/margin/margin_call', // new
		GET_USER_TRANSACTIONS_CRYPTO: '/v1/admin/user/transactions', // new
		FIAT_DEPOSIT: '/v1/admin/user/fiat', // new
		CONVERTS: '/v1/admin/user/exchanges', // new
		TRANSFERS: '/v1/admin/user/transfers', // new
		// Futures
		GET_FUTURES_USER_ORDER_HISTORY: '/v1/admin/futures/user_order_history',
		GET_FUTURES_USER_POSITIONS: '/v1/admin/futures/user_open_positions',
		GET_FUTURES_USER_BALANCES: '/v1/admin/futures/user_balance',
		GET_FUTURES_USER_TRADE_HISTORY: '/v1/admin/futures/user_trade_history',
		GET_FUTURES_USER_BASE_ASSET_STATISTICS: '/v1/admin/futures/base_asset_statistics',
		GET_FUTURES_USER_LIQUIDATION_HISTORY: '/v1/admin/futures/user_liquidation_history',
		// Options
		GET_OPTIONS_POSITIONS_BY_USER: '/v1/admin/options/user-positions',
		GET_OPTIONS_ORDERS_HISTORY_BY_USER: '/v1/admin/options/user-order-history',
		GET_OPTIONS_TRADES_HISTORY_BY_USER: '/v1/admin/options/user-trade-history',
		GET_OPTIONS_DELIVERY_BY_USER: '/v1/admin/options/user-delivery',
		GET_OPTIONS_LIQUIDATION_HISTORY_BY_USER: '/v1/admin/options/liquidation-history',
	},
	adminManagement: {
		ADMINS: '/v1/admin/admins', // new
		PERMISSIONS: '/v1/admin/permissions', // new
		CREATE_ADMIN: '/v1/admin/admins', // new
		EDIT_ADMIN: '/v1/admin/admins', // new
		DELETE_ADMIN: '/v1/admin/admins', // new
		CHANGE_PASSWORD_ADMIN: (id: number) => `/admin/admins/change-password-admin/${id}`,
	},

	wallet: {
		BALANCE: '/v1/admin/user_balances', // new
		HOT_BALANCE: '/v1/admin/hot_wallets_chart_data', // new
		TOTAL_FEE_COLLECTED: '/v1/admin/total_fee_collected_data', // new
	},
	fee: {
		ORDER_FEES_AND_LIMITS: '/v1/admin/pairs/fees', // new
		UPDATE_ORDER_FEES_AND_LIMITS: '/v1/admin/pairs/fees', // new
		CRYPTO_FEES: '/v1/admin/crypto_fees', // new
		UPDATE_CRYPTO_FEES: '/v1/admin/crypto_fees', // new
		PLATFORM_FEES: '/v1/admin/fees', // new
		UPDATE_PLATFORM_FEES: '/v1/admin/fees', // new
		FIAT_FEES: '/v1/admin/fiat_fees', // new
		UPDATE_FIAT_FEES: '/v1/admin/fiat_fees', // new
		VOLUME_SETTINGS: '/admin/volume_settings',
		UPDATE_VOLUME_SETTINGS: '/admin/volume_settings',
		MARGIN_BORROW: '/v1/admin/margin/fees', // new
		MARGIN_BORROW_UPDATE: '/v1/admin/margin/fees', // new
	},
	pendingWithdrawals: {
		PENDING_WITHDRAWALS: '/v1/admin/transactions/withdrawals/pending', // new
		REJECT_WITHDRAWALS: '/v1/admin/transactions/withdrawals/reject', // new
		APPROVE_WITHDRAWALS: '/v1/admin/transactions/withdrawals/approve', // new
	},
	notifications: {
		GET_ALL_NOTIFICATIONS: '/admin/notification/all',
		UPDATE_NOTIFICATIONS: (id: number) => `/admin/notification/${id}`,
	},
	tradingPairs: {
		GET_ASSETS_PAIRS: '/v1/admin/pairs', // new
		UPDATE_ASSETS_PAIRS: `/v1/admin/pairs`, // new
		GET_MARGIN_ISOLATED: '/v1/admin/margin/currencies', // new
		UPDATE_ISOLATED_PAIR: '/v1/admin/margin/currencies', // new
		GET_ASSETS: '/v1/admin/assets', // new
		ADD_ASSETS_PAIR: '/v1/admin/asset_pair', // new
		GET_ACTIVE_BASE_ASSET: '/v1/futures/active_base_asset/', // new
		PUT_ACTIVE_BASE_ASSET: '/v1/admin/futures/asset_future_settings', // new
	},
	walletManagement: {
		GET_HOT_WALLETS_DATA: '/v1/admin/hot_wallets_data', // new
		GET_HOT_WALLET_ADDRESS: '/v1/admin/get_hot_wallet_address', // new
		WITHDRAW_TO_COLD_WALLET: '/v1/admin/withdraw_to_cold_wallet', // new
		HOT_COLD_TRANSACTIONS: '/v1/admin/hot_transactions', // new
	},
	marketMakerBot: {
		MARKET_MAKER_SETTINGS: '/admin/market_maker/settings',
		MARKET_MAKER_SETTINGS_EDIT: '/admin/market_maker/settings',
		MARKET_MAKER_TOGGLE_ENABLE: '/admin/market_maker/toggle_enabled',
	},
	limitations: {
		CRYPTO_FEES: '/v1/admin/crypto_limits', // new
		UPDATE_CRYPTO_FEES: '/v1/admin/crypto_limits', // new
		SPOT_LIMITS: 'v1/admin/spot/limits',
		DERIVATIVE_LIMITS: 'v1/admin/derivatives/limits',
	},
	transactions: {
		DEPOSITS_CRYPTO: '/v1/admin/transactions/deposits', // new
		DEPOSITS_FIAT: '/v1/admin/transactions/fiat', // new
		WITHDRAWALS: '/v1/admin/transactions/withdrawals', // new
		TRANSFERS: '/v1/admin/transactions/transfers', // new
		CONVERTS: '/v1/admin/exchanges', // new
	},
	spot: {
		USER_BALANCES: (id: number) => `/admin/user/${id}/balances`,
	},
	chat: {
		POST_P2p_TRADE: `p2p/trading/trade`,
		GET_P2p_TRADE: (trade_id: string) => `p2p/trading/trade/${trade_id}`,
		GET_P2p_CHATS: (trade_id: string) => `p2p/trading/chats/${trade_id}`,
		PUT_P2p_CANSELED: (trade_id: string) => `p2p/trading/cancel_trade/${trade_id}`,
		PUT_P2p_RECEIVED: (trade_id: string) => `p2p/trading/have_received_payment/${trade_id}`,
		PUT_P2p_PAID_SELLER: (trade_id: string) => `p2p/trading/have_paid_seller/${trade_id}`,
	},
	p2pChat: {
		GET_P2p_CHATS: (trade_id: string) => `admin/p2p/disputes/chats/${trade_id}`,
		POST_MESSAGE_SELLER: `admin/p2p/disputes/message/seller`,
		POST_MESSAGE_BUYER: `admin/p2p/disputes/message/buyer`,
	},
	options: {
		ALL_ORDERS: `v1/admin/options/all-orders`,
	},
};
