/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import {
	getOrdersHistoryIsLoad,
	getOrdersHistoryList,
	getUserBalancesIsLoad,
	getUserBalancesList,
} from 'redux/reducers/userManagement/selectors';
import queryString from 'query-string';
import {
	getUserBalancesRequest,
	getUserOrdersHistoryRequest,
} from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { IApiGetOrderHistoryParams } from 'services/api/users/types';
import SpotBalancesItem from './SpotBalancesItem';
import SpotOrdersHistory from './SpotOrdersHistory';

export const Spot: FC = () => {
	const { id } = useParams<{ id: string }>();
	const userBalancesList = useSelector(getUserBalancesList);
	const userBalancesListLoader = useSelector(getUserBalancesIsLoad);
	const ordersHistoryList = useSelector(getOrdersHistoryList);
	const ordersHistoryListLoader = useSelector(getOrdersHistoryIsLoad);

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const [activeMenu, setActiveMenu] = useState('Balances');

	let className = '';
	if (activeMenu === '') {
		className = '';
	} else if (activeMenu === 'Balances') {
		className = 'table table--spot-balance';
	} else if (activeMenu === 'Orders history') {
		className = 'table table--spot-order_history';
	}

	const arrayMenu = [
		{
			id: 1,
			name: 'Balances',
		},
		{
			id: 2,
			name: 'Orders history',
		},
	];

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);

		const searchParams = `?page=${String(pageNumber)}`;
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		const params: IApiGetOrderHistoryParams = {
			user_id: id,
			current_page: pageNumber || 1,
		};
		dispatch(getUserOrdersHistoryRequest(params));
	};

	const menuTop = () => {
		return arrayMenu.map((e) => {
			return (
				<li
					key={e.id}
					className={`table-panel-tab__item ${activeMenu === e.name ? 'active' : ''}`}
					onClick={() => setActiveMenu(e.name)}
				>
					<span className="table-panel-tab__link">{e.name}</span>
				</li>
			);
		});
	};

	useEffect(() => {
		history.push({
			pathname: location.pathname,
			search: ``,
		});
		setCurrentPage(1);

		dispatch(getUserOrdersHistoryRequest({ user_id: id }));
	}, [dispatch, id]);

	useEffect(() => {
		dispatch(getUserBalancesRequest({ id: `${id}` }));
	}, []);

	const menuFuncBody = () => {
		if (activeMenu === 'Balances') {
			return (
				<div>
					{userBalancesListLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<>
							{userBalancesList?.map((item) => (
								<SpotBalancesItem item={item} key={item.id} />
							))}
							<div />
						</>
					)}
				</div>
			);
		}
		if (activeMenu === 'Orders history') {
			return (
				<div>
					{ordersHistoryListLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<>
							{ordersHistoryList?.data.length === 0 ? (
								<p style={{ padding: '20px' }}>Orders history is empty</p>
							) : null}
							{ordersHistoryList?.data.map((item) => (
								<SpotOrdersHistory item={item} key={item.id} />
							))}
							<div />
						</>
					)}
				</div>
			);
		}
		return null;
	};
	const menuFuncHeader = () => {
		if (activeMenu === 'Balances') {
			return (
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Cryptocurrency</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name td--center">
							<p>Total balance</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name td--right">
							<p>Available balance</p>
						</div>
					</div>
				</div>
			);
		}
		if (activeMenu === 'Orders history') {
			return (
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>ID</p>
						</div>
					</div>
					<div className="td">
						<div className="td-name">
							<p>Pair</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Type</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Amount</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Filled</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Total</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Price</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Average price</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Status</p>
						</div>
					</div>
					<div className="td td">
						<div className="td-name">
							<p>Created</p>
						</div>
					</div>
				</div>
			);
		}
		return null;
	};
	const paginationFooter = () => {
		if (activeMenu === 'Balances') {
			return null;
		}
		if (activeMenu === 'Orders history') {
			return (
				<>
					{(ordersHistoryList?.last_page ?? 0) > 1 && (
						<Pagination
							pageCount={ordersHistoryList?.last_page ?? 0}
							forcePage={currentPage - 1}
							onPageChange={handlePageChange}
						/>
					)}
					{openWhitelisting && (
						<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
					)}
				</>
			);
		}
		return null;
	};

	return (
		<div className="spot">
			<div className="content-block content-block--user-management">
				<div>
					<div className="content-block__inside content-block__inside--panel-only">
						<div className="table-panel">
							<ul className="table-panel-tab">{menuTop()}</ul>
							<div style={{ cursor: 'default', opacity: 0 }} className="table-panel__radio-group">
								<div className="table-panel__radio-group">
									<div className="radio">
										<label className="radio__label">
											<input type="radio" className="hidden" name="margin-type" />
											<span style={{ cursor: 'default' }} className="radio__item" />
											<span style={{ cursor: 'default' }} className="radio__text">
												Cross{' '}
											</span>
										</label>
									</div>
									<div className="radio">
										<label className="radio__label">
											<input type="radio" className="hidden" name="margin-type" />
											<span style={{ cursor: 'default' }} className="radio__item" />
											<span style={{ cursor: 'default' }} className="radio__text">
												Isolated
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					<div className="table-block table-block--ip">
						<div className="table-wrapper">
							<div className={className}>
								<div className="table-header">{menuFuncHeader()}</div>
								<div className="table-body">{menuFuncBody()}</div>
							</div>
						</div>
					</div>
					{paginationFooter()}
				</div>
			</div>
		</div>
	);
};
