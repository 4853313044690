import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { WalletManagement } from 'components/WalletManagement';

// ================================================:
export const WalletManagementPage: FC = () => {
	return (
		<Dashboard title="Wallet management">
			<WalletManagement />
		</Dashboard>
	);
};
