/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { FC, useState } from 'react';
import classNames from 'classnames';
import SpotLimitsTab from './Spot/index';
import DerivativeLimitsTab from './Derivative/index';

const TAB_NAMES = ['Spot', 'Derivatives'];

export const LimitationsTradingLimits: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);

	return (
		<div className="table-block table-block--trades table-block--nomt">
			<div className="content-block__inside content-block__inside--panel-only">
				<div className="table-panel">
					<ul className="table-panel-tab">
						{TAB_NAMES.map((tabName, index) => (
							<li
								key={index} // eslint-disable-line
								className={classNames('table-panel-tab__item', { active: tabIndex === index })}
								onClick={() => setTabIndex(index)}
							>
								<span className="table-panel-tab__link">{tabName}</span>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div className="table-wrapper">
				{tabIndex === 0 && <SpotLimitsTab />}
				{tabIndex === 1 && <DerivativeLimitsTab />}
			</div>
		</div>
	);
};
