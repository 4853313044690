/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
	getTransfersTransactionsList,
	getTransfersTransactionsIsLoad,
} from 'redux/reducers/transactions/selectors';
import Loader from 'ui/Loader';
import { TransfersItem } from './TransfersItem';

export const Transfers: FC = () => {
	const transfers = useSelector(getTransfersTransactionsList);
	const transfersLoader = useSelector(getTransfersTransactionsIsLoad);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block content-block--transaction">
				<div className="content-block__inside content-block__inside--deposit-his" />
				<div className="content-block__inside content-block__inside--login-his">
					{(transfers?.data?.length ?? 0) < 1 && !transfersLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">Transfers not found...</p>
						</div>
					)}
					{transfersLoader && (
						<div className="list-loader">
							<Loader />
						</div>
					)}
					{(transfers?.data?.length ?? 0) > 0 && !transfersLoader && (
						<div className="table-block">
							<div className="table-wrapper">
								<div className="table table--transaction-transfer">
									<div className="table-header">
										<div className="tr">
											<div className="td">
												<div className="td-name">
													<p>ID</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>User ID</p>
												</div>
											</div>

											<div className="td">
												<div className="td-name">
													<p>Date</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>Coin</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>From wallet</p>
												</div>
											</div>

											<div className="td">
												<div className="td-name">
													<p>To wallet</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p>Amount</p>
												</div>
											</div>
										</div>
									</div>

									<div className="table-body">
										{transfers?.data.map((item) => (
											<TransfersItem data={item} key={item.id} />
										))}
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
