import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';
import { ILiquidationProps } from './types';

const LiquidationTableItem: FC<ILiquidationProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [pair] = useState(item.pair);
	const [amount] = useState(item.quantity);
	const total = Number(item.quantity) * Number(item.price);
	const [created] = useState(item.created_at);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Pair</p>
				<p style={{ textTransform: 'uppercase' }}>{pair.replace('_', '/')}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Total</p>
				<p>{noExponent(Number(total).toString())}</p>
			</div>
		</div>
	);
};
export default LiquidationTableItem;
