/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { getParameterByName } from 'utils/paramNames';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IApproveWithdrawalsRequestPayload,
	IApproveWithdrawalsResponsePayload,
	IRejectWithdrawalsRequestPayload,
	IRejectWithdrawalsResponsePayload,
	IWithdrawalsListRequestPayload,
	IWithdrawalsResponsePayload,
} from './types';
import {
	getWithdrawalsRequest,
	getWithdrawalsSuccess,
	withdrawalsInitState,
	approveWithdrawalsRequest,
	approveWithdrawalsSuccess,
	rejectWithdrawalsRequest,
	rejectWithdrawalsSuccess,
} from './reducer';

// ===================================================:
function* withdrawalsRequestWorker(action: PayloadAction<IWithdrawalsListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWithdrawalsResponsePayload = yield call(
			api.withdrawals.getWithdrawals,
			payload,
		);
		yield put(getWithdrawalsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:
function* approveWithdrawalsRequestWorker(
	action: PayloadAction<IApproveWithdrawalsRequestPayload>,
) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');
	const searchField = getParameterByName('field');
	const searchValue = getParameterByName('value');

	const params =
		searchField && searchValue && pageNumber
			? {
					current_page: pageNumber,
					search_field: searchField,
					search_value: searchValue,
			  }
			: pageNumber
			? { current_page: pageNumber }
			: {};

	try {
		yield put(showLoading());
		const response: IApproveWithdrawalsResponsePayload = yield call(
			api.withdrawals.approveWithdrawals,
			payload,
		);
		yield put(approveWithdrawalsSuccess(response));
		notificationContainer('Withdrawals has been successfully approved!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWithdrawalsRequest(params));
	}
}

// ==================================================:
function* rejectWithdrawalsRequestWorker(action: PayloadAction<IRejectWithdrawalsRequestPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');
	const searchField = getParameterByName('field');
	const searchValue = getParameterByName('value');

	const params =
		searchField && searchValue && pageNumber
			? {
					current_page: pageNumber,
					search_field: searchField,
					search_value: searchValue,
			  }
			: pageNumber
			? { current_page: pageNumber }
			: {};

	try {
		yield put(showLoading());
		const response: IRejectWithdrawalsResponsePayload = yield call(
			api.withdrawals.rejectWithdrawals,
			payload,
		);
		yield put(rejectWithdrawalsSuccess(response));
		notificationContainer('Withdrawals has been successfully rejected!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(withdrawalsInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWithdrawalsRequest(params));
	}
}

// ==================================================:

export function* withdrawalsSaga() {
	yield takeEvery(getWithdrawalsRequest.type, withdrawalsRequestWorker);
	yield takeEvery(approveWithdrawalsRequest.type, approveWithdrawalsRequestWorker);
	yield takeEvery(rejectWithdrawalsRequest.type, rejectWithdrawalsRequestWorker);
}
