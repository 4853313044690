import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { loadingBarReducer } from 'react-redux-loading-bar';
import { History } from 'history';

import adminManagement from 'redux/reducers/adminManagement/reducer';
import balance from 'redux/reducers/balance/reducer';
import settings from 'redux/reducers/settings/reducer';
import app from 'redux/reducers/app/reducer';
import auth from 'redux/reducers/auth/reducer';
import userManagement from 'redux/reducers/userManagement/reducer';
import fees from 'redux/reducers/fees/reducer';
import withdrawals from 'redux/reducers/withdrawals/reducer';
import notifications from 'redux/reducers/notifications/reducer';
import tradingPairs from 'redux/reducers/tradingPairs/reducer';
import walletManagement from 'redux/reducers/walletManagement/reducer';
import marketMakerBot from 'redux/reducers/marketMakerBot/reducer';
import limitations from 'redux/reducers/limitations/reducer';
import transactions from 'redux/reducers/transactions/reducer';
import trades from 'redux/reducers/trade/reducer';
import orders from 'redux/reducers/orders/reducer';
import dispute from 'redux/reducers/dispute/reducer';
import currencies from 'redux/reducers/сurrencies/reducer';
import sockets from 'redux/reducers/sockets/reducer';
import chat from 'redux/reducers/chat/reducer';
import options from 'redux/reducers/options/reducer';

const createRootReducer = (history: History) =>
	combineReducers({
		router: connectRouter(history),
		loadingBar: loadingBarReducer,
		adminManagement,
		balance,
		settings,
		app,
		auth,
		userManagement,
		fees,
		withdrawals,
		notifications,
		tradingPairs,
		walletManagement,
		marketMakerBot,
		limitations,
		transactions,
		trades,
		orders,
		dispute,
		currencies,
		sockets,
		chat,
		options,
	});

export default createRootReducer;
