import { TApiGetUserTransactionsCryptoStatus } from 'services/api/users/types';

export const cryptoTransactionStatusNames: Record<TApiGetUserTransactionsCryptoStatus, string> = {
	canceled: 'Canceled',
	pending: 'Pending',
	completed: 'Completed',
};

export const cryptoTransactionStatusClassNames: Record<
	TApiGetUserTransactionsCryptoStatus,
	string
> = {
	canceled: 'status--rejected',
	pending: 'status--pending',
	completed: 'status--verified',
};
