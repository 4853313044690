import React from 'react';

export const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Currency</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>User Orders</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>User Positions</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Min. Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Min.Qty</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Trading Fee, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Taker Fee, % </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Maker Fee, % </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Delivery Fee rate, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Liquidation Fee rate, % </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Margin, MM Factor % </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Margin, Max IM Factor % </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name td-name--action">
						<p>Margin, Min IM Factor % </p>
					</div>
				</div>
			</div>
		</div>
	);
};
