import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { getOptionsStatistics } from '../options/selectors';
import {
	I2FAData,
	IDepositAndWithdrawHistoryData,
	ILoginHistoryData,
	IUsersData,
	IUsersStore,
	IWhitelistIpListData,
	TPositionsByUserWithStatistics,
} from './types';

export const getUserManagement = (state: IStoreState): IUsersStore => state.userManagement;

export const getUsersIsLoad = createSelector(
	[getUserManagement],
	(users: IUsersStore): boolean => users.usersLoader,
);

export const getUsersList = createSelector(
	[getUserManagement],
	(users: IUsersStore): IUsersData => users.users,
);

export const getOneUser = (state: IStoreState) => {
	return getUserManagement(state).selectUser;
};

export const getSelectUserIsLoad = (state: IStoreState) => {
	return getUserManagement(state).userLoader;
};

export const getOneUserDateRange = (state: IStoreState) => {
	return getUserManagement(state).oneUserDateRange;
};

export const getUserBalances = createSelector(
	[getUserManagement],
	(userBalances: IUsersStore) => userBalances,
);

export const getUserBalancesIsLoad = createSelector(
	[getUserManagement],
	(userBalances: IUsersStore) => userBalances.userBalancesLoader,
);
export const getOrdersHistoryList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistory,
);
export const getOrdersHistoryIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistoryLoader,
);
export const getOrdersHistoryMarginList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistoryMargin,
);
export const getOrdersHistoryMarginIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userOrdersHistoryMarginLoader,
);
export const getBorrowingMarginList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userBorrowingMargin,
);
export const getBorrowingMarginIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userBorrowingLoader,
);

export const getUserBalancesList = (state: IStoreState) => {
	return getUserManagement(state).userBalances;
};

export const getRepaymentMarginList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userRepaymentMargin,
);
export const getRepaymentMarginIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userRepaymentLoader,
);
export const getInterestMarginList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userInterestMargin,
);
export const getInterestMarginIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userInterestLoader,
);

export const getLiquidationMarginList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userLiquidationMargin,
);
export const getLiquidationMarginIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userLiquidationLoader,
);
export const getBalancesMarginList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userBalancesMargin,
);
export const getBalancesMarginIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userBalancesMarginLoader,
);
export const getMarginCallMarginList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userMarginCallMargin,
);
export const getMarginCallMarginIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userMarginCallMarginLoader,
);
export const getCryptoDepositList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userCryptoDeposit,
);

export const getCryptoDepositIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userCryptoDepositLoader,
);
export const getFiatDepositList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userFiatDeposit,
);

export const getFiatDepositIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userFiatDepositLoader,
);
export const getConvertsList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userConverts,
);

export const getConvertsIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userConvertsLoader,
);
export const getTransfersList = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userTransfers,
);

export const getTransfersIsLoad = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.userTransfersLoader,
);
export const tabMenuUserIndex = createSelector(
	[getUserManagement],
	(ordersHistory: IUsersStore) => ordersHistory.tabMenuUserIndex,
);
export const getWhitelistIp = createSelector(
	[getUserManagement],
	(whitelistIp: IUsersStore) => whitelistIp,
);

export const getWhitelistIpIsLoad = createSelector(
	[getUserManagement],
	(whitelistIp: IUsersStore) => whitelistIp.whitelistIpLoader,
);

export const getWhitelistIpList = createSelector(
	[getUserManagement],
	(whitelistIp: IUsersStore): IWhitelistIpListData => whitelistIp.whitelistIp,
);

export const getUserBlockIsLoad = createSelector(
	[getUserManagement],
	(users: IUsersStore): boolean => users.userBlockUnblockLoader,
);

export const get2FAIsLoad = createSelector(
	[getUserManagement],
	(users: IUsersStore): boolean => users.twoFALoader,
);

export const get2FAList = createSelector(
	[getUserManagement],
	(twoFA: IUsersStore): I2FAData => twoFA.twoFA,
);

export const getLoginHistory = createSelector(
	[getUserManagement],
	(loginHistory: IUsersStore) => loginHistory,
);

export const getLoginHistoryIsLoad = createSelector(
	[getUserManagement],
	(loginHistory: IUsersStore) => loginHistory.loginHistoryLoader,
);

export const getLoginHistoryList = createSelector(
	[getUserManagement],
	(loginHistory: IUsersStore): ILoginHistoryData => loginHistory.loginHistory,
);

export const getDepositAndWithdrawHistory = createSelector(
	[getUserManagement],
	(depositAndWithdrawHistory: IUsersStore) => depositAndWithdrawHistory,
);

export const getDepositAndWithdrawHistoryIsLoad = createSelector(
	[getUserManagement],
	(depositAndWithdrawHistory: IUsersStore) =>
		depositAndWithdrawHistory.depositAndWithdrawHistoryLoader,
);

export const getDepositAndWithdrawHistoryList = createSelector(
	[getUserManagement],
	(depositAndWithdrawHistory: IUsersStore): IDepositAndWithdrawHistoryData =>
		depositAndWithdrawHistory.depositAndWithdrawHistory,
);

// 📌 User details / Futures

export const getFuturesUserOrderHistory = (state: IStoreState) => {
	return getUserManagement(state).futuresUserOrderHistory;
};

export const getFuturesUserOrderHistoryLoading = (state: IStoreState) => {
	return getUserManagement(state).futuresUserOrderHistoryLoading;
};
export const getFuturesUserPositions = (state: IStoreState) => {
	return getUserManagement(state).futuresUserPositions;
};

export const getFuturesUserPositionsLoading = (state: IStoreState) => {
	return getUserManagement(state).futuresUserPositionsLoading;
};

export const getFuturesUserBalances = (state: IStoreState) => {
	return getUserManagement(state).futuresUserBalances;
};

export const getFuturesUserBalancesLoading = (state: IStoreState) => {
	return getUserManagement(state).futuresUserBalancesLoading;
};
export const getFuturesUserTradeHistory = (state: IStoreState) => {
	return getUserManagement(state).futuresUserTradeHistory;
};

export const getFuturesUserTradeHistoryLoading = (state: IStoreState) => {
	return getUserManagement(state).futuresUserTradeHistoryLoading;
};
export const getFuturesUserMarkPrice = (state: IStoreState) => {
	return getUserManagement(state).futuresUserMarkPrice;
};

export const getFuturesUserMarkPriceLoading = (state: IStoreState) => {
	return getUserManagement(state).futuresUserMarkPriceLoading;
};

export const getFuturesUserLiquidationHistory = (state: IStoreState) => {
	return getUserManagement(state).futuresUserLiquidationHistory;
};

export const getFuturesUserLiquidationHistoryLoading = (state: IStoreState) => {
	return getUserManagement(state).futuresUserLiquidationHistoryLoading;
};

// 📌 User details / Options

export const getOptionsPositionsByUser = (state: IStoreState) => {
	return getUserManagement(state).optionsPositionsByUser;
};
export const getOptionsPositionsByUserLoading = (state: IStoreState) => {
	return getUserManagement(state).optionsPositionsByUserLoading;
};
export const getPositionsByUserWithStatistics = createSelector(
	[getOptionsPositionsByUser, getOptionsStatistics],
	(positions, statistics): TPositionsByUserWithStatistics | null => {
		if (!positions) return null;

		const data = positions.data.map((position) => ({
			...position,
			option: statistics?.[position.option_id] || null,
		}));

		return { ...positions, data };
	},
);

export const getOptionsOrdersHistoryByUser = (state: IStoreState) => {
	return getUserManagement(state).optionsOrdersHistoryByUser;
};
export const getOptionsOrdersHistoryByUserLoading = (state: IStoreState) => {
	return getUserManagement(state).optionsOrdersHistoryByUserLoading;
};

export const getOptionsTradesHistoryByUser = (state: IStoreState) => {
	return getUserManagement(state).optionsTradesHistoryByUser;
};
export const getOptionsTradesHistoryByUserLoading = (state: IStoreState) => {
	return getUserManagement(state).optionsTradesHistoryByUserLoading;
};

export const getOptionsDeliveryByUser = (state: IStoreState) => {
	return getUserManagement(state).optionsDeliveryByUser;
};
export const getOptionsDeliveryByUserLoading = (state: IStoreState) => {
	return getUserManagement(state).optionsDeliveryByUserLoading;
};

export const getOptionsLiquidationHistoryByUserLoading = (state: IStoreState) => {
	return getUserManagement(state).optionsLiquidationHistoryByUserLoading;
};
export const getOptionsLiquidationHistoryByUser = (state: IStoreState) => {
	return getUserManagement(state).optionsLiquidationHistoryByUser;
};
