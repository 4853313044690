import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPermissionsListRequest } from 'redux/reducers/adminManagement/reducer';
import Dashboard from 'layouts/Dashboard';
import EditAdmin from 'components/AdminManagement/EditAdmin';

// ==========================================:
const EditAdminPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPermissionsListRequest());
	}, [dispatch]);

	return (
		<Dashboard title="Edit Admin">
			<EditAdmin />
		</Dashboard>
	);
};

export default EditAdminPage;
