import { FC } from 'react';
import { allStatus } from 'redux/reducers/transactions/constants';
import { noExponent } from 'utils/numberFormat';
import { ITableItemProps } from './types';

const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.base_asset}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{item.order_id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Created At</p>
				<p>{new Date(item.created_at * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">End Time</p>
				<p>{new Date(item.updated_at * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Side</p>
				<p>{item.transactions_type}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order Price</p>
				<p>{noExponent(item.price_avarage_completed)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Size</p>
				<p>{item.amount_asset_request}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order Filled</p>
				<p>{item.amount_asset_filled}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Fill Price</p>
				<div>
					<p>{noExponent(item.price_entry_for_order)}</p>
				</div>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${allStatus?.[item.close_reason]?.statusClass ?? ''}`}>
						{allStatus?.[item.close_reason]?.statusText}
					</span>
				</div>
			</div>
		</div>
	);
};

export default TableItem;
