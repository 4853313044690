/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiGetFuturesTradesParams,
	IApiGetFuturesTradesResponse,
	IApiGetOptionsTradesResponse,
} from 'services/api/trades/types';
import { IMarketMakerPageRequestPayload, ITradesStore } from './types';

export const initialState: ITradesStore = {
	tradesSpot: null,
	tradesSpotLoading: false,
	tradesMargin: null,
	tradesMarginLoading: false,
	futuresTrades: null,
	futuresTradesLoading: false,
	optionsTrades: null,
	optionsTradesLoading: false,
};

const trades = createSlice({
	name: '@@trades',
	initialState,
	reducers: {
		getTradesSpotRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const tradesMarginRequestState = state;
			tradesMarginRequestState.tradesSpotLoading = true;
		},
		getTradesSpotSuccess: (state, action: any) => {
			const { payload } = action;
			const tradesMarginSuccessState = state;
			tradesMarginSuccessState.tradesSpot = payload;
			tradesMarginSuccessState.tradesSpotLoading = false;
		},

		getTradesMarginRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const tradesSpotRequestState = state;
			tradesSpotRequestState.tradesMarginLoading = true;
		},
		getTradesMarginSuccess: (state, action: any) => {
			const { payload } = action;
			const tradesSpotSuccessState = state;
			tradesSpotSuccessState.tradesMargin = payload;
			tradesSpotSuccessState.tradesMarginLoading = false;
		},

		getFuturesTradesRequest: (state, action: PayloadAction<IApiGetFuturesTradesParams>) => {
			state.futuresTradesLoading = true;
		},
		getFuturesTradesSuccess: (state, { payload }: PayloadAction<IApiGetFuturesTradesResponse>) => {
			state.futuresTrades = payload;
			state.futuresTradesLoading = false;
		},
		getFuturesTradesError: (state) => {
			state.futuresTrades = null;
			state.futuresTradesLoading = false;
		},

		getOptionsTradesRequest: (state, action: PayloadAction<IApiGetFuturesTradesParams>) => {
			state.optionsTradesLoading = true;
		},
		getOptionsTradesSuccess: (state, { payload }: PayloadAction<IApiGetOptionsTradesResponse>) => {
			state.optionsTrades = payload;
			state.optionsTradesLoading = false;
		},
		getOptionsTradesError: (state) => {
			state.optionsTrades = null;
			state.optionsTradesLoading = false;
		},

		marketMakerBotInitState: () => initialState,
	},
});

export default trades.reducer;
export const {
	getTradesSpotRequest,
	getTradesSpotSuccess,
	getTradesMarginRequest,
	getTradesMarginSuccess,
	getFuturesTradesRequest,
	getFuturesTradesSuccess,
	getFuturesTradesError,
	getOptionsTradesRequest,
	getOptionsTradesSuccess,
	getOptionsTradesError,
} = trades.actions;
