/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IChangeLimitationsPayload,
	ILimitationsRequestPayload,
	ILimitationsResponsePayload,
} from './types';
import { IPutLimitsResponsePayload } from './types';
import {
	changeLimitationsRequest,
	changeLimitationsSuccess,
	getDerivativeLimitsRequest,
	getDerivativetLimitsSuccess,
	getLimitationsRequest,
	getLimitationsSuccess,
	getSpotLimitsRequest,
	getSpotLimitsSuccess,
	limitationsInitState,
	putDerivativeLimitsRequest,
	putDerivativetLimitsSuccess,
	putSpotLimitsRequest,
	putSpotLimitsSuccess,
} from './reducer';

function* limitationsRequestWorker(action: PayloadAction<ILimitationsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ILimitationsResponsePayload = yield call(
			api.limitations.getLimitations,
			payload,
		);
		yield put(getLimitationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(limitationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* changeLimitationsWorker(action: PayloadAction<IChangeLimitationsPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.limitations.changeLimitations, payload);
		yield put(changeLimitationsSuccess());

		notificationContainer('Your withdraw limit changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(limitationsInitState());
		}
	} finally {
		yield put(hideLoading());
		yield put(getLimitationsRequest({}));
	}
}

function* spotLimitsWorker() {
	try {
		const response: ILimitationsResponsePayload = yield call(api.limitations.getSpotLimitations);
		yield put(getSpotLimitsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* derivativeLimitsWorker() {
	try {
		const response: ILimitationsResponsePayload = yield call(
			api.limitations.getDerivativeLimitations,
		);
		yield put(getDerivativetLimitsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* changeSpotLimitsWorker({ payload }: any) {
	try {
		const response: IPutLimitsResponsePayload = yield call(
			api.limitations.putSpotLimitations,
			payload,
		);
		yield put(putSpotLimitsSuccess());
		yield put(getSpotLimitsRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* changeDerivativeLimitsWorker({ payload }: any) {
	try {
		const response: IPutLimitsResponsePayload = yield call(
			api.limitations.putDerivativeLimitations,
			payload,
		);
		yield put(putDerivativetLimitsSuccess());
		yield put(getDerivativeLimitsRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* limitationsSaga() {
	yield takeEvery(getLimitationsRequest.type, limitationsRequestWorker);
	yield takeEvery(changeLimitationsRequest.type, changeLimitationsWorker);
	yield takeEvery(getSpotLimitsRequest.type, spotLimitsWorker);
	yield takeEvery(getDerivativeLimitsRequest.type, derivativeLimitsWorker);
	yield takeEvery(putSpotLimitsRequest.type, changeSpotLimitsWorker);
	yield takeEvery(putDerivativeLimitsRequest.type, changeDerivativeLimitsWorker);
}
