import { FC } from 'react';

export const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Delivery Time</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Contract</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Direction</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>QTY</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Delivery Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Strike Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fee</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Delivery P&L</p>
					</div>
				</div>
			</div>
		</div>
	);
};
