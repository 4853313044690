import { FC } from 'react';
import Nav from 'layouts-elements/Nav';
import { useDispatch, useSelector } from 'react-redux';
import { getMobileMenuStatus } from 'redux/reducers/app/selectors';
import { mobileMenu } from 'redux/reducers/app/reducer';
import { CollapseIcon, ExpandIcon } from 'assets/styles/inline-svg';

const Sidebar: FC = () => {
	const isMobileMenu = useSelector(getMobileMenuStatus);
	const dispatch = useDispatch();

	const handleMobileMenu = (): void => {
		dispatch(mobileMenu({ open: !isMobileMenu }));
	};

	return (
		<div className="sidebar">
			<button className="sidebar__action" onClick={handleMobileMenu} type="button">
				{isMobileMenu ? <ExpandIcon /> : <CollapseIcon />}
			</button>

			<Nav />
		</div>
	);
};

export default Sidebar;
