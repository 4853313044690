/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IAdminManagementStore,
	IAdminsListRequestPayload,
	IAdminsListSuccessPayload,
	IChangePasswordAdminPayload,
	ICreateNewAdminPayload,
	IDeleteAdminPayload,
	IEditAdminPayload,
	IPermissionsListSuccessPayload,
} from './types';

// ==========================================:
export const initialState: IAdminManagementStore = {
	adminsListLoader: false,
	adminsList: null,
	adminId: null,
	permissionsListLoader: false,
	permissionsList: null,
	createNewAdminLoader: false,
	editAdminLoader: false,
	deleteAdminLoader: false,
	changeAdminPasswordLoader: false,
};

// ==========================================:
const adminManagement = createSlice({
	name: '@@users',
	initialState,
	reducers: {
		getAdminsListRequest: (state, action: PayloadAction<IAdminsListRequestPayload>) => {
			const adminsListState = state;

			adminsListState.adminsListLoader = true;
		},

		setAdminsListSuccess: (state, action: PayloadAction<IAdminsListSuccessPayload>) => {
			const { payload } = action;
			const adminsListSuccessState = state;

			adminsListSuccessState.adminsList = payload;
			adminsListSuccessState.adminsListLoader = false;
		},

		getPermissionsListRequest: (state) => {
			const permissionsState = state;

			permissionsState.permissionsListLoader = true;
		},

		setPermissionsListSuccess: (state, action: PayloadAction<IPermissionsListSuccessPayload>) => {
			const { payload } = action;
			const permissionsListSuccessState = state;

			permissionsListSuccessState.permissionsList = payload;
			permissionsListSuccessState.permissionsListLoader = false;
		},

		createNewAdminRequest: (state, action: PayloadAction<ICreateNewAdminPayload>) => {
			const createNewAdminState = state;

			createNewAdminState.createNewAdminLoader = true;
		},
		createNewAdminSuccess: (state) => {
			const createNewAdminState = state;

			createNewAdminState.createNewAdminLoader = false;
		},
		editAdminRequest: (state, action: PayloadAction<IEditAdminPayload>) => {
			const editAdminState = state;

			editAdminState.editAdminLoader = true;
		},
		editAdminSuccess: (state) => {
			const editAdminState = state;

			editAdminState.editAdminLoader = false;
		},
		changeAdminPasswordRequest: (state, action: PayloadAction<IChangePasswordAdminPayload>) => {
			const changeAdminPasswordState = state;

			changeAdminPasswordState.changeAdminPasswordLoader = true;
		},
		deleteAdminRequest: (state, action: PayloadAction<IDeleteAdminPayload>) => {
			const deleteAdminState = state;

			deleteAdminState.deleteAdminLoader = true;
		},
		deleteAdminSuccess: (state) => {
			const deleteAdminState = state;

			deleteAdminState.deleteAdminLoader = false;
		},

		adminsListInitState: () => initialState,
	},
});

export default adminManagement.reducer;
export const {
	getAdminsListRequest,
	setAdminsListSuccess,
	adminsListInitState,
	getPermissionsListRequest,
	setPermissionsListSuccess,
	createNewAdminRequest,
	createNewAdminSuccess,
	editAdminRequest,
	editAdminSuccess,
	changeAdminPasswordRequest,
	deleteAdminRequest,
	deleteAdminSuccess,
} = adminManagement.actions;
