import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import AdminPanel from 'components/AdminPanel';
import { useSelector } from 'react-redux';
import { getAdminPermissions } from 'redux/reducers/auth/selectors';
import AdminManagement from 'components/AdminManagement';
import UserManagement from 'components/UserManagement';
import TwoFA from 'components/TwoFA';
import Withdrawals from 'components/Withdrawals';
import Orders from 'components/Orders';
import Currencies from 'components/Currencies';
import Trades from 'components/Trades';
import { TradingPairs } from 'components/TradingPairs';
import { Transactions } from 'components/Transactions';
import Fees from 'components/Fees';
import { WalletManagement } from 'components/WalletManagement';
import Limitations from 'components/Limitations';
import Settings from '../Settings';

// ==========================================:
const Home: FC = () => {
	const permissions = useSelector(getAdminPermissions);

	const manageAdmins =
		permissions?.find((permission: any) => permission.name === 'manage admins') || null;
	const manageUsers =
		permissions?.find((permission: any) => permission.name === 'manage users') || null;
	const manageGeneral =
		permissions?.find((permission: any) => permission.name === 'manage general') || null;
	const manageTwoFa =
		permissions?.find((permission: any) => permission.name === 'fa reset request') || null;
	const manageChangePassword =
		permissions?.find((permission: any) => permission.name === 'change password"') || null;
	const managePendingWithdrawals =
		permissions?.find((permission: any) => permission.name === 'manage pending withdrawals') ||
		null;
	const manageViewOrders =
		permissions?.find((permission: any) => permission.name === 'view orders') || null;
	const manageCurrencies =
		permissions?.find((permission: any) => permission.name === 'manage currencies') || null;
	const manageViewTrades =
		permissions?.find((permission: any) => permission.name === 'view trades') || null;
	const manageAssetPairs =
		permissions?.find((permission: any) => permission.name === 'manage asset pairs') || null;
	const manageViewTransactions =
		permissions?.find((permission: any) => permission.name === 'view transactions') || null;
	const manageFees =
		permissions?.find((permission: any) => permission.name === 'manage fees') || null;
	const manageWallets =
		permissions?.find((permission: any) => permission.name === 'manage wallets') || null;
	const manageLimits =
		permissions?.find((permission: any) => permission.name === 'manage limits') || null;

	return (
		<Dashboard title="Admin">
			{(manageGeneral && <AdminPanel />) ||
				(manageUsers && <UserManagement />) ||
				(manageTwoFa && <TwoFA />) ||
				(managePendingWithdrawals && <Withdrawals />) ||
				(manageViewOrders && <Orders />) ||
				(manageCurrencies && <Currencies />) ||
				(manageViewTrades && <Trades />) ||
				(manageAssetPairs && <TradingPairs />) ||
				(manageViewTransactions && <Transactions />) ||
				(manageFees && <Fees />) ||
				(manageWallets && <WalletManagement />) ||
				(manageAdmins && <AdminManagement />) ||
				(manageLimits && <Limitations />) ||
				(manageChangePassword && <Settings />)}
		</Dashboard>
	);
};

export default Home;
