import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { ITradingPairsStore } from './types';

const getTradingPairsState = (state: IStoreState): ITradingPairsStore => state.tradingPairs;

// 📌 Spot

export const getTradingPairs = createSelector(
	[getTradingPairsState],
	(tradingPairs: ITradingPairsStore) => tradingPairs,
);

export const getEditTradingPairsLoading = (state: IStoreState) =>
	state.tradingPairs.editTradingPairsLoader;

// 📌 Margin isolated

export const getMarginIsolatedList = (state: IStoreState) => state.tradingPairs.marginIsolatedList;

export const getReversedMarginIsolatedList = createSelector(
	[getMarginIsolatedList],
	(marginIsolatedList) => [...marginIsolatedList].reverse(),
);

export const getMarginIsolatedLoading = (state: IStoreState) =>
	state.tradingPairs.marginIsolatedLoading;

export const getMarginIsolatedActionsLoading = (state: IStoreState) =>
	state.tradingPairs.marginIsolatedActionsLoading;

// 📌 Asset pairs

export const getAssetsList = (state: IStoreState) => getTradingPairsState(state).assets;

export const getAddAssetsPairLoading = (state: IStoreState) =>
	state.tradingPairs.addAssetsPairLoader;

export const getAddAssetsPairPerp = (state: IStoreState) => {
	return getTradingPairsState(state).perp;
};

export const getAddAssetsPairPerpLoading = (state: IStoreState) => {
	return getTradingPairsState(state).perpLoading;
};
