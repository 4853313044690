import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IHotColdTransactionsData,
	IHotWalletAddressData,
	IWalletManagementStore,
	IWalletsData,
} from './types';

// ==========================================:
const getWalletsState = (state: IStoreState): IWalletManagementStore => state.walletManagement;
export const getWithdrawals = createSelector(
	[getWalletsState],
	(walletManagement: IWalletManagementStore) => walletManagement,
);

// ====================================================:
export const getWalletsIsLoad = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.walletsLoader,
);

// ====================================================:
export const getWalletsList = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IWalletsData => walletManagement.wallets,
);

// ====================================================:
export const getHotWalletAddressIsLoad = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.hotWalletAddressLoader,
);
// ====================================================:
export const getHotWalletAddress = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IHotWalletAddressData =>
		walletManagement.hotWalletAddress,
);

// ====================================================:
export const getHotColdTransactionsIsLoad = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): boolean => walletManagement.hotColdTransactionsLoader,
);

// ====================================================:
export const getHotColdTransactionsList = createSelector(
	[getWithdrawals],
	(walletManagement: IWalletManagementStore): IHotColdTransactionsData =>
		walletManagement.hotColdTransactions,
);
