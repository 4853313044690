import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	TWO_FA_STATUS_NUM_ACCEPT,
	TWO_FA_STATUS_NUM_REJECT,
} from 'redux/reducers/userManagement/constants';
import { reject2FA, reset2FA } from 'redux/reducers/userManagement/reducer';
import { RejectModal } from './RejectModal';
import { ResetModal } from './ResetModal';
import { IResetRejectButtonsProps } from './types';

export const ResetRejectButtons: FC<IResetRejectButtonsProps> = ({
	status,
	id,
	handleClearSearch,
}) => {
	const dispatch = useDispatch();
	const [openResetModal, setOpenResetModal] = useState(false);
	const closeResetModal = () => setOpenResetModal(false);

	const [openRejectModal, setOpenRejectModal] = useState(false);
	const closeRejectModal = () => setOpenRejectModal(false);

	const handleReset = () => {
		dispatch(reset2FA({ id }));
		handleClearSearch();
	};
	const handleReject = () => {
		dispatch(reject2FA({ id }));
		handleClearSearch();
	};

	return (
		<>
			<div className="table-buttons">
				{status !== TWO_FA_STATUS_NUM_REJECT && status !== TWO_FA_STATUS_NUM_ACCEPT ? (
					<button type="button" onClick={() => setOpenRejectModal(true)}>
						<span className="edit-icon icon-cancel-icon" />
					</button>
				) : (
					<span className="empty-button" style={{ width: '25px' }} />
				)}

				{status !== TWO_FA_STATUS_NUM_ACCEPT ? (
					<button type="button" onClick={() => setOpenResetModal(true)}>
						<svg
							width="18"
							height="19"
							viewBox="0 0 18 19"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
								stroke="#00DA83"
								strokeLinecap="square"
							/>
						</svg>
					</button>
				) : (
					<span className="empty-button" style={{ width: '25px' }} />
				)}
			</div>
			<ResetModal
				openModal={openResetModal}
				closeModal={closeResetModal}
				handleResetModal={handleReset}
			/>
			<RejectModal
				openModal={openRejectModal}
				closeModal={closeRejectModal}
				handleRejectModal={handleReject}
			/>
		</>
	);
};
