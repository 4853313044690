import { endpoint } from 'services/endpoint';

import { http } from 'services/http';
import { ITradingPairsApi } from './types';

export const tradingPairs: ITradingPairsApi = {
	getTradingPairs: () => http.get(endpoint.tradingPairs.GET_ASSETS_PAIRS).then(({ data }) => data),

	editTradingPairs: ({ type = 'spot', ...restParams }) => {
		return http
			.put(endpoint.tradingPairs.UPDATE_ASSETS_PAIRS, undefined, {
				params: { type, ...restParams },
			})
			.then(({ data }) => data);
	},

	getAssets: () => http.get(endpoint.tradingPairs.GET_ASSETS).then(({ data }) => data),

	addAssetsPair: (payload) =>
		http.post(endpoint.tradingPairs.ADD_ASSETS_PAIR, payload).then((response) => response.data),

	getMarginIsolated: () =>
		http.get(endpoint.tradingPairs.GET_MARGIN_ISOLATED).then(({ data }) => data),

	updateIsolatedPair: (params) =>
		http.put(endpoint.tradingPairs.UPDATE_ISOLATED_PAIR, params).then(({ data }) => data),

	getActiveBaseAsset: () =>
		http.get(endpoint.tradingPairs.GET_ACTIVE_BASE_ASSET).then(({ data }) => data),
	putActiveBaseAsset: (payload) =>
		http
			.put(endpoint.tradingPairs.PUT_ACTIVE_BASE_ASSET, payload)
			.then((response) => response.data),
};
