/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import classNames from 'classnames';
import { FC, useState } from 'react';
import { FuturesOrderHistory } from './FuturesOrderHistory/FuturesOrderHistory';
import { FuturesPositions } from './FuturesPositions/FuturesPositions';
import { FuturesBalances } from './FuturesBalances/FuturesBalances';
import { FuturesTradeHistory } from './FuturesTradeHistory/FuturesTradeHistory';
import { FuturesLiquidationHistory } from './FuturesLiquidationHistory/FuturesLiquidationHistory';

const TAB_NAMES = [
	'Balances',
	'Positions',
	'Orders history',
	'Liquidation history',
	'Trades history',
];
export const Futures: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);

	return (
		<div className="margin">
			<div className="content-block content-block--user-management">
				<div>
					<div className="content-block__inside content-block__inside--panel-only">
						<div className="table-panel">
							<ul className="table-panel-tab">
								{TAB_NAMES.map((tabName, index) => (
									<li
										key={index} // eslint-disable-line
										className={classNames('table-panel-tab__item', { active: tabIndex === index })}
										onClick={() => setTabIndex(index)}
									>
										<span className="table-panel-tab__link">{tabName}</span>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				{tabIndex === 0 && <FuturesBalances />}
				{tabIndex === 1 && <FuturesPositions />}
				{tabIndex === 2 && <FuturesOrderHistory />}
				{tabIndex === 3 && <FuturesLiquidationHistory />}
				{tabIndex === 4 && <FuturesTradeHistory />}
			</div>
		</div>
	);
};
