/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import {
	TYPE_TRANSACTIONS_CRYPTO,
	TYPE_TRANSACTIONS_FIAT,
} from 'redux/reducers/transactions/constants';
import { Crypto } from '../Crypto';
import { Fiat } from '../Fiat';
import { IDepositProps } from './types';

export const Deposit: FC<IDepositProps> = ({ setTypeTransactions }) => {
	const [typeDeposit, setTypeDeposit] = useState<'crypto' | 'fiat'>('crypto');
	return (
		<div className="content-block content-block--user-management">
			<div className="content-block content-block--transaction">
				<div className="content-block__inside content-block__inside--deposit-his">
					<div className="deposit-history deposit-history--filter">
						<ul className="deposit-history__list">
							<li
								className={`deposit-history__item ${
									typeDeposit === TYPE_TRANSACTIONS_CRYPTO ? 'active' : ''
								}`}
							>
								<a
									className="deposit-history__link"
									onClick={() => {
										setTypeDeposit(TYPE_TRANSACTIONS_CRYPTO);
										setTypeTransactions(TYPE_TRANSACTIONS_CRYPTO);
									}}
								>
									Crypto
								</a>
							</li>

							<li
								className={`deposit-history__item ${
									typeDeposit === TYPE_TRANSACTIONS_FIAT ? 'active' : ''
								}`}
							>
								<a
									className="deposit-history__link"
									onClick={() => {
										setTypeDeposit(TYPE_TRANSACTIONS_FIAT);
										setTypeTransactions(TYPE_TRANSACTIONS_FIAT);
									}}
								>
									Fiat
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div className="content-block__inside content-block__inside--login-his">
					{typeDeposit === TYPE_TRANSACTIONS_CRYPTO ? <Crypto /> : <Fiat />}
				</div>
			</div>
		</div>
	);
};
