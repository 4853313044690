import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { IApiDisputeDetails } from 'services/api/dispute/types';
import { notificationContainer } from 'utils/notificationContainer';
import {
	acceptDisputeError,
	acceptDisputeRequest,
	acceptDisputeSuccess,
	getDetailsRequest,
	getDetailsSuccess,
	getDisputeRequest,
	getDisputeSuccess,
	rejectDisputeError,
	rejectDisputeRequest,
	rejectDisputeSuccess,
} from './reducer';
import { IDetailsActionsPayload, IDisputeRequestPayload, TDisputeResponse } from './types';

function* disputeRequestWorker({ payload }: PayloadAction<IDisputeRequestPayload>) {
	try {
		const response: TDisputeResponse = yield call(api.dispute.getDispute, payload);
		yield put(getDisputeSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* detailsRequestWorker({ payload }: PayloadAction<string>) {
	try {
		const data: IApiDisputeDetails = yield call(api.dispute.getDetails, payload);
		yield put(getDetailsSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* acceptDisputeWorker({ payload }: PayloadAction<IDetailsActionsPayload>) {
	try {
		yield call(api.dispute.accept, payload.disputeId);
		yield put(acceptDisputeSuccess());

		notificationContainer('Dispute approved', 'success');
		payload.history.push('/dispute');
	} catch (error) {
		yield put(acceptDisputeError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* rejectDisputeWorker({ payload }: PayloadAction<IDetailsActionsPayload>) {
	try {
		yield call(api.dispute.reject, payload.disputeId);
		yield put(rejectDisputeSuccess());

		notificationContainer('Dispute rejected', 'success');
		payload.history.push('/dispute');
	} catch (error) {
		yield put(rejectDisputeError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* disputeSaga() {
	yield takeEvery(getDisputeRequest.type, disputeRequestWorker);
	yield takeEvery(getDetailsRequest.type, detailsRequestWorker);
	yield takeEvery(acceptDisputeRequest.type, acceptDisputeWorker);
	yield takeEvery(rejectDisputeRequest.type, rejectDisputeWorker);
}
