import { FC } from 'react';
import { toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';

export const TableItem: FC<ITableItemProps> = ({ item }) => {
	const unrealizedPnl = (() => {
		if (
			typeof item.option?.mark_price !== 'number' ||
			item.entry_price === null ||
			item.amount_asset_underlying === null
		) {
			return '-';
		}
		const isCallType = item.contract_name[item.contract_name.length - 1].toUpperCase() === 'C';

		const pnl = isCallType
			? (item.option.mark_price - item.entry_price) * item.amount_asset_underlying
			: (item.entry_price - item.option.mark_price) * item.amount_asset_underlying;

		return Number(toMaxDecimals(pnl, 2)).toFixed(2);
	})();

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.contract_name}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">QTY</p>
				<p>{Number(toMaxDecimals(item.amount_asset_underlying, 2)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Avg. Entry Price</p>
				<p>{Number(toMaxDecimals(item.entry_price, 2)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Mark Price</p>
				<p>
					{typeof item.option?.mark_price === 'number'
						? Number(toMaxDecimals(item.option.mark_price, 2)).toFixed(2)
						: '-'}
				</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Unrealized P&L (ROI)</p>
				<p>{unrealizedPnl}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Realized P&L</p>
				<p>
					{item.pnl_realized === null
						? '-'
						: Number(toMaxDecimals(item.pnl_realized, 2)).toFixed(2)}
				</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Initial Margin</p>
				<p>{Number(toMaxDecimals(item.im_coefficient, 2)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Maintenance Margin</p>
				<p>{Number(toMaxDecimals(item.mm_coefficient, 2)).toFixed(2)}</p>
			</div>
		</div>
	);
};
