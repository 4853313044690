import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IConvertsTransactionsData,
	ICryptoTransactionsData,
	IFiatTransactionsData,
	ITransactionsStore,
	ITransfersTransactionsData,
	IWithdrawalsTransactionsData,
} from './types';

// ==========================================:
const getTransactionsState = (state: IStoreState): ITransactionsStore => state.transactions;
export const getTransactions = createSelector(
	[getTransactionsState],
	(transactions: ITransactionsStore) => transactions,
);

// ====================================================:
export const getCryptoTransactionsIsLoad = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): boolean => transactions.cryptoTransactionsLoader,
);

// ====================================================:
export const getCryptoTransactionsList = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): ICryptoTransactionsData => transactions.cryptoTransactions,
);

// ====================================================:
export const getFiatTransactionsList = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): IFiatTransactionsData => transactions.fiatTransactions,
);

// ====================================================:
export const getFiatTransactionsIsLoad = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): boolean => transactions.fiatTransactionsLoader,
);

// ====================================================:
export const getWithdrawalsTransactionsList = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): IWithdrawalsTransactionsData =>
		transactions.withdrawalsTransactions,
);

// ====================================================:
export const getWithdrawalsTransactionsIsLoad = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): boolean => transactions.withdrawalsTransactionsLoader,
);

// ====================================================:
export const getTransfersTransactionsList = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): ITransfersTransactionsData =>
		transactions.transfersTransactions,
);

// ====================================================:
export const getTransfersTransactionsIsLoad = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): boolean => transactions.transfersTransactionsLoader,
);

// ====================================================:
export const getConvertsTransactionsList = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): IConvertsTransactionsData =>
		transactions.convertsTransactions,
);

// ====================================================:
export const getConvertsTransactionsIsLoad = createSelector(
	[getTransactions],
	(transactions: ITransactionsStore): boolean => transactions.convertsTransactionsLoader,
);
