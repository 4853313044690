import { FC } from 'react';
import { fiatTransactionsStatus } from 'redux/reducers/transactions/constants';
import { Link } from 'react-router-dom';
import { IFiatItemProps } from './types';

export const FiatItem: FC<IFiatItemProps> = ({ data }) => {
	const date = new Date(data.created_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.created_at + offset) * 1000);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<Link className="td" to={`/user-management/${String(data.user_id)}`}>
				<p className="td-hidden-name">User ID</p>
				<p>{data.user_id}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Time</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{data.asset.code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Payment Method</p>
				<p>{data.payment_system}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Indicated Amount</p>
				<p>{Number(data.indicated_amount)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{Number(data.amount_fee)}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{Number(data.amount)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${fiatTransactionsStatus?.[data?.status]?.statusClass ?? ''}`}>
						{fiatTransactionsStatus?.[data?.status]?.statusText ?? ''}
					</span>
				</div>
			</div>
		</div>
	);
};
