import { FC } from 'react';
import { capitalizeFirstLetter, toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';

export const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Delivery Time</p>
				<p>{new Date(item.created_at).toLocaleString('en-GB')}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.contract_name}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Direction</p>
				<p>{capitalizeFirstLetter(item.transactions_type)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">QTY</p>
				<p>{toMaxDecimals(item.amount_asset_underlying, 6)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Delivery Price</p>
				<p>{Number(toMaxDecimals(item.delivery_price, 6)).toFixed(2)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Strike Price</p>
				<p>{toMaxDecimals(item.strike_price, 6)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{toMaxDecimals(item.fee, 6)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Delivery P&L</p>
				<p>{Number(toMaxDecimals(item.pnl_delivery, 2)).toFixed(2)}</p>
			</div>
		</div>
	);
};
