/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFuturesUserOrderHistory,
	getFuturesUserOrderHistoryLoading,
} from 'redux/reducers/userManagement/selectors';
import { useParams } from 'react-router';
import { getFuturesUserOrderHistoryRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { IApiGetFuturesUserOrderHistoryParams } from 'services/api/users/types';
import { TableHeader } from './TableHeader/TableHeader';
import TableItem from './TableItem/TableItem';

export const FuturesOrderHistory = () => {
	const dispatch = useDispatch();
	const { id: user_id } = useParams<{ id: string }>(); // eslint-disable-line

	const orderHistory = useSelector(getFuturesUserOrderHistory);
	const loading = useSelector(getFuturesUserOrderHistoryLoading);

	const [currentPage, setCurrentPage] = useState(1);

	useLayoutEffect(() => {
		const params: IApiGetFuturesUserOrderHistoryParams = {
			user_id,
			current_page: currentPage,
		};
		dispatch(getFuturesUserOrderHistoryRequest(params));
	}, [dispatch, user_id, currentPage]);

	return (
		<div className="content-block__inside content-block__inside--login-his">
			<div className="table-block table-block--ip">
				<div className="table-wrapper">
					<div className="table table--feature-trade_history">
						{loading && (
							<div className="list-loader">
								<Loader />
							</div>
						)}

						{!loading && (
							<>
								<TableHeader />

								<div className="table-body">
									{orderHistory?.data.map((item) => (
										<TableItem item={item} key={item.order_id} />
									))}

									{!orderHistory?.data.length && <p style={{ padding: '20px' }}>List is empty</p>}
								</div>
							</>
						)}

						<div style={{ padding: '14px' }}>
							{(orderHistory?.last_page || 0) > 1 && (
								<Pagination
									pageCount={orderHistory?.last_page || 0}
									forcePage={currentPage - 1}
									onPageChange={setCurrentPage}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
