import { FC } from 'react';
import { noExponent } from 'utils/numberFormat';
import { ITableItemProps } from './types';

const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Cryptocurrency</p>
				<p style={{ textTransform: 'uppercase' }}>USDT</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Total balance</p>
				<p>{noExponent(item.balance_usdt)}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Available balance</p>
				<p>{noExponent(item.available_balance_usdt)}</p>
			</div>
		</div>
	);
};

export default TableItem;
