import { TApiUserOrderStatus } from 'services/api/users/types';

export const orderStatusNames: Record<TApiUserOrderStatus, string> = {
	executed: 'Filled',
	cancelled: 'Cancelled',
	opened: 'Opened',
};

export const orderStatusClassNames: Record<TApiUserOrderStatus, string> = {
	executed: 'status--filled',
	cancelled: 'status--error',
	opened: 'status--verified',
};
