/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFiatDepositIsLoad, getFiatDepositList } from 'redux/reducers/userManagement/selectors';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { getUserFiatDepositRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { FiatDepositTableHeader } from './FiatDepositTableHeader';
import FiatDepositTableItem from './FiatDepositTableItem';

export const FiatDeposit = () => {
	const fiatDepositList = useSelector(getFiatDepositList);
	const fiatDepositLoad = useSelector(getFiatDepositIsLoad);
	const { id: userId } = useParams<{ id: string }>();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			user_id: userId,
			per_page: 15,
			current_page: pageNumber || 1,
			type: 'deposit',
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
		dispatch(getUserFiatDepositRequest(params));
	};

	useEffect(() => {
		const params = {
			user_id: userId,
			per_page: 15,
			current_page: 1,
			type: 'deposit',
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getUserFiatDepositRequest(params));
	}, []);

	return (
		<>
			{fiatDepositLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<>
					<FiatDepositTableHeader />
					<div className="table-body">
						{fiatDepositList?.data.map((item: any) => (
							<FiatDepositTableItem item={item} key={item.id} />
						))}
						{fiatDepositList?.data.length === 0 ? (
							<p style={{ padding: '20px' }}>Fiat deposit is empty</p>
						) : null}
					</div>
				</>
			)}
			<div style={{ padding: '14px' }}>
				{(fiatDepositList?.last_page ?? 0) > 1 && (
					<Pagination
						pageCount={fiatDepositList?.last_page ?? 0}
						forcePage={currentPage - 1}
						onPageChange={handlePageChange}
					/>
				)}
			</div>
		</>
	);
};
