/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useState } from 'react';
import { CryptoDeposit } from './TransactionHistoryBox/CryptoDeposit/CryptoDeposit';
import { CryptoWithdrawal } from './TransactionHistoryBox/CryptoWithdrawal/CryptoWithdrawal';
import { FiatDeposit } from './TransactionHistoryBox/FiatDeposit/FiatDeposit';
import { Converts } from './TransactionHistoryBox/Converts/Converts';
import { Transfers } from './TransactionHistoryBox/Transfers/Transfers';
import { FiatWithdrawal } from './TransactionHistoryBox/FiatWithdrawal/FiatWithdrawal';

export const TransactionHistory: FC = () => {
	const [activeMenu, setActiveMenu] = useState('Crypto deposit');

	let className = '';
	if (activeMenu === 'Crypto deposit') {
		className = 'table--transactionHistory-crypto_deposit';
	} else if (activeMenu === 'Crypto withdraw' || activeMenu === 'Fiat withdraw') {
		className = 'table--transactionHistory-crypto_withdraw';
	} else if (activeMenu === 'Fiat deposit') {
		className = 'table--transactionHistory-fiat_deposit';
	} else if (activeMenu === 'Converts') {
		className = 'table--transactionHistory_converts';
	} else if (activeMenu === 'Transfers') {
		className = 'table--transactionHistory_transfers';
	}
	const arrayMenu = [
		{
			id: 1,
			name: 'Crypto deposit',
		},
		{
			id: 2,
			name: 'Crypto withdraw',
		},
		{
			id: 3,
			name: 'Fiat deposit',
		},
		{
			id: 4,
			name: 'Fiat withdraw',
		},
		{
			id: 5,
			name: 'Converts',
		},
		{
			id: 6,
			name: 'Transfers',
		},
	];
	const menuTop = () => {
		return arrayMenu.map((e) => {
			return (
				<li
					key={e.id}
					className={`table-panel-tab__item ${activeMenu === e.name ? 'active' : ''}`}
					onClick={() => setActiveMenu(e.name)}
				>
					<span className="table-panel-tab__link">{e.name}</span>
				</li>
			);
		});
	};

	const menuContent = () => {
		switch (activeMenu) {
			case 'Crypto deposit':
				return <CryptoDeposit />;
			case 'Crypto withdraw':
				return <CryptoWithdrawal />;
			case 'Fiat deposit':
				return <FiatDeposit />;
			case 'Fiat withdraw':
				return <FiatWithdrawal />;
			case 'Converts':
				return <Converts />;
			case 'Transfers':
				return <Transfers />;
			default:
				null;
		}
		return null;
	};
	return (
		<div className="margin">
			<div className="content-block content-block--user-management">
				<div>
					<div className="content-block__inside content-block__inside--panel-only">
						<div className="table-panel">
							<ul className="table-panel-tab">{menuTop()}</ul>
						</div>
					</div>
				</div>
				<div className="content-block__inside content-block__inside--login-his">
					<div className="table-block table-block--ip">
						<div className="table-wrapper">
							<div className={`table ${className}`}>{menuContent()}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
