import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import { Transactions } from 'components/Transactions';

// ================================================:
const TransactionsPage: FC = () => {
	return (
		<Dashboard title="Transactions">
			<Transactions />
		</Dashboard>
	);
};

export default TransactionsPage;
