import { INotificationsResponsePayload } from 'redux/reducers/notifications/types';
import { endpoint } from 'services/endpoint';

import { http } from 'services/http';
import { INotificationsApi } from './types';

export const notifications: INotificationsApi = {
	getNotifications: (params) =>
		http
			.get<INotificationsResponsePayload>(endpoint.notifications.GET_ALL_NOTIFICATIONS, { params })
			.then((response) => response.data),
	editNotification: (payload) =>
		http
			.put(endpoint.notifications.UPDATE_NOTIFICATIONS(payload.id), {
				email_enabled: payload.email_enabled,
				sms_enabled: payload.sms_enabled,
			})
			.then((response) => response.data),
};
