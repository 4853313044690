import {
	IChangeCryptoFeesPayload,
	IChangeExchangeFeePayload,
	IChangeTradeFeesAndLimitsPayload,
} from 'redux/reducers/fees/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IFeesApi } from './types';

export const fees: IFeesApi = {
	getFeesAndLimits: () =>
		http.get(endpoint.fee.ORDER_FEES_AND_LIMITS).then((response) => response.data),
	getPlatformFees: () => http.get(endpoint.fee.PLATFORM_FEES).then((response) => response.data),
	getCryptoFees: () => http.get(endpoint.fee.CRYPTO_FEES).then((response) => response.data),
	getVolumeDependsFeeSettings: () =>
		http.get(endpoint.fee.VOLUME_SETTINGS).then((response) => response.data),
	changeVolumeDependsFeeSettings: (payload) =>
		http.put<IChangeExchangeFeePayload>(endpoint.fee.VOLUME_SETTINGS, payload),
	changeInPlatformExchangeFee: (payload) =>
		http.put<IChangeExchangeFeePayload>(endpoint.fee.UPDATE_PLATFORM_FEES, payload),
	changeFeesAndLimits: (payload) =>
		http.put<IChangeTradeFeesAndLimitsPayload>(endpoint.fee.UPDATE_ORDER_FEES_AND_LIMITS, payload),
	changeCryptoFees: (payload) =>
		http.put<IChangeCryptoFeesPayload>(endpoint.fee.UPDATE_CRYPTO_FEES, payload),

	getFiatFees: () => http.get(endpoint.fee.FIAT_FEES).then((response) => response.data),
	changeFiatFees: (payload) =>
		http.put(endpoint.fee.UPDATE_FIAT_FEES, payload).then(({ data }) => data),

	getMarginBorrowFees: () => http.get(endpoint.fee.MARGIN_BORROW).then(({ data }) => data),
	changeMarginBorrowFees: (params) =>
		http.put(endpoint.fee.MARGIN_BORROW_UPDATE, params).then(({ data }) => data),
};
