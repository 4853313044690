import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import { getParameterByName } from 'utils/paramNames';
import {
	notificationsInitState,
	getNotificationsRequest,
	getNotificationsSuccess,
	editNotificationsRequest,
	editNotificationsSuccess,
} from './reducer';
import {
	INotificationsResponsePayload,
	IEditNotificationPayload,
	INotificationRequestPayload,
} from './types';

// =============================================================:
function* notificationsRequestWorker(action: PayloadAction<INotificationRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: INotificationsResponsePayload = yield call(
			api.notifications.getNotifications,
			payload,
		);
		yield put(getNotificationsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(notificationsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editNotificationWorker(action: PayloadAction<IEditNotificationPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		yield call(api.notifications.editNotification, payload);
		yield put(editNotificationsSuccess());

		notificationContainer('Notification has been edited!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(notificationsInitState());
		// yield put(getNotificationsRequest({ current_page: pageNumber }));
	} finally {
		yield put(hideLoading());
		yield put(getNotificationsRequest({ current_page: pageNumber }));
	}
}

export function* notificationsSaga() {
	yield takeEvery(getNotificationsRequest.type, notificationsRequestWorker);
	yield takeEvery(editNotificationsRequest.type, editNotificationWorker);
}
