import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';
import { IBalancesProps } from './types';

const BalancesTableItem: FC<IBalancesProps> = ({ item }) => {
	const [balance] = useState(item.balance);
	const total = Number(balance) + Number(item.frozen_balance);
	const [balancesAvailable] = useState(item.balance);
	const [borrow] = useState(item.borrow);
	const [interestAmount] = useState(item.interest_amount);
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Cryptocurrency</p>
				<p style={{ textTransform: 'uppercase' }}>{item.asset.code}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Total balance</p>
				<p>{noExponent(Number(total).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Available balance</p>
				<p>{noExponent(Number(balancesAvailable).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Borrowed</p>
				<p>{noExponent(Number(borrow).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Interest Amount</p>
				<p>{noExponent(Number(interestAmount).toString())}</p>
			</div>
		</div>
	);
};
export default BalancesTableItem;
