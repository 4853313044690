import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeExchangeFeeRequest } from 'redux/reducers/fees/reducer';
import { numberFormat8Fraction } from 'utils/numberFormat';
import { IChangeExchangeFeeValue, IPlatformFeesItemProps } from './types';

const PlatformFeesItem: FC<IPlatformFeesItemProps> = ({ item }) => {
	const [exchange, setExchange] = useState(numberFormat8Fraction(item.exchange_fee, 'en-En'));
	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();

	const handleChangeExchangeFeeSubmit = (value: IChangeExchangeFeeValue) => {
		if (exchange === '') return;
		dispatch(changeExchangeFeeRequest(value));
	};

	return (
		<div className="tr" key={item.id}>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{item.code.toUpperCase()}</p>
			</div>
			<div className="td td--right" />
			<div className="td td--right">
				<p className="td-hidden-name">Exchange, %</p>
				{isClosed ? <div /> : <p>{exchange}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={exchange}
						onChange={(e) => setExchange(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="submit"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setExchange(numberFormat8Fraction(item.exchange_fee, 'en-En'));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleChangeExchangeFeeSubmit({
									asset_id: item.asset_id,
									exchange_fee: Number(exchange),
								});
							}}
							className={exchange === '' ? 'button-not-active' : ''}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="button" onClick={() => setIsClosed(!isClosed)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};

export default PlatformFeesItem;
