import { FC } from 'react';
import { toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';

export const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{item.id}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Created</p>
				<p style={{ textTransform: 'uppercase' }}>
					{new Date(item.created_at * 1000).toLocaleString()}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.contract_name}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{toMaxDecimals(item.amount_asset_liquidation, 6)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Fee</p>
				<p>{toMaxDecimals(item.fee, 6)}</p>
			</div>
		</div>
	);
};
