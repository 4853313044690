/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiGetFuturesAllOrdersParams,
	TApiGetFuturesAllOrdersResponse,
} from 'services/api/orders/types';
import { IMarketMakerPageRequestPayload, IOrdersStore } from './types';

export const initialState: IOrdersStore = {
	ordersSpot: null,
	ordersSpotLoading: false,
	ordersMargin: null,
	ordersMarginLoading: false,
	futuresAllOrders: null,
	futuresAllOrdersLoading: false,
	ordersOptions: null,
	ordersOptionsLoading: false,
};

const orders = createSlice({
	name: '@@orders',
	initialState,
	reducers: {
		getOrdersSpotRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const ordersMarginRequestState = state;
			ordersMarginRequestState.ordersSpotLoading = true;
		},
		getOrdersSpotSuccess: (state, action: any) => {
			const { payload } = action;
			const ordersMarginSuccessState = state;
			ordersMarginSuccessState.ordersSpot = payload;
			ordersMarginSuccessState.ordersSpotLoading = false;
		},
		getOrdersMarginRequest: (state, action: PayloadAction<IMarketMakerPageRequestPayload>) => {
			const ordersSpotRequestState = state;
			ordersSpotRequestState.ordersMarginLoading = true;
		},
		getOrdersMarginSuccess: (state, action: any) => {
			const { payload } = action;
			const ordersSpotSuccessState = state;
			ordersSpotSuccessState.ordersMargin = payload;
			ordersSpotSuccessState.ordersMarginLoading = false;
		},

		getFuturesAllOrdersRequest: (state, action: PayloadAction<IApiGetFuturesAllOrdersParams>) => {
			state.futuresAllOrdersLoading = true;
		},
		getFuturesAllOrdersSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesAllOrdersResponse>,
		) => {
			state.futuresAllOrders = payload;
			state.futuresAllOrdersLoading = false;
		},
		getFuturesAllOrdersError: (state) => {
			state.futuresAllOrders = null;
			state.futuresAllOrdersLoading = false;
		},
		getOrdersOptionsRequest: (state, action: PayloadAction<any>) => {
			state.ordersOptionsLoading = true;
		},
		getOrdersOptionsSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFuturesAllOrdersResponse>,
		) => {
			state.ordersOptions = payload;
			state.ordersOptionsLoading = false;
		},
		getOrdersOptionsError: (state) => {
			state.ordersOptions = null;
			state.ordersOptionsLoading = false;
		},

		marketMakerBotInitState: () => initialState,
	},
});
export default orders.reducer;
export const {
	getOrdersSpotRequest,
	getOrdersSpotSuccess,
	getOrdersMarginRequest,
	getOrdersMarginSuccess,
	getFuturesAllOrdersRequest,
	getFuturesAllOrdersSuccess,
	getFuturesAllOrdersError,
	getOrdersOptionsRequest,
	getOrdersOptionsSuccess,
	getOrdersOptionsError,
} = orders.actions;
