/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAssetsRequest } from 'redux/reducers/tradingPairs/reducer';
import {
	getConvertsTransactionsList,
	getConvertsTransactionsIsLoad,
} from 'redux/reducers/transactions/selectors';
import Loader from 'ui/Loader';
import { ConvertsItem } from './ConvertsItem';

export const Converts: FC = () => {
	const converts = useSelector(getConvertsTransactionsList);
	const convertsLoader = useSelector(getConvertsTransactionsIsLoad);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getAssetsRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block content-block--transaction">
				<div className="content-block__inside content-block__inside--deposit-his" />
				<div className="content-block__inside content-block__inside--login-his">
					{(converts?.data?.length ?? 0) < 1 && !convertsLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">Converts not found...</p>
						</div>
					)}
					{convertsLoader && (
						<div className="list-loader">
							<Loader />
						</div>
					)}
					{(converts?.data?.length ?? 0) > 0 && !convertsLoader ? (
						<div className="table-block">
							<div className="table-wrapper">
								<div className="table table--transaction-convert">
									<div className="table-header">
										<div className="tr">
											<div className="td">
												<div className="td-name">
													<p>ID</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>User ID</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>Date</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>Fee</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>From</p>
												</div>
											</div>

											<div className="td">
												<div className="td-name">
													<p>To</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>Convert Rate</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p>Amount (From)</p>
												</div>
											</div>
											<div className="td td--right">
												<div className="td-name">
													<p>Amount (To)</p>
												</div>
											</div>
										</div>
									</div>

									<div className="table-body">
										{converts?.data.map((item) => (
											<ConvertsItem data={item} key={item.created_at} />
										))}
									</div>
								</div>
							</div>
						</div>
					) : (
						''
					)}
				</div>
			</div>
		</div>
	);
};
