import { FC } from 'react';
import { Field } from 'formik';
import { IPermissionListItemProps } from './types';

// ================================================:
const PermissionListItem: FC<IPermissionListItemProps> = ({ setFieldValue, idx, name, values }) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="permission-name">{name}</p>
			</div>
			<div className="radio-row">
				<div className="radio">
					<label className="radio__label">
						<Field
							className="hidden"
							type="radio"
							name={`permissions[${idx}]:{id:${values?.id},editable:false,selected:true}`}
							value="false"
							checked={values?.editable === false && values?.selected === true}
							onChange={() =>
								setFieldValue(`permissions[${idx}]`, {
									id: values?.id,
									editable: false,
									selected: true,
								})
							}
						/>
						<div className="radio__item" />
						<span className="radio__text">Read Only</span>
					</label>
				</div>
				<div className="radio">
					<label className="radio__label">
						<Field
							className="hidden"
							type="radio"
							name={`permissions[${idx}]:{id:${values?.id},editable:true,selected:true}`}
							value="true"
							checked={values?.editable === true && values?.selected === true}
							onChange={() =>
								setFieldValue(`permissions[${idx}]`, {
									id: values?.id,
									editable: true,
									selected: true,
								})
							}
						/>
						<div className="radio__item" />
						<span className="radio__text">Editable</span>
					</label>
				</div>
				<div className="radio">
					<label className="radio__label">
						<Field
							className="hidden"
							type="radio"
							name={`permissions[${idx}]:{id:${values?.id},editable:false,selected:false}`}
							value="selected"
							checked={values?.selected === false}
							onChange={() =>
								setFieldValue(`permissions[${idx}]`, {
									id: values?.id,
									editable: false,
									selected: false,
								})
							}
						/>
						<div className="radio__item" />
						<span className="radio__text">Not selected</span>
					</label>
				</div>
			</div>
		</div>
	);
};

export default PermissionListItem;
