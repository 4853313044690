import { IP2PTradeChatResponse, IP2PTradeChatMessgeResponse } from 'redux/reducers/chat/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IChatApi } from './types';
// import { initialState } from '../../../redux/reducers/orders/reducer';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
// ==========================================:
export const chat: IChatApi = {
	getP2PChats: ({ trade_id }) =>
		http
			.get<IP2PTradeChatResponse>(endpoint.p2pChat.GET_P2p_CHATS(trade_id))
			.then((response) => response.data),
	postP2PChatsUserSend: (payload) =>
		http
			.post<IP2PTradeChatMessgeResponse>(endpoint.p2pChat.POST_MESSAGE_SELLER, payload)
			.then((response) => response.data),
	postChatMessagesSeller: (payload) =>
		http
			.post<IP2PTradeChatMessgeResponse>(endpoint.p2pChat.POST_MESSAGE_SELLER, payload)
			.then((response) => response.data),
	postChatMessagesBuyer: (payload) =>
		http
			.post<IP2PTradeChatMessgeResponse>(endpoint.p2pChat.POST_MESSAGE_BUYER, payload)
			.then((response) => response.data),
	postP2PChatsAdminSend: (payload) =>
		http
			.post<IP2PTradeChatMessgeResponse>(endpoint.p2pChat.POST_MESSAGE_BUYER, payload)
			.then((response) => response.data),
};
