import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import L from 'i18n-react';
import { notificationContainer } from 'utils/notificationContainer';
import { api, responseErrors } from 'services';
import { loginRequest, loginSuccess, logoutRequest, authInitState } from './reducer';
import { IAdminAuthUserResponseData, ILoginRequestPayload, IAdminLogoutData } from './types';

// =============================================================:
function* loginRequestWorker(action: PayloadAction<ILoginRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IAdminAuthUserResponseData = yield call(api.auth.login, payload);

		yield put(loginSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			if (error?.response?.data?.errors?.totp) {
				const code: string = error?.response?.data?.errors?.totp[0];
				notificationContainer(String(L.translate(`Errors.${code}`)), 'info');
				yield put(authInitState());
			} else {
				responseErrors(error);
				yield put(authInitState());
			}
		}
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* logoutRequestWorker(action: PayloadAction<IAdminLogoutData>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.auth.logout);
		yield put(authInitState());
		payload.history.push({
			search: '',
		});
	} catch (error) {
		if (axios.isAxiosError(error)) responseErrors(error);
		yield put(authInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* authSaga() {
	yield takeEvery(loginRequest.type, loginRequestWorker);
	yield takeEvery(logoutRequest.type, logoutRequestWorker);
}
