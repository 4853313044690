import { FC } from 'react';

export const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Contract</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>QTY</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Avg. Entry Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Mark Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Unrealized P&L (ROI)</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Realized P&L</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Initial Margin</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Maintenance Margin</p>
					</div>
				</div>
			</div>
		</div>
	);
};
