import { FC } from 'react';
import { ActionsButtons } from './ActionButtons';
import { IWalletItemProps } from './types';

export const WalletItem: FC<IWalletItemProps> = ({ data }) => {
	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>
					{`${data.asset_code.toUpperCase()}
					${data.asset_code !== data.network_name ? ` (${data.network_name.toUpperCase()})` : ''}`}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Balance</p>
				<p>{data.balance}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Copy Url / Withdrawal</p>
				<ActionsButtons
					addressHot={data.hot_wallet_address}
					asset_id={data.asset_id}
					addressCold={data.cold_wallet_address}
					network={data.network_id}
				/>
			</div>
		</div>
	);
};
