import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IWithdrawalsData, IWithdrawalsStore } from './types';

// ==========================================:
const getWithdrawalsState = (state: IStoreState): IWithdrawalsStore => state.withdrawals;
export const getWithdrawals = createSelector(
	[getWithdrawalsState],
	(withdrawals: IWithdrawalsStore) => withdrawals,
);

// ====================================================:
export const getWithdrawalsIsLoad = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): boolean => withdrawals.withdrawalsLoader,
);

// ====================================================:
export const getWithdrawalsList = createSelector(
	[getWithdrawals],
	(withdrawals: IWithdrawalsStore): IWithdrawalsData => withdrawals.withdrawals,
);
