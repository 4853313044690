/* eslint-disable react-hooks/exhaustive-deps */

import { FC, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Pagination from 'ui/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminManagementList } from 'redux/reducers/adminManagement/selectors';
import { IAdminsListRequestPayload } from 'redux/reducers/adminManagement/types';
import { getAdminsListRequest } from 'redux/reducers/adminManagement/reducer';
import AdminManagementList from './AdminManagementList';

const AdminManagement: FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	useEffect(() => {
		setCurrentPage(Number(page) || 1);
	}, [page]);
	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const params: IAdminsListRequestPayload = {
			current_page: pageNumber || 1,
		};
		history.push({
			pathname: location.pathname,
			search: `?page=${String(pageNumber)}`,
		});
		dispatch(getAdminsListRequest(params));
	};

	useEffect(() => {
		setCurrentPage(1);
		const params: IAdminsListRequestPayload = {
			current_page: 1,
		};
		history.push({
			pathname: location.pathname,
			search: `?page=${String(1)}`,
		});
		dispatch(getAdminsListRequest(params));
	}, []);

	const adminList = useSelector(getAdminManagementList);

	return (
		<>
			<div className="panel panel--margin-none">
				<div className="title-block">
					<p className="title">Admin Management</p>
				</div>
				<Link to="/admin-management/new-admin" className="admin-managment-btn">
					<span className="admin-managment-btn__text">Add New Admin</span>
					<span className="plus-icon plus-icon--action icon-plus-icon" />
				</Link>
			</div>
			<AdminManagementList users={adminList?.data.users} />

			{(adminList?.data.last_page ?? 1) > 1 && (
				<Pagination
					pageCount={adminList?.data.last_page ?? 1}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};

export default AdminManagement;
