/* eslint-disable react/button-has-type */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { IAdminManagementListItemProps } from './types';
import { ActionsButtons } from './ActionButtons';

// ==========================================:
export const WithdrawalItem: FC<IAdminManagementListItemProps> = ({ data, handleClearSearch }) => {
	const date = new Date(data.created_at * 1000);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{data.id}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">User Id</p>
				<Link className="td" to={`/user-management/${String(data.user_id)}`}>
					<p className="td-hidden-name">Id</p>
					<p>{data.user_id}</p>
				</Link>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{data.asset_code.toUpperCase()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Network</p>
				<p>{data.network}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Value</p>
				<p>{Number(data.amount)}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee, %</p>
				<p>{Number(data.fee)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Created</p>
				<p>
					{date.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{date.toLocaleTimeString()}
				</p>
			</div>
			<div className="td td--reason">
				<p className="td-hidden-name">Message</p>
				<p className="reason-text">{data?.message?.replace(/_/g, ' ')}</p>
				<div className="reason-subtitle">
					<span className="reason-subtitle__descr">{data?.message?.replace(/_/g, ' ')}</span>
				</div>
			</div>

			<div className="td">
				<p className="td-hidden-name">Action</p>
				<ActionsButtons id={data.id} handleClearSearch={handleClearSearch} />
			</div>
		</div>
	);
};
