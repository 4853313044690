import { notificationContainer } from 'utils/notificationContainer';
import L from 'i18n-react';
// ==========================================:
const customResponseErrors = (errorMessage: string, type: string, response = '') => {
	const number = response.replace(/[^0-9]/g, '');
	const errorMessageNew = `${String(
		L.translate(`Errors.${response.replace(/[0-9]/g, '')}`),
	)} ${number}.`;
	const maxAmountMustBeLowerOrEqual = 'max_amount_must_be_lower_or_equal_';
	const minAmountMustBeGreaterOrEqual = 'min_amount_must_be_greater_or_equal_';
	if (response.toLowerCase().includes(maxAmountMustBeLowerOrEqual)) {
		notificationContainer(errorMessageNew, type);
	} else if (response.toLowerCase().includes(minAmountMustBeGreaterOrEqual)) {
		notificationContainer(errorMessageNew, type);
	} else {
		notificationContainer(errorMessage, type);
	}
};

export { customResponseErrors };
