import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IDispute } from './types';

// ==========================================:
export const dispute: IDispute = {
	getDispute: (params) =>
		http.get(endpoint.dispute.disputeP2P, { params }).then((response) => response.data),

	getDetails: (disputeId) =>
		http.get(endpoint.dispute.getDetails(disputeId)).then(({ data }) => data),

	accept: (disputeId) => http.put(endpoint.dispute.accept(disputeId)).then(({ data }) => data),

	reject: (disputeId) => http.put(endpoint.dispute.reject(disputeId)).then(({ data }) => data),
};
