import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import {
	IApiGetFuturesAllOrdersParams,
	TApiGetFuturesAllOrdersResponse,
} from 'services/api/orders/types';
import {
	getFuturesAllOrdersError,
	getFuturesAllOrdersRequest,
	getFuturesAllOrdersSuccess,
	getOrdersMarginRequest,
	getOrdersMarginSuccess,
	getOrdersOptionsRequest,
	getOrdersOptionsSuccess,
	getOrdersSpotRequest,
	getOrdersSpotSuccess,
} from './reducer';
import { IMarketMakerPageRequestPayload, IOrdersResponsePayload } from './types';

function* spotOrdersRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.orders.getOrdersSpot, payload);
		yield put(getOrdersSpotSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* marginOrdersRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.orders.getOrdersMargin, payload);
		yield put(getOrdersMarginSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* getFuturesAllOrdersWorker({ payload }: PayloadAction<IApiGetFuturesAllOrdersParams>) {
	try {
		const data: TApiGetFuturesAllOrdersResponse = yield call(
			api.orders.getFuturesAllOrders,
			payload,
		);
		yield put(getFuturesAllOrdersSuccess(data));
	} catch (error) {
		yield put(getFuturesAllOrdersError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOrdersOptionsWorker({ payload }: PayloadAction<IApiGetFuturesAllOrdersParams>) {
	try {
		const data: TApiGetFuturesAllOrdersResponse = yield call(api.orders.getOrdersOptions, payload);
		yield put(getOrdersOptionsSuccess(data));
	} catch (error) {
		yield put(getFuturesAllOrdersError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
export function* ordersSaga() {
	yield takeEvery(getOrdersSpotRequest.type, spotOrdersRequestWorker);
	yield takeEvery(getOrdersMarginRequest.type, marginOrdersRequestWorker);
	yield takeLatest(getFuturesAllOrdersRequest, getFuturesAllOrdersWorker);
	yield takeLatest(getOrdersOptionsRequest.type, getOrdersOptionsWorker);
}
