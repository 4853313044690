/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';
import { IRepaymentHeaderProps } from './types';

export const RepaymentTableHeader: FC<IRepaymentHeaderProps> = ({ pair }) => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>
				{pair === 'Isolated' ? (
					<div className="td td">
						<div className="td-name">
							<p>Pair</p>
						</div>
					</div>
				) : null}
				<div className="td td">
					<div className="td-name">
						<p>Coin</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Date</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Principal Amount</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Interest</p>
					</div>
				</div>
				<div className="td td--right">
					<div className="td-name">
						<p>Total</p>
					</div>
				</div>
			</div>
		</div>
	);
};
