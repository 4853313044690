/* eslint-disable no-prototype-builtins */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDerivativeLimitsRequest } from 'redux/reducers/limitations/reducer';
import {
	getDerivativeLimitationsList,
	getDerivativeLimitsLoader,
} from 'redux/reducers/limitations/selectors';
import Loader from 'ui/Loader';
import { DerivativeLimitItem } from './DerivativeItem/index';

const DerivativeLimitsTab = () => {
	const derivativeLimitations = useSelector(getDerivativeLimitationsList);
	const limitationsLoader = useSelector(getDerivativeLimitsLoader);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getDerivativeLimitsRequest());
	}, [dispatch]);

	const renderItems = () => {
		if (derivativeLimitations) {
			if ('map' in derivativeLimitations) {
				return derivativeLimitations.map((item) => (
					<DerivativeLimitItem key={item.id} data={item} />
				));
			}
			return <DerivativeLimitItem data={derivativeLimitations} />;
		}
		return null;
	};

	return (
		<div className="table table-limitation--withdrawal">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Currency name</p>
						</div>
					</div>
					<div className="td td--center">
						<div className="td-name">
							<p>Limit min</p>
						</div>
					</div>
					<div className="td td--center">
						<div className="td-name">
							<p />
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name td-name--action">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{limitationsLoader ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<div className="table-body">{renderItems()}</div>
				)}
			</div>
		</div>
	);
};

export default DerivativeLimitsTab;
