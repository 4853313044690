import { FC } from 'react';

export const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Contract</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Direction</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Filled Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>QTY</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Implied Volatility</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Underlying Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Mark Price</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fees</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Order Time</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Trade Time</p>
					</div>
				</div>
			</div>
		</div>
	);
};
