import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeFiatFeesRequest } from 'redux/reducers/fees/reducer';
import { IChangeFiatFeesPayload } from 'redux/reducers/fees/types';
import { numberFormat8Fraction } from 'utils/numberFormat';
import { IFiatFeesItemProps } from './types';

export const FiatFeesItem: FC<IFiatFeesItemProps> = ({ item }) => {
	const [deposit, setDeposit] = useState(numberFormat8Fraction(item.deposit_fee, 'en-En'));
	const [withdrawal, setWithdrawal] = useState(numberFormat8Fraction(item.withdraw_fee, 'en-En'));
	const [isClosed, setIsClosed] = useState(false);
	const dispatch = useDispatch();

	const handleFiatFeesSubmit = (payload: IChangeFiatFeesPayload) => {
		if (deposit === '' || withdrawal === '') return;
		dispatch(changeFiatFeesRequest(payload));
	};

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p>{item.code.toUpperCase()}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Deposit, %</p>
				{isClosed ? <div /> : <p>{deposit}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={deposit}
						onChange={(e) => {
							setDeposit(e.target.value.replace(/[^\d\\.]/g, ''));
						}}
					/>
				</div>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Withdrawal, %</p>
				{isClosed ? <div /> : <p>{withdrawal}</p>}
				<div className={`table-input ${isClosed ? '' : 'table-input--disable'}`}>
					<input
						type="text"
						value={withdrawal}
						onChange={(e) => setWithdrawal(e.target.value.replace(/[^\d\\.]/g, ''))}
					/>
				</div>
			</div>

			<div className="td">
				<p className="td-hidden-name">Edit</p>
				<div className="table-buttons">
					<button
						type="submit"
						className={`${isClosed ? '' : 'disable'}`}
						onClick={() => {
							setDeposit(numberFormat8Fraction(item.deposit_fee, 'en-En'));
							setWithdrawal(numberFormat8Fraction(item.withdraw_fee, 'en-En'));
							setIsClosed(!isClosed);
						}}
					>
						<span className="edit-icon icon-cancel-icon" />
					</button>
					{isClosed ? (
						<button
							type="button"
							onClick={() => {
								handleFiatFeesSubmit({
									asset_id: item.asset_id,
									deposit_fee: Number(deposit),
									withdraw_fee: Number(withdrawal),
								});
							}}
							className={deposit === '' || withdrawal === '' ? 'button-not-active' : ''}
						>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.19995 8.9001L6.59995 14.3001L16.8 4.1001"
									stroke="#00DA83"
									strokeLinecap="square"
								/>
							</svg>
						</button>
					) : (
						<button type="submit" onClick={() => setIsClosed(!isClosed)}>
							<span className="edit-icon icon-edit-icon" />
						</button>
					)}
				</div>
			</div>
		</div>
	);
};
