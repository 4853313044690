import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoad } from 'redux/reducers/auth/selectors';
import { loginRequest } from 'redux/reducers/auth/reducer';
import { ILoginSubmitValue } from 'components/Forms/LoginForm/types';
import Auth from 'layouts/Auth';
import LoginForm from 'components/Forms/LoginForm';
import AuthLogo from 'assets/img/logo.png';
import Loader from 'ui/Loader';

// ==========================================:
const Login: FC = () => {
	const isLoad = useSelector(getIsLoad);
	const dispatch = useDispatch();

	const handleLoginSubmit = (value: ILoginSubmitValue) => {
		dispatch(loginRequest(value));
	};

	return (
		<Auth title="Login">
			<section className="authorization-section">
				<div className="authorization-form">
					<div className="d-flex authorization-form__logo">
						<img src={AuthLogo} alt="auth logo" />
					</div>
					<p className="form-title">Sign in Backoffice</p>

					<LoginForm loginSubmit={handleLoginSubmit} />
					{isLoad && (
						<div className="auth-loader-wrapper">
							<Loader />
						</div>
					)}
				</div>
			</section>
		</Auth>
	);
};

export default Login;
