import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	INotificationsStore,
	INotificationsResponsePayload,
	IEditNotificationPayload,
	INotificationRequestPayload,
} from './types';

export const initialState: INotificationsStore = {
	all_notifications: null,
	notificationsLoader: false,
	editNotificationLoader: false,
};

// ==========================================:
const notifications = createSlice({
	name: '@@notifications',
	initialState,
	reducers: {
		getNotificationsRequest: (state, action: PayloadAction<INotificationRequestPayload>) => {
			const notificationsRequestState = state;
			notificationsRequestState.notificationsLoader = true;
		},
		getNotificationsSuccess: (state, action: PayloadAction<INotificationsResponsePayload>) => {
			const { payload } = action;
			const notificationsSuccessState = state;
			notificationsSuccessState.all_notifications = payload;
			notificationsSuccessState.notificationsLoader = false;
		},
		editNotificationsRequest: (state, action: PayloadAction<IEditNotificationPayload>) => {
			const editNotificationState = state;

			editNotificationState.editNotificationLoader = true;
		},
		editNotificationsSuccess: (state) => {
			const editNotificationState = state;

			editNotificationState.editNotificationLoader = false;
		},
		notificationsInitState: () => initialState,
	},
});

export default notifications.reducer;
export const {
	getNotificationsSuccess,
	notificationsInitState,
	getNotificationsRequest,
	editNotificationsRequest,
	editNotificationsSuccess,
} = notifications.actions;
