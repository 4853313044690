import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';

import { ICryptoDepositProps } from './types';

const Transfers: FC<ICryptoDepositProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [coin] = useState(item.asset.code);
	const [amount] = useState(item.amount);
	const [created] = useState(item.created_at);
	const date = new Date(created * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+created + offset) * 1000);
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Asset</p>
				<p style={{ textTransform: 'uppercase' }}>{coin}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Value</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateOffset.toLocaleTimeString()}
				</p>
			</div>
		</div>
	);
};
export default Transfers;
