/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { getParameterByName } from 'utils/paramNames';
import {
	TApiGetUserBalancesResponse,
	IApiGetOrderHistoryParams,
	TApiGetOrderHistoryResponse,
	IApiGetOneUserResponse,
	IApiGetFuturesUserOrderHistoryParams,
	TApiGetFuturesUserOrderHistoryResponse,
	TApiGetFuturesUserPositionsResponse,
	TApiGetFuturesUserBalancesResponse,
	IApiGetUserTransactionsCryptoParams,
	TApiGetUserTransactionsCryptoResponse,
	IApiGetOptionsPositionsByUserParams,
	TApiGetOptionsPositionsByUserResponse,
	IApiGetOptionsOrdersHistoryByUserParams,
	TApiGetOptionsOrdersHistoryByUserResponse,
	IApiGetOptionsTradesHistoryByUserParams,
	TApiGetOptionsTradesHistoryByUserResponse,
	IApiGetOptionsDeliveryByUserParams,
	TApiGetOptionsDeliveryByUserResponse,
	TApiGetOptionsLiquidationHistoryByUser,
} from 'services/api/users/types';

import {
	getUserBalancesRequest,
	getUserBalancesSuccess,
	getUsersRequest,
	getUsersSuccess,
	usersInitState,
	setUserBlockUnblockSuccess,
	setUserBlockUnblockRequest,
	get2FASuccess,
	get2FARequest,
	reset2FA,
	getUserSuccess,
	getUserRequest,
	reset2FASuccess,
	reject2FASuccess,
	reject2FA,
	getWhitelistIpSuccess,
	getWhitelistIpRequest,
	removeWhitelistIpSuccess,
	removeWhitelistIpRequest,
	verifyWhitelistIpSuccess,
	verifyWhitelistIpRequest,
	getLoginHistorySuccess,
	getLoginHistoryRequest,
	getDepositAndWithdrawHistorySuccess,
	getDepositAndWithdrawHistoryRequest,
	getUserOrdersHistoryRequest,
	getUserOrdersHistorySuccess,
	getUserMarginOrdersHistoryRequest,
	getUserMarginOrdersHistorySuccess,
	getUserMarginBorrowingRequest,
	getUserMarginBorrowingSuccess,
	getUserMarginRepaymentRequest,
	getUserMarginRepaymentSuccess,
	getUserMarginInterestRequest,
	getUserMarginInterestSuccess,
	getUserMarginLiquidationRequest,
	getUserMarginLiquidationSuccess,
	getUserMarginBalancesSuccess,
	getUserMarginBalancesRequest,
	getUserMarginMarginCallRequest,
	getUserMarginMarginCallSuccess,
	getUserCryptoDepositRequest,
	getUserCryptoDepositSuccess,
	getUserFiatDepositRequest,
	getUserFiatDepositSuccess,
	getUserConvertsRequest,
	getUserConvertsSuccess,
	getUserTransfersRequest,
	getUserTransfersSuccess,
	getFuturesUserOrderHistorySuccess,
	getFuturesUserOrderHistoryError,
	getFuturesUserOrderHistoryRequest,
	getFuturesUserPositionsSuccess,
	getFuturesUserPositionsError,
	getFuturesUserPositionsRequest,
	getFuturesUserBalancesRequest,
	getFuturesUserBalancesSuccess,
	getFuturesUserTradeHistoryRequest,
	getFuturesUserTradeHistorySuccess,
	getFuturesUserMarkPriceRequest,
	getFuturesUserMarkPriceSuccess,
	getFuturesUserLiquidationHistoryRequest,
	getFuturesUserLiquidationHistorySuccess,
	getOptionsPositionsByUserSuccess,
	getOptionsPositionsByUserError,
	getOptionsPositionsByUserRequest,
	getOptionsOrdersHistoryByUserSuccess,
	getOptionsOrdersHistoryByUserError,
	getOptionsOrdersHistoryByUserRequest,
	getOptionsTradesHistoryByUserSuccess,
	getOptionsTradesHistoryByUserError,
	getOptionsDeliveryByUserSuccess,
	getOptionsDeliveryByUserError,
	getOptionsTradesHistoryByUserRequest,
	getOptionsDeliveryByUserRequest,
	getOptionsLiquidationHistoryByUserSuccess,
	getOptionsLiquidationHistoryByUserRequest,
} from './reducer';
import {
	IUsersResponsePayload,
	IUsersListRequestPayload,
	IUserBalancesRequestPayload,
	IUserBalancesResponsePayload,
	IUserBlockUnblockRequestPayload,
	I2FAListRequestPayload,
	I2FAResponsePayload,
	IUserRequestPayload,
	I2FAResetPayload,
	I2FARejectPayload,
	IWhitelistIpListRequestPayload,
	IWhitelistIpListResponsePayload,
	IWhitelistIpRemoveRequestPayload,
	IWhitelistIpRemoveResponsePayload,
	IWhitelistIpVerifyRequestPayload,
	IWhitelistIpVerifyResponsePayload,
	ILoginHistoryRequestPayload,
	ILoginHistoryResponsePayload,
	IDepositAndWithdrawHistoryRequestPayload,
	IDepositAndWithdrawHistoryResponsePayload,
	IMarginBorrowingPayload,
	TApiGetFuturesUserTradeHistoryResponse,
	TApiGetFuturesUserMarkPriceResponse,
	TApiGetFuturesUserLiquidationHistoryResponse,
} from './types';

function* usersRequestWorker(action: PayloadAction<IUsersListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IUsersResponsePayload = yield call(api.users.getUsers, payload);
		yield put(getUsersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userRequestWorker(action: PayloadAction<IUserRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const data: IApiGetOneUserResponse = yield call(api.users.getUser, payload);

		yield put(getUserSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userBalancesRequestWorker(action: PayloadAction<IUserBalancesRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const data: TApiGetUserBalancesResponse = yield call(api.users.getBalances, payload);

		yield put(getUserBalancesSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userOrdersHistoryRequestWorker(action: PayloadAction<IApiGetOrderHistoryParams>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const data: TApiGetOrderHistoryResponse = yield call(api.users.getOrdersHistory, payload);

		yield put(getUserOrdersHistorySuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userOrdersHistoryMarginRequestWorker(action: PayloadAction<IApiGetOrderHistoryParams>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const data: TApiGetOrderHistoryResponse = yield call(api.users.getOrdersHistory, payload);

		yield put(getUserMarginOrdersHistorySuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* whitelistIpRequestWorker(action: PayloadAction<IWhitelistIpListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IWhitelistIpListResponsePayload = yield call(api.users.getWhitelistIp, payload);

		yield put(getWhitelistIpSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* whitelistIpRemoveRequestWorker(action: PayloadAction<IWhitelistIpRemoveRequestPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		const response: IWhitelistIpRemoveResponsePayload = yield call(
			api.users.removeWhitelistIp,
			payload,
		);

		yield put(removeWhitelistIpSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWhitelistIpRequest({ id: String(payload.userId), current_page: pageNumber }));
	}
}

// =============================================================:
function* whitelistIpVerifyRequestWorker(action: PayloadAction<IWhitelistIpVerifyRequestPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		const response: IWhitelistIpVerifyResponsePayload = yield call(
			api.users.verifyWhitelistIp,
			payload,
		);

		yield put(verifyWhitelistIpSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
		yield put(getWhitelistIpRequest({ id: String(payload.userId), current_page: pageNumber }));
	}
}

function* userBlockUnblockRequestWorker(action: PayloadAction<IUserBlockUnblockRequestPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());

		yield call(api.users.setUserBlockUnblock, payload);
		yield put(setUserBlockUnblockSuccess());

		yield put(getUsersRequest({ current_page: pageNumber }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* twoFARequestWorker(action: PayloadAction<I2FAListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: I2FAResponsePayload = yield call(api.users.get2FA, payload);
		yield put(get2FASuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* twoFAResetRequestWorker(action: PayloadAction<I2FAResetPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		yield call(api.users.reset2FA, payload);
		yield put(reset2FASuccess());
		yield put(get2FARequest({ current_page: pageNumber }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(get2FARequest({ current_page: pageNumber }));
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* twoFARejectRequestWorker(action: PayloadAction<I2FARejectPayload>) {
	const { payload } = action;
	const pageNumber = getParameterByName('page');

	try {
		yield put(showLoading());
		yield call(api.users.reject2FA, payload);
		yield put(reject2FASuccess());
		yield put(get2FARequest({ current_page: pageNumber }));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(get2FARequest({ current_page: pageNumber }));
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* loginHistoryRequestWorker(action: PayloadAction<ILoginHistoryRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ILoginHistoryResponsePayload = yield call(api.users.getLoginHistory, payload);

		yield put(getLoginHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* depositAndWithdrawHistoryRequestWorker(
	action: PayloadAction<IDepositAndWithdrawHistoryRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IDepositAndWithdrawHistoryResponsePayload = yield call(
			api.users.getDepositAndWithdrawHistory,
			payload,
		);

		yield put(getDepositAndWithdrawHistorySuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userBorrowingMarginRequestWorker({ payload }: PayloadAction<any>) {
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBorrowingMarginCross,
				payload,
			);
			yield put(getUserMarginBorrowingSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBorrowingMarginIsolated,
				payload,
			);
			yield put(getUserMarginBorrowingSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userRepaymentMarginRequestWorker({ payload }: PayloadAction<any>) {
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getRepaymentMarginCross,
				payload,
			);
			yield put(getUserMarginRepaymentSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getRepaymentMarginIsolated,
				payload,
			);
			yield put(getUserMarginRepaymentSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userInterestMarginRequestWorker({ payload }: PayloadAction<any>) {
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getInterestMarginCross,
				payload,
			);
			yield put(getUserMarginInterestSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getInterestMarginIsolated,
				payload,
			);
			yield put(getUserMarginInterestSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userLiquidationMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getLiquidationMarginCross,
				payload,
			);
			yield put(getUserMarginLiquidationSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getLiquidationMarginIsolated,
				payload,
			);
			yield put(getUserMarginLiquidationSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userBalancesMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBalancesMarginCross,
				payload,
			);
			yield put(getUserMarginBalancesSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getBalancesMarginIsolated,
				payload,
			);
			yield put(getUserMarginBalancesSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userMarginCallMarginRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		if (payload.options === 'Cross') {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getMarginCallMarginCross,
				payload,
			);
			yield put(getUserMarginMarginCallSuccess(response));
		} else {
			const response: IUserBalancesResponsePayload = yield call(
				api.users.getMarginCallMarginIsolated,
				payload,
			);
			yield put(getUserMarginMarginCallSuccess(response));
		}
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userCryptoDepositRequestWorker({
	payload,
}: PayloadAction<IApiGetUserTransactionsCryptoParams>) {
	try {
		yield put(showLoading());
		const data: TApiGetUserTransactionsCryptoResponse = yield call(
			api.users.getCryptoDeposit,
			payload,
		);
		yield put(getUserCryptoDepositSuccess(data));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userFiatDepositRequestWorker({ payload }: PayloadAction<any>) {
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getFiatDeposit, payload);
		yield put(getUserFiatDepositSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

function* userConvertsRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getConverts, payload);
		yield put(getUserConvertsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}
function* userTransfersRequestWorker(action: PayloadAction<IMarginBorrowingPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IUserBalancesResponsePayload = yield call(api.users.getTransfers, payload);
		yield put(getUserTransfersSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(usersInitState());
	} finally {
		yield put(hideLoading());
	}
}

// 📌 Futures

function* getFuturesUserOrderHistoryWorker({
	payload,
}: PayloadAction<IApiGetFuturesUserOrderHistoryParams>) {
	try {
		const data: TApiGetFuturesUserOrderHistoryResponse = yield call(
			api.users.getFuturesUserOrderHistory,
			payload,
		);
		yield put(getFuturesUserOrderHistorySuccess(data));
	} catch (error) {
		yield put(getFuturesUserOrderHistoryError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getFuturesPositionsWorker({
	payload,
}: PayloadAction<IApiGetFuturesUserOrderHistoryParams>) {
	try {
		const data: TApiGetFuturesUserPositionsResponse = yield call(
			api.users.getFuturesUserPositions,
			payload,
		);
		yield put(getFuturesUserPositionsSuccess(data));
	} catch (error) {
		yield put(getFuturesUserPositionsError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getFuturesBalancesWorker({
	payload,
}: PayloadAction<IApiGetFuturesUserOrderHistoryParams>) {
	try {
		const data: TApiGetFuturesUserBalancesResponse = yield call(
			api.users.getFuturesUserBalances,
			payload,
		);
		yield put(getFuturesUserBalancesSuccess(data));
	} catch (error) {
		yield put(getFuturesUserPositionsError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getFuturesTradeHistoryWorker({
	payload,
}: PayloadAction<IApiGetFuturesUserOrderHistoryParams>) {
	try {
		const data: TApiGetFuturesUserTradeHistoryResponse = yield call(
			api.users.getFuturesUserTradeHistory,
			payload,
		);
		yield put(getFuturesUserTradeHistorySuccess(data));
	} catch (error) {
		yield put(getFuturesUserPositionsError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}
function* getFuturesMarkPriceWorker({
	payload,
}: PayloadAction<IApiGetFuturesUserOrderHistoryParams>) {
	try {
		const data: TApiGetFuturesUserMarkPriceResponse = yield call(
			api.users.getFuturesUserBaseAssetStatistics,
			payload,
		);
		yield put(getFuturesUserMarkPriceSuccess(data));
	} catch (error) {
		yield put(getFuturesUserPositionsError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getFuturesLiquidationHistoryWorker({
	payload,
}: PayloadAction<IApiGetFuturesUserOrderHistoryParams>) {
	try {
		const data: TApiGetFuturesUserLiquidationHistoryResponse = yield call(
			api.users.getFuturesUserLiquidationHistory,
			payload,
		);
		yield put(getFuturesUserLiquidationHistorySuccess(data));
	} catch (error) {
		yield put(getFuturesUserPositionsError());
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

// 📌 User details / Options

function* getOptionsPositionsByUserWorker({
	payload,
}: PayloadAction<IApiGetOptionsPositionsByUserParams>) {
	try {
		const data: TApiGetOptionsPositionsByUserResponse = yield call(
			api.users.getOptionsPositionsByUser,
			payload,
		);
		yield put(getOptionsPositionsByUserSuccess(data));
	} catch (error) {
		yield put(getOptionsPositionsByUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOptionsOrdersHistoryByUserWorker({
	payload,
}: PayloadAction<IApiGetOptionsOrdersHistoryByUserParams>) {
	try {
		const data: TApiGetOptionsOrdersHistoryByUserResponse = yield call(
			api.users.getOptionsOrdersHistoryByUser,
			payload,
		);
		yield put(getOptionsOrdersHistoryByUserSuccess(data));
	} catch (error) {
		yield put(getOptionsOrdersHistoryByUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOptionsTradesHistoryByUserWorker({
	payload,
}: PayloadAction<IApiGetOptionsTradesHistoryByUserParams>) {
	try {
		const data: TApiGetOptionsTradesHistoryByUserResponse = yield call(
			api.users.getOptionsTradesHistoryByUser,
			payload,
		);
		yield put(getOptionsTradesHistoryByUserSuccess(data));
	} catch (error) {
		yield put(getOptionsTradesHistoryByUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOptionsDeliveryByUserWorker({
	payload,
}: PayloadAction<IApiGetOptionsDeliveryByUserParams>) {
	try {
		const data: TApiGetOptionsDeliveryByUserResponse = yield call(
			api.users.getOptionsDeliveryByUser,
			payload,
		);
		yield put(getOptionsDeliveryByUserSuccess(data));
	} catch (error) {
		yield put(getOptionsDeliveryByUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

function* getOptionsLiquidationHistoryByUserWorker({
	payload,
}: PayloadAction<IApiGetOptionsDeliveryByUserParams>) {
	try {
		const data: TApiGetOptionsLiquidationHistoryByUser = yield call(
			api.users.getOptionsLiquidationHistoryByUser,
			payload,
		);
		yield put(getOptionsLiquidationHistoryByUserSuccess(data));
	} catch (error) {
		yield put(getOptionsDeliveryByUserError());

		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	}
}

export function* usersSaga() {
	yield takeEvery(getUsersRequest.type, usersRequestWorker);
	yield takeEvery(getUserRequest.type, userRequestWorker);
	yield takeEvery(getUserBalancesRequest.type, userBalancesRequestWorker);
	yield takeEvery(getUserOrdersHistoryRequest.type, userOrdersHistoryRequestWorker);
	yield takeEvery(getUserMarginOrdersHistoryRequest.type, userOrdersHistoryMarginRequestWorker);
	yield takeEvery(getWhitelistIpRequest.type, whitelistIpRequestWorker);
	yield takeEvery(removeWhitelistIpRequest.type, whitelistIpRemoveRequestWorker);
	yield takeEvery(verifyWhitelistIpRequest.type, whitelistIpVerifyRequestWorker);
	yield takeEvery(setUserBlockUnblockRequest.type, userBlockUnblockRequestWorker);
	yield takeEvery(get2FARequest.type, twoFARequestWorker);
	yield takeEvery(reset2FA.type, twoFAResetRequestWorker);
	yield takeEvery(reject2FA.type, twoFARejectRequestWorker);
	yield takeEvery(getLoginHistoryRequest.type, loginHistoryRequestWorker);
	yield takeEvery(getDepositAndWithdrawHistoryRequest.type, depositAndWithdrawHistoryRequestWorker);
	yield takeEvery(getUserMarginBorrowingRequest.type, userBorrowingMarginRequestWorker);
	yield takeEvery(getUserMarginRepaymentRequest.type, userRepaymentMarginRequestWorker);
	yield takeEvery(getUserMarginInterestRequest.type, userInterestMarginRequestWorker);
	yield takeEvery(getUserMarginLiquidationRequest.type, userLiquidationMarginRequestWorker);
	yield takeEvery(getUserMarginBalancesRequest.type, userBalancesMarginRequestWorker);
	yield takeEvery(getUserMarginMarginCallRequest.type, userMarginCallMarginRequestWorker);
	yield takeEvery(getUserCryptoDepositRequest.type, userCryptoDepositRequestWorker);
	yield takeEvery(getUserFiatDepositRequest.type, userFiatDepositRequestWorker);
	yield takeEvery(getUserConvertsRequest.type, userConvertsRequestWorker);
	yield takeEvery(getUserTransfersRequest.type, userTransfersRequestWorker);
	// Futures
	yield takeLatest(getFuturesUserOrderHistoryRequest, getFuturesUserOrderHistoryWorker);
	// Futures positions
	yield takeLatest(getFuturesUserPositionsRequest, getFuturesPositionsWorker);
	// Futures balances
	yield takeLatest(getFuturesUserBalancesRequest, getFuturesBalancesWorker);
	// Trade history
	yield takeLatest(getFuturesUserTradeHistoryRequest, getFuturesTradeHistoryWorker);
	// Feature mark price
	yield takeLatest(getFuturesUserMarkPriceRequest, getFuturesMarkPriceWorker);
	// Trade LiquidationHistory
	yield takeLatest(getFuturesUserLiquidationHistoryRequest, getFuturesLiquidationHistoryWorker);
	// User details / Options
	yield takeLatest(getOptionsPositionsByUserRequest, getOptionsPositionsByUserWorker);
	yield takeLatest(getOptionsOrdersHistoryByUserRequest, getOptionsOrdersHistoryByUserWorker);
	yield takeLatest(getOptionsTradesHistoryByUserRequest, getOptionsTradesHistoryByUserWorker);
	yield takeLatest(getOptionsDeliveryByUserRequest, getOptionsDeliveryByUserWorker);
	yield takeLatest(
		getOptionsLiquidationHistoryByUserRequest,
		getOptionsLiquidationHistoryByUserWorker,
	);
}
