import { FC } from 'react';

export const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>USER ID</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Contract</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Trade Type</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Order Price</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>QTY</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Avg. Filled Price</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Filled QTY</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Fees</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Order P&amp;L</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Cash Flow</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Order ID</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Order Type</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Time in Force</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Order Time</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Trade Time</p>
					</div>
				</div>
				<div className="td ">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
			</div>
		</div>
	);
};
