import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';
import { IInterestProps } from './types';

const InterestTableItem: FC<IInterestProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [pair] = useState(item?.pair?.code);
	const [coin] = useState(item.asset.code);
	const date = new Date(item.date * 1000);

	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			{pair ? (
				<div className="td td--left">
					<p className="td-hidden-name">Pair</p>
					<p style={{ textTransform: 'uppercase' }}>{pair.replace('_', '/')}</p>
				</div>
			) : null}
			<div className="td td--left">
				<p className="td-hidden-name">Coin</p>
				<p style={{ textTransform: 'uppercase' }}>{coin}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Date</p>
				<p>
					{date.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{date.toLocaleTimeString()}
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{noExponent(Number(item.amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Daily Interest Rate</p>
				<p>{noExponent(item.daily_interest_rate)}</p>
			</div>
		</div>
	);
};
export default InterestTableItem;
