/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { getTradesSpotRequest } from 'redux/reducers/trade/reducer';
import { getLoadingTradesSpot, getTradesSpotList } from 'redux/reducers/trade/selectors';
import { SpotTradesTableHeader } from './SpotTradesTableHeader';
import { ISpotTradeItem, IMarginProps } from './types';
import SpotTradesTableItem from './SpotTradesTableItem';

export const SpotTrades: FC<IMarginProps> = ({ radioCheck }) => {
	const tradesSpotList = useSelector(getTradesSpotList);
	const tradesSpotIsLoad = useSelector(getLoadingTradesSpot);
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const history = useHistory();
	const location = useLocation();

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		const searchParams = `?page=${String(pageNumber)}`;
		const params = {
			per_page: 15,
			current_page: pageNumber || 1,
			order_type: 'spot',
		};
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		dispatch(getTradesSpotRequest(params));
	};

	useEffect(() => {
		const params = {
			per_page: 15,
			current_page: 1,
			order_type: 'spot',
		};
		setCurrentPage(1);
		history.push({
			pathname: location.pathname,
		});
		dispatch(getTradesSpotRequest(params));
	}, [radioCheck]);

	return (
		<>
			{tradesSpotIsLoad ? (
				<>
					{' '}
					<div className="list-loader">
						<Loader />
					</div>
				</>
			) : (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--spot-trades">
							<SpotTradesTableHeader />
							<div className="table-body">
								{tradesSpotList?.data.map((item: ISpotTradeItem) => (
									<SpotTradesTableItem item={item} key={item.id} />
								))}
								{tradesSpotList?.data.length === 0 ? (
									<p style={{ padding: '20px' }}>Borrowing is empty</p>
								) : null}
							</div>
						</div>
					</div>
					<div style={{ padding: '14px' }}>
						{(tradesSpotList?.last_page ?? 0) > 1 && (
							<Pagination
								pageCount={tradesSpotList?.last_page ?? 0}
								forcePage={currentPage - 1}
								onPageChange={handlePageChange}
							/>
						)}
					</div>
				</div>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
