import { FC } from 'react';
import { toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';

const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">USER ID</p>
				<p>{item.user_id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p>{item.asset_underlying.toUpperCase()}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order ID</p>
				<p>{item.transactions_type === 'buy' ? item.buy_order_id : item.sell_order_id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Size</p>
				<p>{item.amount_asset_filled}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Fill Price</p>
				<p>{toMaxDecimals(item.fill_price, 8)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Fee</p>
				<p>{Number(toMaxDecimals(item.fee_usdt, 8)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order PnL</p>
				<p>{toMaxDecimals(item.pnl_realized, 8)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Trade Type</p>
				<p>{item.transactions_type}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Created At</p>
				<p>{new Date(item.created_at).toLocaleString()}</p>
			</div>
		</div>
	);
};

export default TableItem;
