/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import classNames from 'classnames';
import { FC, useState } from 'react';
import { Delivery } from './Delivery/Delivery';
import { Liquidation } from './LiquidationHistory/Liquidation';
import { OrdersHistory } from './OrdersHistory/OrdersHistory';
import { Positions } from './Positions/Positions';
import { TradesHistory } from './TradesHistory/TradesHistory';

const TAB_NAMES = [
	'Positions',
	'Orders history',
	'Trades history',
	'Liquidation history',
	'Delivery',
];

export const Options: FC = () => {
	const [tabIndex, setTabIndex] = useState(0);

	return (
		<div className="margin">
			<div className="content-block content-block--user-management">
				<div className="content-block__inside content-block__inside--panel-only">
					<div className="table-panel">
						<ul className="table-panel-tab">
							{TAB_NAMES.map((tabName, index) => (
								<li
									key={index} // eslint-disable-line
									className={classNames('table-panel-tab__item', { active: tabIndex === index })}
									onClick={() => setTabIndex(index)}
								>
									<span className="table-panel-tab__link">{tabName}</span>
								</li>
							))}
						</ul>
					</div>
				</div>
				{tabIndex === 0 && <Positions />}
				{tabIndex === 1 && <OrdersHistory />}
				{tabIndex === 2 && <TradesHistory />}
				{tabIndex === 3 && <Liquidation />}
				{tabIndex === 4 && <Delivery />}
			</div>
		</div>
	);
};
