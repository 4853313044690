import { FC, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	getMarginIsolatedActionsLoading,
	getMarginIsolatedLoading,
	getReversedMarginIsolatedList,
} from 'redux/reducers/tradingPairs/selectors';
import { getMarginIsolatedRequest } from 'redux/reducers/tradingPairs/reducer';
import Loader from 'ui/Loader';
import { MarginIsolatedItem } from './MarginIsolatedItem';

export const MarginIsolatedPairs: FC = () => {
	const dispatch = useDispatch();
	const reversedList = useSelector(getReversedMarginIsolatedList);
	const listLoading = useSelector(getMarginIsolatedLoading);
	const actionsLoading = useSelector(getMarginIsolatedActionsLoading);

	useLayoutEffect(() => {
		if (!actionsLoading) {
			dispatch(getMarginIsolatedRequest());
		}
	}, [actionsLoading, dispatch]);

	return (
		<div className="table-block margin-isolated-pairs">
			<div className="table-wrapper">
				<div className="table">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Currency Pair</p>
								</div>
							</div>

							<div className="td td--center">
								<div className="td-name">
									<p>Isolated margin Leverage</p>
								</div>
							</div>

							<div className="td td--right">
								<div className="td-name">
									<p>Isolated margin status</p>
								</div>
							</div>

							<div className="td">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>

					<div className="table-body">
						{listLoading && (
							<div className="list-loader">
								<Loader />
							</div>
						)}

						{!listLoading && !!reversedList.length && (
							<div>
								{reversedList.map((item) => (
									<MarginIsolatedItem item={item} key={item.id} />
								))}
							</div>
						)}

						{!listLoading && !reversedList.length && (
							<div className="user-management-empty-data">
								<p className="user-management-empty__text">No trading pairs found...</p>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
