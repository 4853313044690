import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';
import { orderStatusClassNames, orderStatusNames } from './utils';

const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<Link to={`/user-management/${item.user_id}`}>
					<p className="td-hidden-name">USER ID</p>
					<p className="grey-text">{item.user_id}</p>
				</Link>
			</div>
			<div className="td">
				<p className="td-hidden-name">Contract</p>
				<p>{item.base_asset.toUpperCase()}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{item.id}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Creared At</p>
				<p>{new Date(item.created_at * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">End Time</p>
				<p>{new Date(item.updated_at * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Side</p>
				<p>{item.transactions_type}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Order Price</p>
				<p>
					{item.price_avarage_completed === null
						? '-'
						: toMaxDecimals(item.price_avarage_completed, 8)}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Size</p>
				<p>{item.amount_asset_request}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Order Filled</p>
				<p>{item.amount_asset_request_left}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Fill Price</p>
				<p>{toMaxDecimals(item.price_entry_for_order, 8)}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={classNames('status', orderStatusClassNames[item.status])}>
						{orderStatusNames[item.status]}
					</span>
				</div>
			</div>
		</div>
	);
};

export default TableItem;
