import { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFuturesTradesRequest } from 'redux/reducers/trade/reducer';
import { getFuturesTrades, getFuturesTradesLoading } from 'redux/reducers/trade/selectors';
import { IApiGetFuturesTradesParams } from 'services/api/trades/types';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { TableHeader } from './TableHeader/TableHeader';
import TableItem from './TableItem/TableItem';

export const FuturesTrades: FC = () => {
	const dispatch = useDispatch();

	const trades = useSelector(getFuturesTrades);
	const loading = useSelector(getFuturesTradesLoading);

	const [currentPage, setCurrentPage] = useState(1);

	useLayoutEffect(() => {
		const params: IApiGetFuturesTradesParams = {
			page: currentPage,
		};
		dispatch(getFuturesTradesRequest(params));
	}, [dispatch, currentPage]);

	return (
		<>
			{loading && (
				<div className="list-loader">
					<Loader />
				</div>
			)}

			{!loading && (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--futures-trades">
							<TableHeader />

							<div className="table-body">
								{trades?.data.orders.map((item) => (
									<TableItem item={item} key={item.order_id} />
								))}

								{!trades?.data.orders.length && <p style={{ padding: '20px' }}>List is empty</p>}
							</div>
						</div>
					</div>

					<div style={{ padding: '14px' }}>
						{(trades?.data.last_page || 0) > 1 && (
							<Pagination
								pageCount={trades?.data.last_page || 0}
								forcePage={currentPage - 1}
								onPageChange={setCurrentPage}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};
