import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Limitations from 'components/Limitations';

// ================================================:
const LimitationsPage: FC = () => {
	return (
		<Dashboard title="Limitations">
			<Limitations />
		</Dashboard>
	);
};

export default LimitationsPage;
