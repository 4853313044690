import { TApiGetFuturesAllOrdersStatus } from 'services/api/orders/types';

export const orderStatusNames: Record<TApiGetFuturesAllOrdersStatus, string> = {
	open: 'Opened',
	canceled: 'Canceled',
	filled: 'Filled',
	expired: 'Liquidated',
};

export const orderStatusClassNames: Record<TApiGetFuturesAllOrdersStatus, string> = {
	open: 'status--verified',
	canceled: 'status--error',
	filled: 'status--inprogres',
	expired: 'status--under-dispute',
};
