import React, { FC, useState } from 'react';
import { Formik } from 'formik';
import { IItemsProps } from './types';
/* eslint-disable @typescript-eslint/no-explicit-any */
function numberInputFix(value: any) {
	['e', 'E', '+', '-'].includes(value.key) && value.preventDefault();
}

export const TableItem: FC<IItemsProps> = ({ item, updateHandler, manage }) => {
	const currencyPair = item?.base_asset;
	const userOrders = item?.base_asset_id;
	const isActive = item?.is_active;
	const minPrice = item?.min_price_option;
	const minLot = item?.min_lot;
	const tradingFee = item?.max_proportion_transaction_option;
	const feeRateTaker = item?.taker_fee_option;
	const feeRateMaker = item?.maker_fee_option;
	const deliveryFee = item?.delivery_fee_option;
	const liquidationFeeRate = item?.liquidation_fee_option;
	const mmFactor = item?.mm_factor_option;
	const maxImFactor = item?.max_im_factor_option;
	const minImFactor = item?.min_im_factor_option;

	const [changeableMode, setChangeableMode] = useState<boolean>(false);
	const switchOnChangeableMode = () => {
		setChangeableMode(true);
	};

	const switchOffChangeableMode = () => {
		setChangeableMode(false);
	};
	const initialValues = {
		minPrice: minPrice || '',
		minLot: minLot || '',
		tradingFee: tradingFee || '',
		feeRateTaker: feeRateTaker || '',
		feeRateMaker: feeRateMaker || '',
		deliveryFeeRateDaily: 'NONE' || '',
		deliveryFeeOther: deliveryFee,
		liquidationFeeRate: liquidationFeeRate || '',
		liquidationFee: 'NONE',
		mmFactor: mmFactor || '',
		maxImFactor: maxImFactor || '',
		minImFactor: minImFactor || '',
	};
	return (
		<div>
			{manage && changeableMode ? (
				<Formik
					initialValues={initialValues}
					onSubmit={(values, actions) => {
						const data = {
							base_asset: currencyPair,
							is_active: isActive,
							min_price_option: Number(values.minPrice),
							min_lot: values.minLot,
							taker_fee_option: Number(values.feeRateTaker),
							maker_fee_option: Number(values.feeRateMaker),
							delivery_fee_option: Number(values.deliveryFeeOther),
							max_proportion_transaction_option: Number(values.tradingFee),
							liquidation_fee_option: Number(values.liquidationFeeRate),
							mm_factor_option: Number(values.mmFactor),
							max_im_factor_option: Number(values.maxImFactor),
							min_im_factor_option: Number(values.minImFactor),
						};
						actions.setSubmitting(false);
						updateHandler(data);
						switchOffChangeableMode();
					}}
				>
					{(props) => (
						<form className="tr" onSubmit={props.handleSubmit}>
							<div className="td">
								<p className="td-hidden-name">Currency</p>
								<p>{currencyPair?.toUpperCase()} </p>
							</div>
							<div className="td">
								<p className="td-hidden-name">User Orders</p>
								<p>{userOrders}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">User Positions</p>
								<p>{isActive}</p>
							</div>
							<div className="td">
								<p className="td-hidden-name">Min. Price</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="minPrice"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.minPrice}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Min. Qty</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="minLot"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.minLot}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Trading Fee, %</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="tradingFee"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.tradingFee}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Taker Fee, %</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="feeRateTaker"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.feeRateTaker}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Maker Fee, %</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="feeRateMaker"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.feeRateMaker}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Delivery Fee rate (Other)</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="deliveryFeeOther"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.deliveryFeeOther}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Liquidation Fee rate</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="liquidationFeeRate"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.liquidationFeeRate}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">MM factor</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="mmFactor"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.mmFactor}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Max IM factor</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="maxImFactor"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.maxImFactor}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Min IM factor</p>
								<input
									className="input-item input-item---fullWidth input-item--type-td"
									type="number"
									onKeyDown={(evt) => numberInputFix(evt)}
									name="minImFactor"
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									value={props.values.minImFactor}
									placeholder="0"
								/>
							</div>
							<div className="td">
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									<button type="button" onClick={switchOffChangeableMode}>
										<svg
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1.7998 1.7998L16.1998 16.1998M1.7998 16.1998L16.1998 1.7998"
												stroke="#E04141"
											/>
										</svg>
									</button>
									<button type="submit">
										<svg
											width="18"
											height="18"
											viewBox="0 0 18 18"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M1.2002 8.4001L6.60019 13.8001L16.8002 3.6001"
												stroke="#30C06E"
												strokeLinecap="square"
											/>
										</svg>
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			) : (
				<div className="tr">
					<div className="td">
						<p className="td-hidden-name">Currency</p>
						<p>{currencyPair?.toUpperCase()} </p>
					</div>
					<div className="td">
						<p className="td-hidden-name">User Orders</p>
						<p>{userOrders}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">User Positions</p>
						<p>{isActive}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Min. Price</p>
						<p>{minPrice}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Min. Qty</p>
						<p>{minLot}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Trading Fee</p>
						<p>{tradingFee}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Taker Fee</p>
						<p>{feeRateTaker}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Maker Fee</p>
						<p>{feeRateMaker}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Delivery Fee Other</p>
						<p>{deliveryFee}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Liquidation Fee rate</p>
						<p>{liquidationFeeRate}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">MM factor</p>
						<p>{mmFactor}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Max IM factor</p>
						<p>{maxImFactor}</p>
					</div>
					<div className="td">
						<p className="td-hidden-name">Min IM factor</p>
						<p>{minImFactor}</p>
					</div>
					<div className="td">
						{manage ? (
							<>
								<p className="td-hidden-name">Action</p>
								<div className="table-buttons flex-e">
									<button type="button" onClick={switchOnChangeableMode}>
										<svg
											width="18"
											height="19"
											viewBox="0 0 18 19"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M0.599609 11.9001L0.246056 11.5465L0.0996094 11.693V11.9001H0.599609ZM11.3996 1.1001L11.7532 0.746544C11.5579 0.551282 11.2413 0.551282 11.0461 0.746544L11.3996 1.1001ZM17.3996 7.1001L17.7532 7.45365C17.9484 7.25839 17.9484 6.94181 17.7532 6.74654L17.3996 7.1001ZM6.59961 17.9001V18.4001H6.80672L6.95316 18.2537L6.59961 17.9001ZM0.599609 17.9001H0.0996094C0.0996094 18.1762 0.323467 18.4001 0.599609 18.4001L0.599609 17.9001ZM0.953163 12.2537L11.7532 1.45365L11.0461 0.746544L0.246056 11.5465L0.953163 12.2537ZM11.0461 1.45365L17.0461 7.45365L17.7532 6.74654L11.7532 0.746544L11.0461 1.45365ZM17.0461 6.74654L6.24606 17.5465L6.95316 18.2537L17.7532 7.45365L17.0461 6.74654ZM6.59961 17.4001H0.599609V18.4001H6.59961V17.4001ZM1.09961 17.9001V11.9001H0.0996094V17.9001H1.09961ZM7.44606 5.05365L13.4461 11.0537L14.1532 10.3465L8.15316 4.34654L7.44606 5.05365ZM9.59961 18.4001H17.9996V17.4001H9.59961V18.4001Z"
												fill="#30C06E"
											/>
										</svg>
									</button>
								</div>
							</>
						) : (
							''
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default TableItem;
