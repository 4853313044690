import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Fees from 'components/Fees';

// ================================================:
const FeeManagementPage: FC = () => {
	return (
		<Dashboard title="Fee Management">
			<Fees />
		</Dashboard>
	);
};

export default FeeManagementPage;
