import classNames from 'classnames';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { capitalizeFirstLetter } from 'utils/capitalizeFirstLetter';
import { ITableItemProps } from './types';
import { orderStatusClassNames, orderStatusNames } from './utils';

const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td">
				<Link to={`/user-management/${item.user_id}`}>
					<p className="td-hidden-name">USER ID</p>
					<p className="grey-text">{item.user_id}</p>
				</Link>
			</div>
			<div className="td">
				<p className="td-hidden-name">Contract</p>
				<p>{item.contract_name.toUpperCase()}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">ID</p>
				<p>{item.id}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Trade Type</p>
				<p className={`${item.transactions_type === 'buy' ? 'green' : 'red'}`}>
					{capitalizeFirstLetter(item.transactions_type)}
				</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Order Price</p>
				<p>{item.price_entry_for_order}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">QTY</p>
				<p>{item.amount_asset_request}</p>
			</div>

			<div className="td">
				<p className="td-hidden-name">Avg. Filled Price</p>
				<p>{item.price_avarage_completed}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Filled QTY</p>
				<p>{item.amount_asset_request - item.amount_asset_request_left}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Fees</p>
				<p>{item.fee.toFixed(2)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Order P&L</p>
				<p>{item.pnl_realized}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Cash Flow</p>
				<p>{item.pnl_realized > 0 ? 'Profit' : 'Loss'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Order ID</p>
				<p>{item.id}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Order Type</p>
				<p>{item.type_order}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Time in Force</p>
				<p>Good &#39;Til Canceled</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Order Time</p>
				<p>{new Date(item.created_at).toLocaleString()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Trade Time</p>
				<p>{new Date(item.updated_at).toLocaleString()}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={classNames('status', orderStatusClassNames[item.status])}>
						{orderStatusNames[item.status]}
					</span>
				</div>
			</div>
		</div>
	);
};
export default TableItem;
