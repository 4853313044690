import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IAdminManagementStore,
	IAdminsListResponseData,
	IPermissionsListResponseData,
} from './types';

// ====================================================:
const getAdminManagementState = (state: IStoreState): IAdminManagementStore =>
	state.adminManagement;

export const getAdminManagement = createSelector(
	[getAdminManagementState],
	(adminManagement: IAdminManagementStore) => adminManagement,
);

// ====================================================:
export const getAdminManagementIsLoad = createSelector(
	[getAdminManagement],
	(adminManagement: IAdminManagementStore): boolean => adminManagement.adminsListLoader,
);

// ====================================================:
export const getAdminManagementList = createSelector(
	[getAdminManagement],
	(adminManagement: IAdminManagementStore): IAdminsListResponseData => adminManagement.adminsList,
);

// ====================================================:
export const getPermissionsListIsLoad = createSelector(
	[getAdminManagement],
	(adminManagement: IAdminManagementStore): boolean => adminManagement.permissionsListLoader,
);

// ====================================================:
export const getPermissionsList = createSelector(
	[getAdminManagement],
	(adminManagement: IAdminManagementStore): IPermissionsListResponseData =>
		adminManagement.permissionsList,
);
