import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { getUsersRequest } from 'redux/reducers/userManagement/reducer';
import Dashboard from 'layouts/Dashboard';
import UserManagement from 'components/UserManagement';

// ================================================:
const UserManagementPage: FC = () => {
	return (
		<Dashboard title="User management">
			<UserManagement />
		</Dashboard>
	);
};

export default UserManagementPage;
