import {
	IChangeLimitationsPayload,
	ILimitationsResponsePayload,
} from 'redux/reducers/limitations/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IChangeLimitsPayload } from '../../../redux/reducers/limitations/types';
import { ILimitationsApi } from './types';

export const limitations: ILimitationsApi = {
	getLimitations: (params) =>
		http
			.get<ILimitationsResponsePayload>(endpoint.limitations.CRYPTO_FEES, { params })
			.then((response) => response.data),

	changeLimitations: (payload) =>
		http.put<IChangeLimitationsPayload>(endpoint.limitations.UPDATE_CRYPTO_FEES, payload),
	getSpotLimitations: () =>
		http.get(endpoint.limitations.SPOT_LIMITS).then((response) => response.data),
	getDerivativeLimitations: () =>
		http.get(endpoint.limitations.DERIVATIVE_LIMITS).then((response) => response.data),
	putSpotLimitations: (payload) =>
		http.put<IChangeLimitsPayload>(endpoint.limitations.SPOT_LIMITS, payload),
	putDerivativeLimitations: (payload) =>
		http.put<IChangeLimitsPayload>(endpoint.limitations.DERIVATIVE_LIMITS, payload),
};
