import { FC, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { getFuturesAllOrders, getFuturesAllOrdersLoading } from 'redux/reducers/orders/selectors';
import { getFuturesAllOrdersRequest } from 'redux/reducers/orders/reducer';
import TableItem from './TableItem/TableItem';
import { TableHeader } from './TableHeader/TableHeader';

export const FuturesOrders: FC = () => {
	const dispatch = useDispatch();

	const orders = useSelector(getFuturesAllOrders);
	const loading = useSelector(getFuturesAllOrdersLoading);

	const [currentPage, setCurrentPage] = useState(1);

	useLayoutEffect(() => {
		dispatch(getFuturesAllOrdersRequest({ current_page: currentPage }));
	}, [dispatch, currentPage]);

	return (
		<>
			{loading && (
				<div className="list-loader">
					<Loader />
				</div>
			)}

			{!loading && (
				<div className="table-block table-block--trades table-block--nomt">
					<div className="table-wrapper">
						<div className="table table--futures-orders">
							<TableHeader />

							<div className="table-body">
								{orders?.data.map((item) => (
									<TableItem item={item} key={item.id} />
								))}

								{!orders?.data.length && <p style={{ padding: '20px' }}>List is empty</p>}
							</div>
						</div>
					</div>

					<div style={{ padding: '14px' }}>
						{(orders?.last_page || 0) > 1 && (
							<Pagination
								pageCount={orders?.last_page || 0}
								forcePage={currentPage - 1}
								onPageChange={setCurrentPage}
							/>
						)}
					</div>
				</div>
			)}
		</>
	);
};
