import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
	IP2PStore,
	IP2PBalancesResponse,
	IP2POpenTradesRequest,
	IP2POpenTradesResponse,
	IP2PClosedTradesResponse,
	IP2PClosedTradesRequest,
	IP2PAdevrtisementResponse,
	IP2PAdevrtisementRequest,
	IAssetsResponse,
	IRemoveOrederP2PRequest,
	IP2POrderQuickResponse,
	IP2POrderQuickRequest,
	IP2PCurrenciesResponse,
	IGetCurrenciesItem,
	IPaymentItem,
	IP2PAdevertisementResponse,
	IP2PAdevertisementRequest,
	IPofileRequest,
	IPofileResponse,
	IP2PPaymentResponse,
	IP2PProposalRequest,
	IP2PProposalResponse,
	IPostAdvertisementDataParams,
	IP2PUserSdvertisementRequest,
	IP2PUserSdvertisementResponse,
	IP2PTradeDetailsRequest,
	IP2PDatailsResponse,
	IP2PTradePaySellResponse,
	IP2PTradePaySellRequest,
	IP2PTradeChatResponse,
	IP2PTradeChatMessgeRequest,
	ISocketTrade,
	IP2PTradeChatMessgeResponse,
	ITradeSocketResponse,
} from './types';

// ==========================================:
export const initialState: IP2PStore = {
	activeChatId: '',
	p2pBalancesList: null,
	p2pBalancesListLoader: false,
	p2pOpenTradesList: null,
	p2pOpenTradesBuy: null,
	p2pClosedTradesList: null,
	p2pClosedTradesSell: null,
	p2pAdvertisement: null,
	p2pAssets: null,
	p2pOrderQuick: null,
	currencies: null,
	currentCoin: 'usdt',
	currencyItem: { code: 'USD', id: 6, name: 'US Dollar' },
	currentType: 'buy',
	paymentsTypes: null,
	paymentsTypeItem: { id: 0, payment_name: 'All payments', payment_name_eng: 'All payments' },
	allAdvertisement: null,
	allAdvertisementSell: null,
	userProfile: null,
	p2pProposal: null,
	userAdvrtisement: null,
	trade_id: '',
	tradePaySellData: null,
	p2pStateLoader: false,
	p2pChat: null,
	chat: null,
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
// ==========================================:
const chat = createSlice({
	name: '@@chat',
	initialState,
	reducers: {
		getActiveChatId: (state, action: any) => {
			const { payload } = action;
			const p2pRequestState = state;
			p2pRequestState.activeChatId = payload;
		},
		getChatRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;
			p2pRequestState.p2pBalancesListLoader = true;
		},
		getChatSuccess: (state, action: PayloadAction<IP2PTradeChatResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;
			p2pRequestState.p2pChat = payload;
			p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageSendRequest: (state, action: PayloadAction<IP2PTradeChatMessgeRequest>) => {
			const p2pRequestState = state;
			// p2pRequestState.p2pBalancesListLoader = true;
		},
		postChatMessageSendSuccess: (state, action: PayloadAction<IP2PTradeChatMessgeResponse>) => {
			const p2pRequestState = state;
			const { payload } = action;

			if (p2pRequestState.p2pChat?.common) {
				p2pRequestState.p2pChat?.common.push(payload);
			}

			// p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageSendUpdate: (state, action: PayloadAction<any>) => {
			const p2pRequestState = state;
			const { payload } = action;
			const text = Object.keys(payload)[1];
			if (text === 'common') {
				if (
					p2pRequestState.p2pChat?.common &&
					payload.trade_id === window.location.pathname.split('/dispute-info/')[1]
				) {
					p2pRequestState.p2pChat?.common.push(payload.common[0]);
				}
			} else if (text === 'seller') {
				if (
					p2pRequestState.p2pChat?.seller &&
					payload.trade_id === window.location.pathname.split('/dispute-info/')[1]
				) {
					p2pRequestState.p2pChat?.seller.push(payload.seller[0]);
				}
			} else if (text === 'buyer') {
				if (
					p2pRequestState.p2pChat?.buyer &&
					payload.trade_id === window.location.pathname.split('/dispute-info/')[1]
				) {
					p2pRequestState.p2pChat?.buyer.push(payload.buyer[0]);
				}
			}

			p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageAdminSendRequest: (state, action: PayloadAction<IP2PTradeChatMessgeRequest>) => {
			const p2pRequestState = state;
			// p2pRequestState.p2pBalancesListLoader = false;
		},
		postChatMessageAdminSendSuccess: (
			state,
			action: PayloadAction<IP2PTradeChatMessgeResponse>,
		) => {
			const p2pRequestState = state;
			const { payload } = action;

			if (p2pRequestState.p2pChat?.admin) {
				p2pRequestState.p2pChat?.admin.push(payload);
			}
		},
		postChatMessageAddSellerSendSuccess: (state, action: PayloadAction<any>) => {
			const p2pRequestState = state;
			const { payload } = action;
			if (p2pRequestState.p2pChat?.seller) {
				p2pRequestState.p2pChat?.seller.push(payload?.message);
			}
		},
		postChatMessageAddBuyerSendSuccess: (state, action: PayloadAction<any>) => {
			const p2pRequestState = state;
			const { payload } = action;

			if (p2pRequestState.p2pChat?.buyer) {
				p2pRequestState.p2pChat?.buyer.push(payload?.message);
			}
		},
		putCancelTradeRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},

		putHavePaidSellerRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},
		// putHavePaidSellerUpdate: (state, action: PayloadAction<IP2PDatailsResponse>) => {
		// 	const p2pRequestState = state;

		// 	p2pRequestState.tradePaySellData?.trade.
		// 	p2pRequestState.p2pBalancesListLoader = true;
		// },
		putRecivedPaymentRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;

			// p2pRequestState.p2pBalancesListLoader = true;
		},
		postMessageSellerRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;
			p2pRequestState.p2pBalancesListLoader = true;
		},
		postMessageBuyerRequest: (state, action: PayloadAction<IP2PDatailsResponse>) => {
			const p2pRequestState = state;
			p2pRequestState.p2pBalancesListLoader = true;
		},
	},
});
export default chat.reducer;
export const {
	getActiveChatId,
	getChatRequest,
	getChatSuccess,
	putCancelTradeRequest,
	putHavePaidSellerRequest,
	putRecivedPaymentRequest,
	postChatMessageSendRequest,
	postChatMessageSendSuccess,
	postChatMessageAdminSendRequest,
	postChatMessageAdminSendSuccess,
	postChatMessageSendUpdate,
	postMessageSellerRequest,
	postMessageBuyerRequest,
	postChatMessageAddBuyerSendSuccess,
	postChatMessageAddSellerSendSuccess,
} = chat.actions;
