import {
	IApproveWithdrawalsResponsePayload,
	IRejectWithdrawalsResponsePayload,
	IWithdrawalsResponsePayload,
} from 'redux/reducers/withdrawals/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWithdrawalsApi } from './types';

export const withdrawals: IWithdrawalsApi = {
	getWithdrawals: (params) =>
		http
			.get<IWithdrawalsResponsePayload>(endpoint.pendingWithdrawals.PENDING_WITHDRAWALS, { params })
			.then((response) => response.data),

	approveWithdrawals: (params) =>
		http
			.put<IApproveWithdrawalsResponsePayload>(
				endpoint.pendingWithdrawals.APPROVE_WITHDRAWALS,
				params,
			)
			.then((response) => response.data),

	rejectWithdrawals: (params) =>
		http
			.put<IRejectWithdrawalsResponsePayload>(
				endpoint.pendingWithdrawals.REJECT_WITHDRAWALS,
				params,
			)
			.then((response) => response.data),
};
