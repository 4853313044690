/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { ILoginHistoryItemProps } from './types';

export const LoginHistoryItem: FC<ILoginHistoryItemProps> = ({ data }) => {
	const date = new Date(data.logged_at * 1000);
	const offset = (-date.getTimezoneOffset() * 60) / 1000;
	const dateOffset = new Date((+data.logged_at + offset) * 1000);
	return (
		<div className="table-body">
			<div className="tr">
				<div className="td">
					<p className="td-hidden-name">Device</p>
					<p>{data.device}</p>
				</div>

				<div className="td">
					<p className="td-hidden-name">Country</p>
					<p>{data.country}</p>
				</div>
				<div className="td">
					<p className="td-hidden-name">ip address</p>
					<p>{data.ip}</p>
				</div>
				<div className="td td--right">
					<p className="td-hidden-name">Last Login</p>
					<p>
						{dateOffset.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
						{dateOffset.toLocaleTimeString()}
					</p>
				</div>
			</div>
		</div>
	);
};
