import { transactions } from './transactions';
import { IApi } from './types';
import { admins } from './admins';
import { auth } from './auth';
import { settings } from './settings';
import { users } from './users';
import { balance } from './balance';
import { fees } from './fees';
import { withdrawals } from './withdrawals';
import { notifications } from './notifications';
import { tradingPairs } from './tradingPairs';
import { walletManagement } from './walletManagement';
import { marketMakerBot } from './marketMakerBot';
import { limitations } from './limitations';
import { trades } from './trades';
import { orders } from './orders';
import { dispute } from './dispute';
import { currencies } from './currencies';
import { chat } from './chat';

const api: IApi = {
	auth,
	settings,
	users,
	admins,
	balance,
	fees,
	withdrawals,
	notifications,
	tradingPairs,
	walletManagement,
	marketMakerBot,
	limitations,
	transactions,
	trades,
	orders,
	dispute,
	currencies,
	chat,
};

export { api };
