import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFees } from 'redux/reducers/fees/selectors';
import { getPlatformFeesRequest } from 'redux/reducers/fees/reducer';

import Loader from 'ui/Loader';
import PlatformFeesItem from './PlatformFeesItem';

const PlatformFees: FC = () => {
	const platformFeesList = useSelector(getFees);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPlatformFeesRequest());
	}, [dispatch]);

	return (
		<div className="content-block content-block--user-management">
			<div className="content-block__inside content-block__inside--bt content-block__inside--login-his">
				<div className="table-block">
					<div className="table-wrapper">
						<div className="table table--fee">
							<div className="table-header">
								<div className="tr">
									<div className="td">
										<div className="td-name">
											<p>Currency</p>
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p />
										</div>
									</div>
									<div className="td td--right">
										<div className="td-name">
											<p>Exchange, %</p>
										</div>
									</div>
									<div className="td">
										<div className="td-name td-name--action">
											<p>Edit</p>
										</div>
									</div>
								</div>
							</div>
							<div className="table-body">
								{platformFeesList?.feesLoader ? (
									<div className="list-loader">
										<Loader />
									</div>
								) : (
									<>
										{platformFeesList?.platformFees?.map((item) => (
											<PlatformFeesItem item={item} key={item.asset_id} />
										))}
										<div />
									</>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PlatformFees;
