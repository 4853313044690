import { FC } from 'react';
import { ITableItemProps } from './types';

const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID Order</p>
				<p>{item.future_position_id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Created</p>
				<p>{new Date(item.created * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Closed</p>
				<p>{new Date(item.closed * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.contract}</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Amount</p>
				<p>{item.amount}</p>
			</div>
		</div>
	);
};

export default TableItem;
