import { FC } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field } from 'formik';
import Input from 'ui/Formik/Input';
import { password, email } from 'utils/formValidation';
import { useSelector } from 'react-redux';
import { getAuth } from 'redux/reducers/auth/selectors';
import { ILoginForm, ILoginSubmitValue } from './types';

const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
	const loginData = useSelector(getAuth);

	const initialValues = {
		email: loginData.loginEmail || '',
		// password: loginData.loginPassword || '', // not secure
		password: '',
		remember: loginData.remember || false,
		totp: '',
		captcha: '00000000', // backend required
	};

	const validationSchema = yup.object().shape({
		email,
		password,
	});

	return (
		<div className="authorization-form__body">
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={(values: ILoginSubmitValue, { setSubmitting }) => {
					loginSubmit(values);
					setSubmitting(false);
				}}
				validateOnBlur
				enableReinitialize
			>
				{({ isSubmitting, isValid, values }) => {
					return (
						<Form>
							<Field
								title="Email address"
								type="email"
								placeholder="Email"
								name="email"
								required
								component={Input}
								fieldIcon="icon-mail-icon"
							/>
							<Field
								title="Password"
								type="password"
								placeholder="Password"
								name="password"
								required
								component={Input}
								fieldIcon="icon-password-icon"
							/>
							<Field
								title="2FA-Authorization"
								type="text"
								placeholder="Code"
								name="totp"
								required
								component={Input}
								fieldIcon="icon-password-icon icon-lock-icon"
							/>
							<div className="checkbox">
								<label className="checkbox__label" htmlFor="checkbox">
									<Field id="checkbox" className="hidden" type="checkbox" name="remember" />
									<div className=" checkbox__item">
										<span className="checkbox__item-icon">
											<svg
												className="fill"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 26 26"
												width="26"
												height="26"
												fill="#1a1a1a"
											>
												<path d="M 22.566406 4.730469 L 20.773438 3.511719 C 20.277344 3.175781 19.597656 3.304688 19.265625 3.796875 L 10.476563 16.757813 L 6.4375 12.71875 C 6.015625 12.296875 5.328125 12.296875 4.90625 12.71875 L 3.371094 14.253906 C 2.949219 14.675781 2.949219 15.363281 3.371094 15.789063 L 9.582031 22 C 9.929688 22.347656 10.476563 22.613281 10.96875 22.613281 C 11.460938 22.613281 11.957031 22.304688 12.277344 21.839844 L 22.855469 6.234375 C 23.191406 5.742188 23.0625 5.066406 22.566406 4.730469 Z" />
											</svg>
										</span>
									</div>
									<p className="checkbox__text">Remember me</p>
								</label>
							</div>
							<div className="form-submit">
								<button
									className="button button--full-width button--rounded"
									disabled={!(isValid && values.email && values.password) || isSubmitting}
									aria-label="form-submit"
									type="submit"
								>
									Sign in
								</button>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

export default LoginForm;
