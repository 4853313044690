import { FC } from 'react';
import Loader from 'ui/Loader';
import { useSelector } from 'react-redux';
import { get2FAIsLoad } from 'redux/reducers/userManagement/selectors';
import UserManagementItem from './TwoFAtItem';
import { ITwoFAListProps } from './types';

// ==========================================:
const TwoFAtList: FC<ITwoFAListProps> = ({ twoFAResetList, handleClearSearch }) => {
	const twoFAListLoader = useSelector(get2FAIsLoad);

	return (
		<div className="table-block">
			<div className="table-wrapper">
				<div className="table table--2fa">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>User ID</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>E-mail</p>
								</div>
							</div>
							<div className="td ">
								<div className="td-name td-name--status">
									<p>Status</p>
								</div>
							</div>
							<div className="td ">
								<div className="td-name">
									<p>Date</p>
								</div>
							</div>

							<div className="td">
								<div className="td-name">
									<p>Documents</p>
								</div>
							</div>
							<div className="td">
								<div className="td-name">
									<p>Reason</p>
								</div>
							</div>

							<div className="td">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>

					{!twoFAResetList?.length && !twoFAListLoader && (
						<div className="user-management-empty-data">
							<p className="user-management-empty__text">No users found...</p>
						</div>
					)}
					{twoFAListLoader ? (
						<div className="list-loader">
							<Loader />
						</div>
					) : (
						<div className="table-body">
							{twoFAResetList?.map((item) => (
								<UserManagementItem
									key={item.id}
									data={item}
									handleClearSearch={handleClearSearch}
								/>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TwoFAtList;
