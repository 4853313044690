/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getOptionsLiquidationHistoryByUser,
	getOptionsLiquidationHistoryByUserLoading,
} from 'redux/reducers/userManagement/selectors';
import { useParams } from 'react-router';
import { getOptionsLiquidationHistoryByUserRequest } from 'redux/reducers/userManagement/reducer';
import Loader from 'ui/Loader';
import Pagination from 'ui/Pagination';
import { IApiGetOptionsDeliveryByUserParams } from 'services/api/users/types';
import { TableHeader } from './TableHeader/TableHeader';
import { TableItem } from './TableItem/TableItem';

export const Liquidation = () => {
	const dispatch = useDispatch();
	const { id: userId } = useParams<{ id: string }>();

	const liquidation = useSelector(getOptionsLiquidationHistoryByUser);
	const loading = useSelector(getOptionsLiquidationHistoryByUserLoading);

	const [currentPage, setCurrentPage] = useState(1);

	console.log('LIQUIDATION', liquidation);

	useLayoutEffect(() => {
		const params: IApiGetOptionsDeliveryByUserParams = {
			user_id: userId,
			current_page: currentPage,
		};
		dispatch(getOptionsLiquidationHistoryByUserRequest(params));
	}, [dispatch, userId, currentPage]);

	return (
		<div className="content-block__inside content-block__inside--login-his">
			<div className="table-block table-block--ip">
				<div className="table-wrapper">
					<div className="table table--user-options-delivery">
						{loading && (
							<div className="list-loader">
								<Loader />
							</div>
						)}

						{!loading && (
							<>
								<TableHeader />

								<div className="table-body">
									{liquidation?.data.map((item: any) => (
										<TableItem item={item} key={item.id} />
									))}

									{!liquidation?.data.length && <p style={{ padding: '20px' }}>List is empty</p>}
								</div>
							</>
						)}
					</div>
				</div>
			</div>

			{(liquidation?.last_page || 0) > 1 && (
				<div style={{ padding: '0 15px 10px' }}>
					<Pagination
						pageCount={liquidation?.last_page || 0}
						forcePage={currentPage - 1}
						onPageChange={setCurrentPage}
					/>
				</div>
			)}
		</div>
	);
};
