import React from 'react';

export const TableHeader = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Currency Pair</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>User Orders</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>User Positions</p>
					</div>
				</div>

				<div className="td">
					<div className="td-name">
						<p>Min. lot</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Rate taker, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Fee maker, %</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Max Leverage </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Impact Margin</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Maintenance Margin</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Сontract Upper </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Сontract Lower </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Base index </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Quote index </p>
					</div>
				</div>
				<div className="td">
					<div className="td-name td-name--action">
						<p>Action </p>
					</div>
				</div>
			</div>
		</div>
	);
};
