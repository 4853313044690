import { FC, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { noExponent } from 'utils/numberFormat';
import { notificationContainer } from 'utils/notificationContainer';
import { ReactComponent as CopyIcon } from 'assets/img/icons/copy-to-clipboard.svg';
import classNames from 'classnames';
import { ICryptoDepositProps } from './types';
import { cryptoTransactionStatusClassNames, cryptoTransactionStatusNames } from '../../utils';

const CryptoWithdrawalTableItem: FC<ICryptoDepositProps> = ({ item }) => {
	const [id] = useState(item.id);
	const [network] = useState(item.network);
	const [fee] = useState(item.fee);
	const [address] = useState(item.address);
	const [amount] = useState(item.amount);
	const dateCreated = new Date(item.created_at * 1000);

	const handleAddressCopy = () => {
		notificationContainer('Transaction url copied successfully!', 'info');
	};
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">ID</p>
				<p>{id}</p>
			</div>
			<div className="td td--left">
				<p className="td-hidden-name">Asset</p>
				<p style={{ textTransform: 'uppercase' }}>{network === undefined ? '-' : network}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Value</p>
				<p>{noExponent(Number(amount).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Fee</p>
				<p>{noExponent(Number(fee).toString())}</p>
			</div>
			<div
				style={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
				}}
				className="td td--left"
			>
				<p className="td-hidden-name">TX link</p>

				<p className="copy-button">
					<span className="copy-button__text">{address}</span>

					<CopyToClipboard text={item.tx_url || ''} onCopy={handleAddressCopy}>
						<button type="button" className="copy-button__item">
							<CopyIcon />
						</button>
					</CopyToClipboard>
				</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Created</p>
				<p>
					{dateCreated.toLocaleDateString('en-GB')} <span className="custom-dot"> • </span>
					{dateCreated.toLocaleTimeString()}
				</p>
			</div>

			<div className="td td--right">
				<p className="td-hidden-name">Status</p>
				<div className="status-wrapper">
					<span
						className={classNames(
							'status',
							cryptoTransactionStatusClassNames[item.status.type_name],
						)}
					>
						{cryptoTransactionStatusNames[item.status.type_name]}
					</span>
				</div>
			</div>
		</div>
	);
};

export default CryptoWithdrawalTableItem;
