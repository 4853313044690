import { FC } from 'react';
import Popup from 'reactjs-popup';
import { IResetModalProps } from './types';

// ================================================:
export const ResetModal: FC<IResetModalProps> = ({ openModal, closeModal, handleResetModal }) => {
	return (
		<Popup
			open={openModal}
			closeOnDocumentClick
			onClose={closeModal}
			closeOnEscape
			overlayStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
		>
			<div className="popup-window">
				<div className="popup-window__inside">
					<div className="popup actions-2fa-popup">
						<button type="button" className="popup__close" onClick={closeModal}>
							<svg
								width="18"
								height="19"
								viewBox="0 0 18 19"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M1.80005 2.30005L16.2 16.7M1.80005 16.7L16.2 2.30005" stroke="#011E26" />
							</svg>
						</button>
						<div className="popup-header">
							<h3 className="popup-header__title">Reset 2FA</h3>
						</div>
						<div className="popup-body">
							<div className="popup-text popup-text--center">
								<p>
									Do you really want
									<br />
									to reset 2FA?
								</p>
							</div>
							<div className="popup-submit popup-submit--sb">
								<button type="button" className="button button--wider" onClick={closeModal}>
									Cancel
								</button>
								<button
									type="button"
									className="button button--wider button--full button--del"
									onClick={() => {
										handleResetModal();
										closeModal();
									}}
								>
									Reset 2FA
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Popup>
	);
};
