/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IHotColdTransactionsRequestPayload,
	IHotColdTransactionsResponsePayload,
	IHotWalletAddressRequestPayload,
	IHotWalletAddressResponsePayload,
	IWalletManagementStore,
	IWalletsRequestPayload,
	IWalletsResponsePayload,
	IWithdrawToColdWalletRequestPayload,
	IWithdrawToColdWalletResponsePayload,
} from './types';
// ==========================================:
export const initialState: IWalletManagementStore = {
	wallets: null,
	walletsLoader: false,
	hotWalletAddress: null,
	hotWalletAddressLoader: false,
	withdrawToColdWalletLoader: false,
	withdrawToColdWallet: null,
	hotColdTransactions: null,
	hotColdTransactionsLoader: false,
};

// ==========================================:
const walletManagement = createSlice({
	name: '@@walletManagement',
	initialState,
	reducers: {
		getWalletsRequest: (state, action: PayloadAction<IWalletsRequestPayload>) => {
			const walletManagementRequestState = state;
			walletManagementRequestState.walletsLoader = true;
		},
		getWalletsSuccess: (state, action: PayloadAction<IWalletsResponsePayload>) => {
			const { payload } = action;
			const walletsSuccessState = state;
			walletsSuccessState.wallets = payload;
			walletsSuccessState.walletsLoader = false;
		},

		geHotWalletAddressRequest: (state, action: PayloadAction<IHotWalletAddressRequestPayload>) => {
			const walletManagementRequestState = state;
			walletManagementRequestState.hotWalletAddressLoader = true;
		},
		getHotWalletAddressSuccess: (
			state,
			action: PayloadAction<IHotWalletAddressResponsePayload>,
		) => {
			const { payload } = action;

			const walletsSuccessState = state;
			walletsSuccessState.hotWalletAddress = payload;
			walletsSuccessState.hotWalletAddressLoader = false;
		},

		withdrawToColdWalletRequest: (
			state,
			action: PayloadAction<IWithdrawToColdWalletRequestPayload>,
		) => {
			const walletManagementRequestState = state;
			walletManagementRequestState.withdrawToColdWalletLoader = true;
		},
		withdrawToColdWalletSuccess: (
			state,
			action: PayloadAction<IWithdrawToColdWalletResponsePayload>,
		) => {
			const { payload } = action;

			const walletsSuccessState = state;
			walletsSuccessState.withdrawToColdWallet = payload;
			walletsSuccessState.withdrawToColdWalletLoader = false;
		},

		geHotColdTransactionsRequest: (
			state,
			action: PayloadAction<IHotColdTransactionsRequestPayload>,
		) => {
			const walletManagementRequestState = state;
			walletManagementRequestState.hotColdTransactionsLoader = true;
		},
		getHotColdTransactionsSuccess: (
			state,
			action: PayloadAction<IHotColdTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const walletManagementSuccessState = state;
			walletManagementSuccessState.hotColdTransactions = payload;
			walletManagementSuccessState.hotColdTransactionsLoader = false;
		},

		walletManagementInitState: () => initialState,
	},
});

export default walletManagement.reducer;
export const {
	getWalletsRequest,
	getWalletsSuccess,
	geHotWalletAddressRequest,
	getHotWalletAddressSuccess,
	walletManagementInitState,
	withdrawToColdWalletRequest,
	withdrawToColdWalletSuccess,
	geHotColdTransactionsRequest,
	getHotColdTransactionsSuccess,
} = walletManagement.actions;
