import {
	IHotColdTransactionsResponsePayload,
	IHotWalletAddressResponsePayload,
	IWalletsResponsePayload,
	IWithdrawToColdWalletRequestPayload,
} from 'redux/reducers/walletManagement/types';
import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IWalletManagementApi } from './types';
// ==========================================:
export const walletManagement: IWalletManagementApi = {
	getWallets: (params) =>
		http
			.get<IWalletsResponsePayload>(endpoint.walletManagement.GET_HOT_WALLETS_DATA, {
				params,
			})
			.then((response) => response.data),
	getHotWalletAddress: (params) =>
		http
			.get<IHotWalletAddressResponsePayload>(endpoint.walletManagement.GET_HOT_WALLET_ADDRESS, {
				params,
			})
			.then((response) => response.data),

	withdrawToColdWallet: (payload: IWithdrawToColdWalletRequestPayload) =>
		http
			.post(endpoint.walletManagement.WITHDRAW_TO_COLD_WALLET, payload)
			.then((response) => response.data),
	getHotColdTransactions: (params) =>
		http
			.get<IHotColdTransactionsResponsePayload>(endpoint.walletManagement.HOT_COLD_TRANSACTIONS, {
				params,
			})
			.then((response) => response.data),
};
