import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	setAdminsListSuccess,
	adminsListInitState,
	getAdminsListRequest,
	setPermissionsListSuccess,
	createNewAdminSuccess,
	getPermissionsListRequest,
	editAdminSuccess,
	createNewAdminRequest,
	editAdminRequest,
	changeAdminPasswordRequest,
	deleteAdminRequest,
	deleteAdminSuccess,
} from './reducer';
import {
	IAdminsListRequestPayload,
	IAdminsListResponseData,
	IChangePasswordAdminPayload,
	ICreateNewAdminPayload,
	IDeleteAdminPayload,
	IEditAdminPayload,
	IPermissionsListRequestPayload,
	IPermissionsListResponseData,
} from './types';

// =============================================================:
function* adminManagementListWorker(action: PayloadAction<IAdminsListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IAdminsListResponseData = yield call(api.admins.getAdmins, payload);

		yield put(setAdminsListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(adminsListInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* permissionsListWorker(action: PayloadAction<IPermissionsListRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IPermissionsListResponseData = yield call(api.admins.getPermissions, payload);

		yield put(setPermissionsListSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(adminsListInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* createAdminWorker(action: PayloadAction<ICreateNewAdminPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.admins.createAdmin, payload);
		yield put(createNewAdminSuccess());
		notificationContainer('Admin has been created!', 'success');
		payload.history.push('/admin-management');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		// yield put(adminsListInitState());
		// yield put(getPermissionsListRequest());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* editAdminWorker(action: PayloadAction<IEditAdminPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.admins.editAdmin, payload);
		yield put(editAdminSuccess());

		notificationContainer('Admin has been edited!', 'success');

		payload.history.push({
			pathname: '/admin-management',
			search: `?page=${String(payload.page)}`,
		});
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(adminsListInitState());
		yield put(getPermissionsListRequest());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* deleteAdminWorker(action: PayloadAction<IDeleteAdminPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.admins.deleteAdmin, payload);
		yield put(deleteAdminSuccess());

		notificationContainer('Admin has been deleted!', 'success');

		const params = { current_page: Number(payload.page) || 1 };
		yield put(getAdminsListRequest(params));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(adminsListInitState());
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
function* changePasswordAdminWorker(action: PayloadAction<IChangePasswordAdminPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());
		yield call(api.admins.changePasswordAdmin, payload);
		yield put(changeAdminPasswordRequest(payload));

		notificationContainer('Admin password has been change!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* adminManagementSaga() {
	yield takeEvery(getAdminsListRequest.type, adminManagementListWorker);
	yield takeEvery(getPermissionsListRequest.type, permissionsListWorker);
	yield takeEvery(createNewAdminRequest.type, createAdminWorker);
	yield takeEvery(editAdminRequest.type, editAdminWorker);
	yield takeEvery(deleteAdminRequest.type, deleteAdminWorker);
	yield takeEvery(changeAdminPasswordRequest.type, changePasswordAdminWorker);
}
