/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { Link } from 'react-router-dom';

import {
	USER_STATUS_APPROVED,
	USER_STATUS_APPROVED_CLASS,
	USER_STATUS_PENDING,
	USER_STATUS_PENDING_CLASS,
	USER_STATUS_REJECTED,
	USER_STATUS_REJECTED_CLASS,
	USER_STATUS_UNVERIFIED,
	USER_STATUS_UNVERIFIED_CLASS,
} from 'redux/reducers/userManagement/constants';
import { capitalizeFirstLetter } from 'utils/numberFormat';
import { IAdminManagementListItemProps } from './types';
import { BlockedButton } from './BlockedButton';

const UserManagementItem: FC<IAdminManagementListItemProps> = ({ data, handleClearSearch }) => {
	const status = capitalizeFirstLetter(data.status || USER_STATUS_REJECTED);

	let statusClassName;
	switch (data.status) {
		case USER_STATUS_APPROVED:
			statusClassName = USER_STATUS_APPROVED_CLASS;
			break;
		case USER_STATUS_PENDING:
			statusClassName = USER_STATUS_PENDING_CLASS;
			break;
		case USER_STATUS_UNVERIFIED:
			statusClassName = USER_STATUS_UNVERIFIED_CLASS;
			break;
		case USER_STATUS_REJECTED:
		default:
			statusClassName = USER_STATUS_REJECTED_CLASS;
	}

	const textValid = (txt?: string | null) => {
		const txtTrim = txt?.trim();
		if (txtTrim) return txtTrim;
		return '-';
	};

	return (
		<div className="tr">
			<Link className="td" to={`/user-management/${data.user_id}`}>
				<p className="td-hidden-name">Id</p>
				<p>{data.user_id}</p>
			</Link>
			<Link
				className="td"
				to={`/user-management/${data.user_id}`}
				style={{ textDecoration: 'none', color: '#011E26' }}
			>
				<p className="td-hidden-name">Full name</p>
				<p>{textValid(`${data.first_name || ''} ${data.last_name || ''}`)}</p>
			</Link>
			<Link className="td" to={`/user-management/${data.user_id}`}>
				<p className="td-hidden-name">E-mail</p>
				<p>{textValid(data.email || '')}</p>
			</Link>
			<div className="td">
				<p className="td-hidden-name">Phone number</p>
				<p>{textValid(data.phone_number)}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Country</p>
				<p>{data.country || '-'}</p>
			</div>
			<div className="td td--status">
				<p className="td-hidden-name">Status</p>
				<div>
					<span className={`status ${statusClassName}`}>{status}</span>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Action</p>
				<BlockedButton
					blocked={Boolean(data.blocked)}
					id={data.user_id}
					handleClearSearch={handleClearSearch}
				/>
			</div>
		</div>
	);
};

export default UserManagementItem;
