/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLimitationsRequest } from 'redux/reducers/limitations/reducer';
import { getLimitationsIsLoad, getLimitationsList } from 'redux/reducers/limitations/selectors';
import Loader from 'ui/Loader';
import { LimitationsItem } from './LimitationsItem';

export const LimitationsWithdrawal: FC = () => {
	const limitations = useSelector(getLimitationsList);
	const limitationsLoader = useSelector(getLimitationsIsLoad);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getLimitationsRequest({}));
	}, [dispatch]);

	return (
		<div className="table-block table-block--trades table-block--nomt">
			<div className="table-wrapper">
				<div className="table table-limitation--withdrawal">
					<div className="table-header">
						<div className="tr">
							<div className="td">
								<div className="td-name">
									<p>Currency name</p>
								</div>
							</div>
							<div className="td td--center">
								<div className="td-name">
									<p>Withdraw min</p>
								</div>
							</div>
							<div className="td td--center">
								<div className="td-name">
									<p>Withdraw max</p>
								</div>
							</div>
							<div className="td td--right">
								<div className="td-name td-name--action">
									<p>Action</p>
								</div>
							</div>
						</div>
					</div>
					<div className="table-body">
						{!limitations?.length && !limitationsLoader && (
							<div className="user-management-empty-data">
								<p className="user-management-empty__text">No users found...</p>
							</div>
						)}
						{limitationsLoader ? (
							<div className="list-loader">
								<Loader />
							</div>
						) : (
							<div className="table-body">
								{limitations?.map((item) => (
									<LimitationsItem key={item.id} data={item} />
								))}
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
