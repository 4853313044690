import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSpotLimitsRequest } from 'redux/reducers/limitations/reducer';
import { getSpotLimitationsList, getSpotLimitsLoader } from 'redux/reducers/limitations/selectors';
import Loader from 'ui/Loader';
import { LimitItem } from '../LimitationsItem';

const SpotLimitsTab = () => {
	const limitations = useSelector(getSpotLimitationsList);
	const limitationsLoader = useSelector(getSpotLimitsLoader);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getSpotLimitsRequest());
	}, [dispatch]);

	return (
		<div className="table table-limitation--withdrawal">
			<div className="table-header">
				<div className="tr">
					<div className="td">
						<div className="td-name">
							<p>Currency name</p>
						</div>
					</div>
					<div className="td td--center">
						<div className="td-name">
							<p>Limit min</p>
						</div>
					</div>
					<div className="td td--center">
						<div className="td-name">
							<p>Limit max</p>
						</div>
					</div>
					<div className="td td--right">
						<div className="td-name td-name--action">
							<p>Action</p>
						</div>
					</div>
				</div>
			</div>
			<div className="table-body">
				{!limitations?.length && !limitationsLoader && (
					<div className="user-management-empty-data">
						<p className="user-management-empty__text">No currencies found...</p>
					</div>
				)}
				{limitationsLoader ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<div className="table-body">
						{limitations?.map((item) => (
							<LimitItem key={item.id} data={item} />
						))}
					</div>
				)}
			</div>
		</div>
	);
};

export default SpotLimitsTab;
