import axios from 'axios';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { getGeneralRequest, getGeneralSuccess, changeGeneralRequest } from './reducer';
import { IOrdersResponsePayload } from './types';
import { IP2PLimitsPayload } from '../limitations/types';
import { notificationContainer } from '../../../utils/notificationContainer';
import { changeLimitationsP2PSuccess } from '../limitations/reducer';

function* generalRequestWorker() {
	try {
		yield put(showLoading());
		const response: IOrdersResponsePayload = yield call(api.currencies.getGeneral);
		yield put(getGeneralSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

function* generalChangeRequestWorker(action: any) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IP2PLimitsPayload = yield call(api.currencies.changeGeneral, payload);
		notificationContainer('Successfully updated!', 'success');
		yield put(changeLimitationsP2PSuccess(response));
		const responseSuccess: IOrdersResponsePayload = yield call(api.currencies.getGeneral);
		yield put(getGeneralSuccess(responseSuccess));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}

export function* currenciesSaga() {
	yield takeEvery(getGeneralRequest.type, generalRequestWorker);
	yield takeEvery(changeGeneralRequest.type, generalChangeRequestWorker);
}
