/* eslint-disable @typescript-eslint/no-explicit-any */

import { IStoreState } from 'redux/types';

const getOptionsState = (state: IStoreState) => state.options;

// 📌 Statistics

export const getOptionsStatistics = (state: any) => {
	return getOptionsState(state).statistics;
};
