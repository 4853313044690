/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import {
	getConvertsTransactionsList,
	getCryptoTransactionsList,
	getFiatTransactionsList,
	getTransfersTransactionsList,
	getWithdrawalsTransactionsList,
} from 'redux/reducers/transactions/selectors';
import Pagination from 'ui/Pagination';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router';
import {
	getConvertsTransactionsRequest,
	getCryptoTransactionsRequest,
	getFiatTransactionsRequest,
	getTransfersTransactionsRequest,
	getWithdrawalsTransactionsRequest,
} from 'redux/reducers/transactions/reducer';
import {
	ICryptoTransactionsRequestPayload,
	IFiatTransactionsRequestPayload,
	ITransactionsRequestPayload,
	TTypeTransactions,
} from 'redux/reducers/transactions/types';
import SearchBar from 'components/SearchBar';
import {
	CRYPTO_TRANSACTION_STATUS_ARRAY,
	FIAT_TRANSACTION_STATUS_ARRAY,
	TRANSFERS_FROM_TO_WALLET_ARRAY,
	TYPE_TRANSACTIONS_CONVERTS,
	TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_CONVERTS_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_CRYPTO,
	TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_FIAT,
	TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_FIAT_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_TRANSFER,
	TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_TRANSFER_SEARCH_TEXT_ARRAY,
	TYPE_TRANSACTIONS_WITHDRAWALS,
	TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY,
	TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY,
	WITHDRAWALS_TRANSACTION_STATUS_ARRAY,
} from 'redux/reducers/transactions/constants';
import { DateRangePicker } from 'rsuite';
import { DateRange, DisabledDateFunction } from 'rsuite/esm/DateRangePicker/types';
import { notificationContainer } from 'utils/notificationContainer';
import { Deposit } from './Deposit';
import { Withdrawals } from './Withdrawals';
import { Transfers } from './Transfers';
import { Converts } from './Converts';
import 'rsuite/dist/rsuite.min.css';

export const Transactions: FC = () => {
	const crypto = useSelector(getCryptoTransactionsList);
	const fiat = useSelector(getFiatTransactionsList);
	const withdrawals = useSelector(getWithdrawalsTransactionsList);
	const transfers = useSelector(getTransfersTransactionsList);
	const converts = useSelector(getConvertsTransactionsList);

	// const [lastPage, setLastPage] = useState(0);
	const history = useHistory();
	const location = useLocation();
	const { search } = useLocation();
	const [tabIndex, setTabIndex] = useState(0);
	const dispatch = useDispatch();

	const { afterToday } = DateRangePicker;
	const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);

	const now = new Date();
	const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

	const [typeTransactions, setTypeTransactions] =
		useState<TTypeTransactions>(TYPE_TRANSACTIONS_CRYPTO);

	const [dropdownArray, setDropdownArray] = useState(CRYPTO_TRANSACTION_STATUS_ARRAY);

	const [searchFieldArray, setSearchFieldArray] = useState(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY);
	const [searchFieldTextArray, setSearchFieldTextArray] = useState(
		TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY,
	);

	const { page, text, field, tab } = queryString.parse(search);
	const [searchField, setSearchField] = useState(searchFieldArray[0]);
	const [currentPage, setCurrentPage] = useState<number>(Number(page) || 1);

	const getRequest = (
		params: ICryptoTransactionsRequestPayload | IFiatTransactionsRequestPayload,
		typeTransactionsParam?: TTypeTransactions,
	) => {
		const type = typeTransactionsParam || typeTransactions;
		switch (type) {
			case TYPE_TRANSACTIONS_CRYPTO:
				dispatch(getCryptoTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_FIAT:
				console.log(params);
				dispatch(getFiatTransactionsRequest({ type: 'deposit', ...params }));
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS:
				dispatch(getWithdrawalsTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_TRANSFER:
				console.log(params);
				dispatch(getTransfersTransactionsRequest(params));
				break;
			case TYPE_TRANSACTIONS_CONVERTS:
				dispatch(getConvertsTransactionsRequest(params));
				break;
			default:
				dispatch(getCryptoTransactionsRequest(params));
		}
	};

	const lastPage = () => {
		if (typeTransactions === TYPE_TRANSACTIONS_CRYPTO) return crypto?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_FIAT) return fiat?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_WITHDRAWALS) return withdrawals?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_TRANSFER) return transfers?.last_page ?? 0;
		if (typeTransactions === TYPE_TRANSACTIONS_CONVERTS) return converts?.last_page ?? 0;

		return 0;
	};

	const handlePageChange = (pageNumber: number) => {
		setCurrentPage(pageNumber);
		let verifyText = '';
		if (text) {
			verifyText = typeof text === 'string' ? text : String(text);
		}

		const searchParams =
			verifyText.length > 0
				? `?page=${String(pageNumber)}${verifyText && `&text=${verifyText}&field=${searchField}`}`
				: ``;

		const params: ITransactionsRequestPayload = { current_page: pageNumber || 1 };
		if (verifyText.length > 0) {
			params.search_value = verifyText;
			params.search_field = String(searchField);
		}
		if (dateRangeValue) {
			params.start_date = dateRangeValue[0].toISOString().split('T')[0];
			params.end_date = dateRangeValue[1].toISOString().split('T')[0];
		}

		history.push({
			pathname: location.pathname,
			search: searchParams,
		});

		getRequest(params);
	};

	const handleSearch = (txt: string) => {
		const params: ITransactionsRequestPayload = {};
		if (txt.length >= 1) {
			params.search_value = txt;
			params.search_field = String(searchField);
		}
		if (dateRangeValue) {
			params.start_date = dateRangeValue[0].toISOString().split('T')[0];
			params.end_date = dateRangeValue[1].toISOString().split('T')[0];
		}

		history.push({
			pathname: location.pathname,
			search: txt.length >= 1 ? `?text=${txt}&field=${searchField}` : ``,
		});
		setCurrentPage(1);

		if ((searchField === 'currency' || searchField === 'code') && txt !== '' && txt.length < 3)
			return;
		getRequest(params);
	};

	const [term, setTerm] = useState('');
	const [debouncedTerm, setDebouncedTerm] = useState(term);

	useEffect(() => {
		const timer = setTimeout(() => setTerm(debouncedTerm), 600);
		return () => clearTimeout(timer);
	}, [debouncedTerm]);

	useEffect(() => {
		handleSearch(term);
	}, [term, searchField]);

	// To remove if all works
	// useEffect(() => {
	// 	dispatch(getCryptoTransactionsRequest({}));
	// 	dispatch(getFiatTransactionsRequest({}));
	// 	dispatch(getWithdrawalsTransactionsRequest({}));
	// 	dispatch(getTransfersTransactionsRequest({}));
	// 	dispatch(getConvertsTransactionsRequest({}));
	// }, []);

	const handleClearSearch = () => {
		setDebouncedTerm('');
		setTerm('');
	};

	const setSearchFieldArrayHandler = (type: TTypeTransactions) => {
		let searchParams = '';
		let verifyText = '';
		if (text) {
			verifyText = String(text).trim();
		}
		setDateRangeValue(null);
		switch (type) {
			case TYPE_TRANSACTIONS_CRYPTO:
				setSearchFieldArray(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_CRYPTO_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY[0]);
				setDropdownArray(CRYPTO_TRANSACTION_STATUS_ARRAY);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CRYPTO_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CRYPTO}`
				}`;
				break;
			case TYPE_TRANSACTIONS_FIAT:
				setSearchFieldArray(TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_FIAT_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY[0]);
				setDropdownArray(FIAT_TRANSACTION_STATUS_ARRAY);

				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_FIAT_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_FIAT}`
				}`;
				break;
			case TYPE_TRANSACTIONS_WITHDRAWALS:
				setSearchFieldArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]);
				setDropdownArray(WITHDRAWALS_TRANSACTION_STATUS_ARRAY);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_WITHDRAWALS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_WITHDRAWALS}`
				}`;
				break;
			case TYPE_TRANSACTIONS_TRANSFER:
				setSearchFieldArray(TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_TRANSFER_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY[0]);
				setDropdownArray(TRANSFERS_FROM_TO_WALLET_ARRAY);

				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_TRANSFER_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_TRANSFER}`
				}`;
				break;
			case TYPE_TRANSACTIONS_CONVERTS:
				setSearchFieldArray(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY);
				setSearchFieldTextArray(TYPE_TRANSACTIONS_CONVERTS_SEARCH_TEXT_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CONVERTS}`
				}`;
				break;
			default:
				setSearchFieldArray(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY);
				setSearchField(TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]);
				searchParams = `?${
					verifyText &&
					`&text=${verifyText}&field=${TYPE_TRANSACTIONS_CONVERTS_SEARCH_ARRAY[0]}&tab=${TYPE_TRANSACTIONS_CONVERTS}`
				}`;
		}
		history.push({
			pathname: location.pathname,
			search: searchParams,
		});
	};

	const setTypeTransactionsHandler = (type: TTypeTransactions) => {
		setTypeTransactions(type);
		history.push({
			pathname: location.pathname,
			search: `?tab=${type}`,
		});
		setSearchFieldArrayHandler(type);
		handleClearSearch();
		setCurrentPage(1);
		getRequest({}, type);
	};

	const changeDateRangeHandler = (d: DateRange | null) => {
		setDateRangeValue(d);

		const param: ITransactionsRequestPayload = d
			? {
					current_page: 1,
					start_date: d[0].toISOString().split('T')[0],
					end_date: d[1].toISOString().split('T')[0],
			  }
			: {};
		if ((text?.length ?? 0) > 0) {
			param.search_field = searchField;
			param.search_value = String(text);
		}
		getRequest(param);
		history.push({
			pathname: location.pathname,
			search: (text?.length ?? 0) > 0 ? `?text=${String(text)}&field=${searchField}` : ``,
		});
		setCurrentPage(1);
	};

	// const exportHandler = () => {
	// 	const params: ITransactionsRequestPayload = {};
	// 	params.current_page = currentPage;
	// 	if ((debouncedTerm?.length ?? 0) > 0) {
	// 		params.search_value = debouncedTerm;
	// 		params.search_field = searchField;
	// 	}
	// 	if (dateRangeValue) {
	// 		params.start_date = dateRangeValue[0].toISOString().split('T')[0];
	// 		params.end_date = dateRangeValue[1].toISOString().split('T')[0];
	// 	}
	// 	params.export = 1;
	// 	getRequest(params);
	// };

	return (
		<>
			<div className="title-block">
				<p className="title">Transactions</p>
			</div>
			<div className="count-label count-label--user-management count-label--left transactions-data-picker">
				<SearchBar
					setDebouncedTerm={setDebouncedTerm}
					debouncedTerm={debouncedTerm}
					searchFieldArray={searchFieldArray}
					searchFieldTextArray={searchFieldTextArray}
					searchField={searchField}
					setSearchField={setSearchField}
					handleClearSearch={handleClearSearch}
					typeSearch={
						searchField === 'status' || searchField === 'from' || searchField === 'to'
							? 'dropdown'
							: undefined
					}
					dropdownArray={dropdownArray}
				/>
				<DateRangePicker
					format="dd/MM/yyyy"
					size="lg"
					block
					placement="bottomEnd"
					style={{ width: '308px' }}
					disabledDate={afterToday?.()}
					value={dateRangeValue}
					onChange={changeDateRangeHandler}
					defaultCalendarValue={[prevMonth, now]}
					ranges={[]}
				/>

				{/* <button type="button" className="transaction-export-btn" onClick={exportHandler}>
					Export transaction history
					<span className="transaction-export-btn__icon">
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M4.7495 14.9375H11.2513C13.5163 14.9375 14.9375 13.3332 14.9375 11.063V4.937C14.9375 2.66675 13.5238 1.0625 11.2513 1.0625H4.7495C2.477 1.0625 1.0625 2.66675 1.0625 4.937V11.063C1.0625 13.3332 2.477 14.9375 4.7495 14.9375Z"
								stroke="#777E91"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M8 11.0645V4.93555"
								stroke="#777E91"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M10.811 8.24219L7.99996 11.0652L5.18896 8.24219"
								stroke="#777E91"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</span>
				</button> */}
			</div>

			<Tabs
				className="settings-tabs"
				selectedIndex={tabIndex}
				onSelect={(index) => {
					setTabIndex(index);
					if (index === 0) setTypeTransactionsHandler(TYPE_TRANSACTIONS_CRYPTO);
					else if (index === 1) setTypeTransactionsHandler(TYPE_TRANSACTIONS_WITHDRAWALS);
					else if (index === 2) setTypeTransactionsHandler(TYPE_TRANSACTIONS_TRANSFER);
					else if (index === 3) setTypeTransactionsHandler(TYPE_TRANSACTIONS_CONVERTS);
				}}
			>
				<TabList className="user-settings user-settings--settings">
					<Tab className="user-settings__item">
						<span className="user-settings__link">Deposit</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Withdrawal</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Transfer</span>
					</Tab>
					<Tab className="user-settings__item">
						<span className="user-settings__link">Convert</span>
					</Tab>
				</TabList>

				<TabPanel>
					<Deposit setTypeTransactions={setTypeTransactionsHandler} />
				</TabPanel>
				<TabPanel>
					<Withdrawals />
				</TabPanel>
				<TabPanel>
					<Transfers />
				</TabPanel>
				<TabPanel>
					<Converts />
				</TabPanel>
			</Tabs>

			{(lastPage() ?? 0) > 1 && (
				<Pagination
					pageCount={lastPage() ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
		</>
	);
};
