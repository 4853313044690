import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import queryString from 'query-string';
import Dashboard from 'layouts/Dashboard';
import AdminManagement from 'components/AdminManagement';
import { getAdminsListRequest } from 'redux/reducers/adminManagement/reducer';

// ================================================:
const AdminManagementPage: FC = () => {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { page } = queryString.parse(search);

	useEffect(() => {
		const params = { current_page: Number(page) || 1 };

		dispatch(getAdminsListRequest(params));
	}, [dispatch, page]);

	return (
		<Dashboard title="Admin management">
			<AdminManagement />
		</Dashboard>
	);
};

export default AdminManagementPage;
