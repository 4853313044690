import { endpoint } from 'services/endpoint';
import { http } from 'services/http';
import { IOrders } from './types';

export const orders: IOrders = {
	getOrdersSpot: (params) =>
		http.get(endpoint.orders.ordersSpot, { params }).then((response) => response.data),

	getOrdersMargin: (params) =>
		http.get(endpoint.orders.ordersSpot, { params }).then((response) => response.data),

	getFuturesAllOrders: (params) =>
		http.get(endpoint.orders.getFuturesAllOrders, { params }).then(({ data }) => data),
	getOrdersOptions: (params) =>
		http.get(endpoint.options.ALL_ORDERS, { params }).then((response) => response.data),
};
