import { FC } from 'react';
import { capitalizeFirstLetter, toMaxDecimals } from 'utils/numberFormat';
import { ITableItemProps } from './types';

export const TableItem: FC<ITableItemProps> = ({ item }) => {
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Contract</p>
				<p style={{ textTransform: 'uppercase' }}>{item.contract_name}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order ID</p>
				<p>{item.id}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order Time</p>
				<p>{new Date(item.created_at * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Trade Time</p>
				<p>{new Date(item.updated_at * 1000).toLocaleString('en-GB')}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Trade Type</p>
				<p>{capitalizeFirstLetter(item.transactions_type)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order Price</p>
				<p>{toMaxDecimals(item.price_entry_for_order, 2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">QTY</p>
				<p>{toMaxDecimals(item.amount_asset_request, 6)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order Filled</p>
				<p>{toMaxDecimals(item.amount_exequted_in_asset, 6)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Fill Price</p>
				<p>{Number(toMaxDecimals(item.price_avarage_completed, 2)).toFixed(2)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Time in Force</p>
				<p>GTC</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Order P&L</p>
				<p>{item.pnl_realized === null ? '-' : toMaxDecimals(item.pnl_realized, 6)}</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Cash Flow</p>
				<p>-</p>
			</div>

			<div className="td td--left">
				<p className="td-hidden-name">Status</p>
				<p>{capitalizeFirstLetter(item.status)}</p>
			</div>
		</div>
	);
};
