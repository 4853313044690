import { FC } from 'react';
import { useHistory, useLocation } from 'react-router';
import ReactPaginate from 'react-paginate';
import queryString from 'query-string';
import { IPagination, IHandlePage } from './types';

const Pagination: FC<IPagination> = (props) => {
	const { pageCount, forcePage, onPageChange, type } = props;
	const history = useHistory();
	const { search } = useLocation();
	const { page, action, crc, startDate, endDate, text } = queryString.parse(search);

	const handlePage = (propsValue: IHandlePage) => {
		const { selected } = propsValue;

		const actionParam = `&action=${String(action)}`;
		const currencyParam = `&crc=${String(crc)}`;
		const startDateParam = `&startDate=${String(startDate)}`;
		const endDateParam = `&endDate=${String(endDate)}`;

		const textParam = Number(text?.length) >= 3 ? `&text=${String(text)}` : '';

		const param = `${(action && actionParam) || ''}${(crc && currencyParam) || ''}${
			(startDate && startDateParam) || ''
		}${(endDate && endDateParam) || ''}${(text && textParam) || ''}`;

		if (type === 'user-transaction') {
			history.push(`?page=${Number(page)}&userpage=${Number(selected) + 1}${param}`);
		} else {
			history.push(`?page=${Number(selected) + 1}${param}`);
		}

		return onPageChange(Number(selected) + 1);
	};

	return (
		<div className="pagination-block">
			<ReactPaginate
				pageCount={pageCount}
				pageRangeDisplayed={2}
				marginPagesDisplayed={1}
				containerClassName="pagination"
				previousLabel={
					<svg
						className="stroke"
						width="8"
						height="12"
						viewBox="0 0 8 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M6.9999 11.2L1.3999 5.99999L6.9999 0.799988"
							stroke="#9EA2A9"
							strokeLinecap="square"
						/>
					</svg>
				}
				nextLabel={
					<svg
						className="stroke"
						width="8"
						height="12"
						viewBox="0 0 8 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M1 11.2L6.6 5.99999L1 0.799988" stroke="#5BC044" strokeLinecap="square" />
					</svg>
				}
				nextLinkClassName="arrow"
				previousLinkClassName="arrow"
				activeLinkClassName="active"
				disabledLinkClassName="disabled"
				forcePage={forcePage}
				onPageChange={handlePage}
			/>
		</div>
	);
};

export default Pagination;
