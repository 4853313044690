import { IAdminsListResponseData } from 'redux/reducers/adminManagement/types';
import { endpoint } from 'services/endpoint';

import { http } from 'services/http';
import { IAdminsApi } from './types';

export const admins: IAdminsApi = {
	getAdmins: (params) =>
		http
			.get<IAdminsListResponseData>(endpoint.adminManagement.ADMINS, { params })
			.then((response) => response.data),

	getPermissions: () =>
		http.get(endpoint.adminManagement.PERMISSIONS).then((response) => response.data),

	createAdmin: (payload) =>
		http
			.post(endpoint.adminManagement.CREATE_ADMIN, payload.data)
			.then((response) => response.data),

	editAdmin: ({ userId, data }) =>
		http
			.put(endpoint.adminManagement.EDIT_ADMIN, data, { params: { id: userId } })
			.then((response) => response.data),

	deleteAdmin: ({ userId }) =>
		http
			.delete(endpoint.adminManagement.DELETE_ADMIN, { params: { id: userId } })
			.then(({ data }) => data),

	changePasswordAdmin: (payload) =>
		http
			.post(endpoint.adminManagement.CHANGE_PASSWORD_ADMIN(payload.adminId), payload.data)
			.then((response) => response.data),
};
