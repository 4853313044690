import { FC, useState } from 'react';
import { noExponent } from 'utils/numberFormat';

import { ISpotBalancesProps } from './types';

const SpotBalancesItem: FC<ISpotBalancesProps> = ({ item }) => {
	const [cryptocurrency] = useState(item.asset.code);
	const [totalBalance] = useState(item.total);
	const [availableBalance] = useState(item.balance);
	return (
		<div className="tr">
			<div className="td td--left">
				<p className="td-hidden-name">Cryptocurrency</p>
				<p style={{ textTransform: 'uppercase' }}>{cryptocurrency}</p>
			</div>
			<div className="td td--center">
				<p className="td-hidden-name">Total balance</p>
				<p>{noExponent(Number(totalBalance).toString())}</p>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Available balance</p>
				<p>{noExponent(Number(availableBalance).toString())}</p>
			</div>
		</div>
	);
};
export default SpotBalancesItem;
