import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	getMarketMakerRequest,
	getMarketMakerRequestSuccess,
	putMarketMakerBotToggleSuccess,
	putMarketMakerBotToggleRequest,
	putMarketMakerBotEditSettingsRequest,
	renderRequest,
} from './reducer';
import { IMarketMakerPageRequestPayload, IPairsSettingsResponsePayload } from './types';
// =============================================================:
// =============================================================:
function* marketMakerBotRequestWorker(action: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IPairsSettingsResponsePayload = yield call(
			api.marketMakerBot.getMarketMakerSettings,
			payload,
		);
		yield put(getMarketMakerRequestSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* marketMakerBotToggleEnabledWorker() {
	try {
		yield put(showLoading());
		const response: IPairsSettingsResponsePayload = yield call(
			api.marketMakerBot.putMarketMakerToggleEnabled,
		);
		yield put(putMarketMakerBotToggleSuccess(response));
		notificationContainer('Spot market changed!', 'success');
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
	} finally {
		yield put(hideLoading());
	}
}
function* marketMakerBotEditSettingsWorker(body: PayloadAction<IMarketMakerPageRequestPayload>) {
	const { payload } = body;
	try {
		yield put(showLoading());
		yield call(api.marketMakerBot.putMarketMakerSettings, payload);
		notificationContainer('Successfully updated!', 'success');
		yield put(renderRequest());
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
			yield put(renderRequest());
		}
	} finally {
		yield put(hideLoading());
	}
}
export function* marketMakerSaga() {
	yield takeEvery(getMarketMakerRequest.type, marketMakerBotRequestWorker);
	yield takeEvery(putMarketMakerBotToggleRequest.type, marketMakerBotToggleEnabledWorker);
	yield takeEvery(putMarketMakerBotEditSettingsRequest.type, marketMakerBotEditSettingsWorker);
}
