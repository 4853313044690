/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
	getLoginHistoryIsLoad,
	getLoginHistoryList,
	getOneUserDateRange,
} from 'redux/reducers/userManagement/selectors';
import { getLoginHistoryRequest } from 'redux/reducers/userManagement/reducer';
import Pagination from 'ui/Pagination';
import Loader from 'ui/Loader';
import { LoginHistoryItem } from './LoginHistoryItem';
import { ISetPageAndGetLoginHistoryParams } from './types';

export const LoginHistory: FC = () => {
	const { id } = useParams<{ id: string }>();

	const loginHistory = useSelector(getLoginHistoryList);
	const loginHistoryLoader = useSelector(getLoginHistoryIsLoad);
	const dateRange = useSelector(getOneUserDateRange);

	const dispatch = useDispatch();
	const history = useHistory();

	const [currentPage, setCurrentPage] = useState(1);
	const [openWhitelisting, setOpenWhitelisting] = useState(false);
	const [isMount, setIsMount] = useState(true);

	const setPageAndGetLoginHistory = (params: ISetPageAndGetLoginHistoryParams = {}) => {
		const { newPage = 1, withoutDateRange } = params;

		setCurrentPage(newPage);
		history.push({ search: `?page=${String(newPage)}` });

		const actualDateRange = withoutDateRange ? null : dateRange;
		const payload = {
			id,
			current_page: newPage,
			start_date: actualDateRange?.[0],
			end_date: actualDateRange?.[1],
		};
		dispatch(getLoginHistoryRequest(payload));
	};

	const handlePageChange = (newPage: number) => {
		setPageAndGetLoginHistory({ newPage });
	};

	useEffect(() => {
		setIsMount(false);
		setPageAndGetLoginHistory({ withoutDateRange: isMount });
	}, [dateRange]);

	return (
		<>
			<div className="content-block content-block--user-management">
				{!loginHistory && !loginHistoryLoader && (
					<div className="user-management-empty-data">
						<p className="user-management-empty__text">No Login History found...</p>
					</div>
				)}
				{loginHistoryLoader ? (
					<div className="list-loader">
						<Loader />
					</div>
				) : (
					<div className="content-block__inside content-block__inside--bt content-block__inside--login-his">
						<div className="table-block table-block--ip">
							<div className="table-wrapper">
								<div className="table table--login-his">
									<div className="table-header">
										<div className="tr">
											<div className="td">
												<div className="td-name">
													<p>Device</p>
												</div>
											</div>

											<div className="td">
												<div className="td-name">
													<p>Country</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name">
													<p>ip address</p>
												</div>
											</div>
											<div className="td">
												<div className="td-name td--right">
													<p>Last Login</p>
												</div>
											</div>
										</div>
									</div>

									{loginHistory?.login_history?.length &&
									loginHistory?.login_history?.length > 0 ? (
										loginHistory?.login_history?.map((item) => (
											<LoginHistoryItem data={item} key={item.id} />
										))
									) : (
										<p style={{ padding: '20px' }}>Login history is empty</p>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			{(loginHistory?.last_page ?? 0) > 1 && (
				<Pagination
					pageCount={loginHistory?.last_page ?? 0}
					forcePage={currentPage - 1}
					onPageChange={handlePageChange}
				/>
			)}
			{openWhitelisting && (
				<div className="popup-window" onClick={() => setOpenWhitelisting(false)} />
			)}
		</>
	);
};
