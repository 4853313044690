import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import {
	IBalanceStore,
	IBalanceListResponseData,
	IHotBalanceListResponseData,
	ITotalFeeCollectedListResponseData,
} from './types';

// ====================================================:
const getBalanceState = (state: IStoreState): IBalanceStore => state.balance;
export const getBalance = createSelector([getBalanceState], (balance: IBalanceStore) => balance);

// ====================================================:
export const getBalanceIsLoad = createSelector(
	[getBalance],
	(balance: IBalanceStore): boolean => balance.balanceListLoader,
);

// ====================================================:
export const getBalanceList = createSelector(
	[getBalance],
	(balance: IBalanceStore): IBalanceListResponseData => balance.balanceList,
);

// ====================================================:
const getHotBalanceState = (state: IStoreState): IBalanceStore => state.balance;
export const getHotBalance = createSelector(
	[getHotBalanceState],
	(balance: IBalanceStore) => balance,
);

// ====================================================:
export const getHotBalanceIsLoad = createSelector(
	[getHotBalance],
	(balance: IBalanceStore): boolean => balance.hotBalanceListLoader,
);

// ====================================================:
export const getHotBalanceList = createSelector(
	[getHotBalance],
	(balance: IBalanceStore): IHotBalanceListResponseData => balance.hotBalanceList,
);

// ====================================================:
const getTotalFeeCollectedState = (state: IStoreState): IBalanceStore => state.balance;
export const getTotalFeeCollected = createSelector(
	[getTotalFeeCollectedState],
	(balance: IBalanceStore) => balance,
);

// ====================================================:
export const getTotalFeeCollectedIsLoad = createSelector(
	[getTotalFeeCollected],
	(balance: IBalanceStore): boolean => balance.totalFeeCollectedListLoader,
);

// ====================================================:
export const getTotalFeeCollectedList = createSelector(
	[getTotalFeeCollected],
	(balance: IBalanceStore): ITotalFeeCollectedListResponseData => balance.totalFeeCollectedList,
);
