/* eslint-disable no-nested-ternary */
import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { call, put, takeEvery } from 'redux-saga/effects';
import { api } from 'services';
import { responseErrors } from 'services/http/responseErrors';
import { notificationContainer } from 'utils/notificationContainer';
import {
	IConvertsTransactionsRequestPayload,
	IConvertsTransactionsResponsePayload,
	ICryptoTransactionsRequestPayload,
	ICryptoTransactionsResponsePayload,
	IFiatTransactionsRequestPayload,
	IFiatTransactionsResponsePayload,
	ITransfersTransactionsRequestPayload,
	ITransfersTransactionsResponsePayload,
	IWithdrawalsTransactionsRequestPayload,
	IWithdrawalsTransactionsResponsePayload,
} from './types';
import {
	getConvertsTransactionsRequest,
	getConvertsTransactionsSuccess,
	getCryptoTransactionsRequest,
	getCryptoTransactionsSuccess,
	getFiatTransactionsRequest,
	getFiatTransactionsSuccess,
	getTransfersTransactionsRequest,
	getTransfersTransactionsSuccess,
	getWithdrawalsTransactionsRequest,
	getWithdrawalsTransactionsSuccess,
	transactionsInitState,
} from './reducer';

// ===================================================:
function* cryptoTransactionsRequestWorker(
	action: PayloadAction<ICryptoTransactionsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ICryptoTransactionsResponsePayload = yield call(
			api.transactions.getCryptoTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer('Exported transaction history successfully!', 'info');
		else yield put(getCryptoTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* fiatTransactionsRequestWorker(action: PayloadAction<IFiatTransactionsRequestPayload>) {
	const { payload } = action;

	try {
		yield put(showLoading());

		const response: IFiatTransactionsResponsePayload = yield call(
			api.transactions.getFiatTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer('Exported transaction history successfully!', 'info');
		else yield put(getFiatTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* withdrawalsTransactionsRequestWorker(
	action: PayloadAction<IWithdrawalsTransactionsRequestPayload>,
) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const response: IWithdrawalsTransactionsResponsePayload = yield call(
			api.transactions.getWithdrawalsTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer('Exported transaction history successfully!', 'info');
		else yield put(getWithdrawalsTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:

function* transfersTransactionsRequestWorker(
	action: PayloadAction<ITransfersTransactionsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: ITransfersTransactionsResponsePayload = yield call(
			api.transactions.getTransfersTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer('Exported transaction history successfully!', 'info');
		else yield put(getTransfersTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ===================================================:
function* convertsTransactionsRequestWorker(
	action: PayloadAction<IConvertsTransactionsRequestPayload>,
) {
	const { payload } = action;

	try {
		yield put(showLoading());
		const response: IConvertsTransactionsResponsePayload = yield call(
			api.transactions.getConvertsTransactions,
			payload,
		);
		if (payload.export === 1)
			notificationContainer('Exported transaction history successfully!', 'info');
		else yield put(getConvertsTransactionsSuccess(response));
	} catch (error) {
		if (axios.isAxiosError(error)) {
			responseErrors(error);
		}
		yield put(transactionsInitState());
	} finally {
		yield put(hideLoading());
	}
}

// ==================================================:

export function* transactionsSaga() {
	yield takeEvery(getCryptoTransactionsRequest.type, cryptoTransactionsRequestWorker);
	yield takeEvery(getFiatTransactionsRequest.type, fiatTransactionsRequestWorker);
	yield takeEvery(getWithdrawalsTransactionsRequest.type, withdrawalsTransactionsRequestWorker);
	yield takeEvery(getTransfersTransactionsRequest.type, transfersTransactionsRequestWorker);
	yield takeEvery(getConvertsTransactionsRequest.type, convertsTransactionsRequestWorker);
}
