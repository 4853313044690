/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IConvertsTransactionsRequestPayload,
	IConvertsTransactionsResponsePayload,
	ICryptoTransactionsRequestPayload,
	ICryptoTransactionsResponsePayload,
	IFiatTransactionsRequestPayload,
	IFiatTransactionsResponsePayload,
	ITransactionsStore,
	ITransfersTransactionsRequestPayload,
	ITransfersTransactionsResponsePayload,
	IWithdrawalsTransactionsRequestPayload,
	IWithdrawalsTransactionsResponsePayload,
} from './types';
// ==========================================:
export const initialState: ITransactionsStore = {
	cryptoTransactions: null,
	cryptoTransactionsLoader: false,
	fiatTransactions: null,
	fiatTransactionsLoader: false,
	withdrawalsTransactions: null,
	withdrawalsTransactionsLoader: false,
	transfersTransactions: null,
	transfersTransactionsLoader: false,
	convertsTransactions: null,
	convertsTransactionsLoader: false,
};

// ==========================================:
const transactions = createSlice({
	name: '@@Transactions',
	initialState,
	reducers: {
		getCryptoTransactionsRequest: (
			state,
			action: PayloadAction<ICryptoTransactionsRequestPayload>,
		) => {
			const cryptoTransactionsRequestState = state;
			if (action.payload.export !== 1)
				cryptoTransactionsRequestState.cryptoTransactionsLoader = true;
		},
		getCryptoTransactionsSuccess: (
			state,
			action: PayloadAction<ICryptoTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const cryptoTransactionsSuccessState = state;
			cryptoTransactionsSuccessState.cryptoTransactions = payload;
			cryptoTransactionsSuccessState.cryptoTransactionsLoader = false;
		},
		getFiatTransactionsRequest: (state, action: PayloadAction<IFiatTransactionsRequestPayload>) => {
			const fiatTransactionsRequestState = state;
			if (action.payload.export !== 1) fiatTransactionsRequestState.fiatTransactionsLoader = true;
		},
		getFiatTransactionsSuccess: (
			state,
			action: PayloadAction<IFiatTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const fiatTransactionsSuccessState = state;
			fiatTransactionsSuccessState.fiatTransactions = payload;
			fiatTransactionsSuccessState.fiatTransactionsLoader = false;
		},
		getWithdrawalsTransactionsRequest: (
			state,
			action: PayloadAction<IWithdrawalsTransactionsRequestPayload>,
		) => {
			const withdrawalsTransactionsRequestState = state;
			if (action.payload.export !== 1)
				withdrawalsTransactionsRequestState.withdrawalsTransactionsLoader = true;
		},
		getWithdrawalsTransactionsSuccess: (
			state,
			action: PayloadAction<IWithdrawalsTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const withdrawalsTransactionsSuccessState = state;
			withdrawalsTransactionsSuccessState.withdrawalsTransactions = payload;
			withdrawalsTransactionsSuccessState.withdrawalsTransactionsLoader = false;
		},
		getTransfersTransactionsRequest: (
			state,
			action: PayloadAction<ITransfersTransactionsRequestPayload>,
		) => {
			const transfersTransactionsRequestState = state;
			if (action.payload.export !== 1)
				transfersTransactionsRequestState.transfersTransactionsLoader = true;
		},
		getTransfersTransactionsSuccess: (
			state,
			action: PayloadAction<ITransfersTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const transfersTransactionsSuccessState = state;
			transfersTransactionsSuccessState.transfersTransactions = payload;
			transfersTransactionsSuccessState.transfersTransactionsLoader = false;
		},
		getConvertsTransactionsRequest: (
			state,
			action: PayloadAction<IConvertsTransactionsRequestPayload>,
		) => {
			const convertsTransactionsRequestState = state;
			if (action.payload.export !== 1)
				convertsTransactionsRequestState.convertsTransactionsLoader = true;
		},
		getConvertsTransactionsSuccess: (
			state,
			action: PayloadAction<IConvertsTransactionsResponsePayload>,
		) => {
			const { payload } = action;
			const convertsTransactionsSuccessState = state;
			convertsTransactionsSuccessState.convertsTransactions = payload;
			convertsTransactionsSuccessState.convertsTransactionsLoader = false;
		},

		transactionsInitState: () => initialState,
	},
});

export default transactions.reducer;
export const {
	getCryptoTransactionsRequest,
	getCryptoTransactionsSuccess,
	getFiatTransactionsRequest,
	getFiatTransactionsSuccess,
	getWithdrawalsTransactionsRequest,
	getWithdrawalsTransactionsSuccess,
	getTransfersTransactionsRequest,
	getTransfersTransactionsSuccess,
	getConvertsTransactionsRequest,
	getConvertsTransactionsSuccess,

	transactionsInitState,
} = transactions.actions;
