import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { logoutRequest } from 'redux/reducers/auth/reducer';
import { getAdminEmail, getIsAuth } from 'redux/reducers/auth/selectors';
import { getUserDataFromLS } from 'utils/localeStorage';

// ==========================================:
const Header: FC = () => {
	const ifAdminAuth = useSelector(getIsAuth);
	const adminEmail = useSelector(getAdminEmail);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleAdminLogout = (): void => {
		dispatch(logoutRequest({ history }));
	};

	const currentAdminEmail = adminEmail || getUserDataFromLS()?.email || '-----';

	return (
		<header className="header">
			{ifAdminAuth && (
				<div className="admin">
					<p className="admin__name">{currentAdminEmail}</p>
					<button className="admin__logout" type="button" onClick={handleAdminLogout}>
						<svg
							className="stroke"
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M15.1998 8.00005L11.5998 11.9M15.1998 8.00005L11.5998 4.40005M15.1998 8.00005L3.7998 8.00005M8.5998 15.2L0.799805 15.2L0.799805 0.800049L8.5998 0.800049"
								stroke="white"
							/>
						</svg>
					</button>
				</div>
			)}
		</header>
	);
};

export default Header;
