/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IChangeLimitationsPayload,
	ILimitationsRequestPayload,
	ILimitationsResponsePayload,
	ILimitationsStore,
	IP2PLimitsPayload,
} from './types';

export const initialState: ILimitationsStore = {
	limitations: null,
	limitationsLoader: false,
	limitationsP2P: null,
	limitationsP2PLoader: false,
	changeLimitationsLoader: false,
	spotLimits: null,
	spotLimitsLoader: false,
	derivativeLimits: null,
	derivativeLimitsLoader: false,
};

const limitations = createSlice({
	name: '@@Limitations',
	initialState,
	reducers: {
		getLimitationsRequest: (state, action: PayloadAction<ILimitationsRequestPayload>) => {
			const limitationsRequestState = state;
			limitationsRequestState.limitationsLoader = true;
		},
		getLimitationsSuccess: (state, action: PayloadAction<ILimitationsResponsePayload>) => {
			const { payload } = action;
			const limitationsSuccessState = state;
			limitationsSuccessState.limitations = payload;
			limitationsSuccessState.limitationsLoader = false;
		},

		changeLimitationsRequest: (state, action: PayloadAction<IChangeLimitationsPayload>) => {
			const changeLimitationsState = state;

			changeLimitationsState.changeLimitationsLoader = true;
		},
		changeLimitationsSuccess: (state) => {
			const changeLimitationsState = state;

			changeLimitationsState.changeLimitationsLoader = true;
		},

		changeLimitationsP2PSuccess: (state, action: PayloadAction<IP2PLimitsPayload>) => {
			const { payload } = action;
			const limitationsP2PSuccessState = state;
			limitationsP2PSuccessState.limitationsP2P = payload.data;
			limitationsP2PSuccessState.limitationsP2PLoader = false;
		},
		getSpotLimitsRequest: (state) => {
			const spotLimitsState = state;
			spotLimitsState.spotLimitsLoader = true;
		},
		getSpotLimitsSuccess: (state, { payload }) => {
			const spotLimitsState = state;
			spotLimitsState.spotLimitsLoader = false;
			spotLimitsState.spotLimits = payload;
		},
		getDerivativeLimitsRequest: (state) => {
			const spotLimitsState = state;
			spotLimitsState.derivativeLimitsLoader = true;
		},
		getDerivativetLimitsSuccess: (state, { payload }) => {
			const spotLimitsState = state;
			spotLimitsState.derivativeLimitsLoader = false;
			spotLimitsState.derivativeLimits = payload;
		},
		putSpotLimitsRequest: (state, { payload }) => {
			const spotLimitsState = state;
			spotLimitsState.spotLimitsLoader = true;
		},
		putSpotLimitsSuccess: (state) => {
			const spotLimitsState = state;
			spotLimitsState.spotLimitsLoader = false;
		},
		putDerivativeLimitsRequest: (state, { payload }) => {
			const spotLimitsState = state;
			spotLimitsState.derivativeLimitsLoader = true;
		},
		putDerivativetLimitsSuccess: (state) => {
			const spotLimitsState = state;
			spotLimitsState.derivativeLimitsLoader = false;
		},
		limitationsInitState: () => initialState,
	},
});

export default limitations.reducer;
export const {
	getLimitationsRequest,
	getLimitationsSuccess,
	changeLimitationsRequest,
	changeLimitationsSuccess,
	changeLimitationsP2PSuccess,
	getSpotLimitsRequest,
	getSpotLimitsSuccess,
	getDerivativeLimitsRequest,
	getDerivativetLimitsSuccess,
	putSpotLimitsRequest,
	putSpotLimitsSuccess,
	putDerivativeLimitsRequest,
	putDerivativetLimitsSuccess,
	limitationsInitState,
} = limitations.actions;
