/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import { FC } from 'react';

export const OrdersHistoryTableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>ID</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Pair</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Type</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Amount</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Filled</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Total</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Price</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Status</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Created</p>
					</div>
				</div>
			</div>
		</div>
	);
};
