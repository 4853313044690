import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CreateAdmin from 'components/AdminManagement/CreateAdmin';
import { getPermissionsListRequest } from 'redux/reducers/adminManagement/reducer';
import Dashboard from 'layouts/Dashboard';

// ==========================================:
const CreateAdminPage: FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getPermissionsListRequest());
	}, [dispatch]);

	return (
		<Dashboard title="Create Admin">
			<CreateAdmin />
		</Dashboard>
	);
};

export default CreateAdminPage;
