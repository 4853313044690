import { FC } from 'react';
import Dashboard from 'layouts/Dashboard';
import Withdrawals from 'components/Withdrawals';

// ================================================:
const WithdrawalsPage: FC = () => {
	return (
		<Dashboard title="Pending withdrawals">
			<Withdrawals />
		</Dashboard>
	);
};

export default WithdrawalsPage;
