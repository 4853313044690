import { FC } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setUserBlockUnblockRequest } from 'redux/reducers/userManagement/reducer';
import { IUserBlockUnblockRequestData } from 'redux/reducers/userManagement/types';
import { IBlockedButtonProps } from './types';

export const BlockedButton: FC<IBlockedButtonProps> = ({ blocked, id, handleClearSearch }) => {
	const dispatch = useDispatch();

	const toggleUserBlock = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		handleClearSearch();

		const payload: IUserBlockUnblockRequestData = {
			user_id: id,
			blocked: blocked ? 0 : 1,
		};
		dispatch(setUserBlockUnblockRequest(payload));
	};

	return (
		<div className="table-buttons">
			<button
				type="button"
				className={classNames({ lock: blocked })}
				disabled={blocked}
				onClick={toggleUserBlock}
			>
				<svg
					className="stroke"
					width="13"
					height="15"
					viewBox="0 0 13 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M3.5 6.5V3.5C3.5 1.84315 4.84315 0.5 6.5 0.5C8.15685 0.5 9.5 1.84315 9.5 3.5V6.5M1.5 6.5H11.5C12.0523 6.5 12.5 6.94772 12.5 7.5V13.5C12.5 14.0523 12.0523 14.5 11.5 14.5H1.5C0.947715 14.5 0.5 14.0523 0.5 13.5V7.5C0.5 6.94772 0.947715 6.5 1.5 6.5Z"
						stroke="#9ea2a9"
					/>
				</svg>
			</button>

			<button type="button" className="active" disabled={!blocked} onClick={toggleUserBlock}>
				<svg
					className="stroke"
					width="13"
					height="15"
					viewBox="0 0 13 15"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M3.5 6.5V3.5C3.5 1.84315 4.84315 0.5 6.5 0.5C8.15685 0.5 9.5 1.84315 9.5 3.5V4M1.5 6.5H11.5C12.0523 6.5 12.5 6.94772 12.5 7.5V13.5C12.5 14.0523 12.0523 14.5 11.5 14.5H1.5C0.947715 14.5 0.5 14.0523 0.5 13.5V7.5C0.5 6.94772 0.947715 6.5 1.5 6.5Z"
						stroke="#9ea2a9"
					/>
				</svg>
			</button>
		</div>
	);
};
