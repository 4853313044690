import { FC } from 'react';

export const TableHeader: FC = () => {
	return (
		<div className="table-header">
			<div className="tr">
				<div className="td">
					<div className="td-name">
						<p>Contract</p>
					</div>
				</div>
				<div className="td">
					<div className="td-name">
						<p>Size</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Entry Price</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Mark Price</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Liquidation price</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Margin</p>
					</div>
				</div>
				<div className="td td">
					<div className="td-name">
						<p>Unreatised PNL[RDE]</p>
					</div>
				</div>
			</div>
		</div>
	);
};
